// // /* eslint-disable default-case */



// import { createSlice,PayloadAction } from '@reduxjs/toolkit'
// import { AddToCartPayload } from '../actions/actionTypes'


// export interface CounterState {
//   value: number
// }

// const initialState = {
//   userLoginDetails: {
//     token: "",
//     userDetails: {},
//   },
//   userAddress: {},
//   cartDetails:{
//     items: [
//       {
//         prodid: '',//
//         name: '', // Product Name
//         price: 29.99, // Price per unit
//         quantity: 1, // Number of units being added
//         imageUrl: '', // Image URL
//         description: '', // Product Description
//         category: '', // Category
//         discountedPrice: 24.99, // Optional discounted price
//         totalQuantity: '0',
//         totalPrice: '0',
//         discount: '0',
//         polysheet:'',
//         polysheetColor:'',
//         polySheetSize:'', 
//         additionalProcudts:[{}]
//       }
     
//       // Adding products...
//     ]
    

//   },
// }

// export const UserReducer = createSlice({
//   name: 'user',
//   initialState,
//   reducers: {
//    login:(state,action:PayloadAction<{ token: string; userDetails: object }>)=>{
//     // console.log("login dispaced",action.payload.token,action.payload.userDetails)
//     state.userLoginDetails.token = action.payload.token;
//     state.userLoginDetails.userDetails = action.payload.userDetails;
//    },
//    addToCart: (state, action: PayloadAction<{ items: any }>) => {
//     const newItem = action.payload.items;
//     const existingItem = state.cartDetails.items.find(item => item.prodid === newItem.prodid);

//     // if (existingItem) {
//     //   console.log("cart existingitem",Array.from(state.cartDetails.items))
//     //   // existingItem.quantity += newItem.quantity; // Update quantity if already in cart
      
//     // } else {
//     //   console.log("cart existingitem else",Array.from(state.cartDetails.items))
//     //   state.cartDetails.items.push(newItem); // Add new item to cart
//     // }

//     if (existingItem) {
//       console.log("Existing item found:", existingItem);
//     } else {
//       console.log("No existing item found.");
//       state.cartDetails.items.push(newItem);
//     }
//     // console.log("cart existingitem",existingItem)

//     // state.totalQuantity += newItem.quantity; // Update total quantity
//     // state.totalPrice += newItem.price * newItem.quantity; // Update total price
//   }
//   },
// })

// // Action creators are generated for each case reducer function
// export const { login,addToCart } = UserReducer.actions

// export default UserReducer.reducer


import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ProductDetails from '../../components/landingPage/product-details';

export interface CartItem {
  prodid: any;
  name: string;
  price: any;
  quantity: any;
  imageUrl: string;
  description: string;
  category: string;
  discountedPrice: string;
  polysheet?: string;
  polysheetColor?: string;
  polySheetSize?: string;
  additionalProducts?: Array<object>;
  updatedPrice?:any;
}
export interface WishlistItem {
  prodid: any;
  name: string;
  price: any;
  quantity: any;
  imageUrl: string;
  description: string;
  category: string;
  discountedPrice: string;
  polysheet?: string;
  polysheetColor?: string;
  polySheetSize?: string;
  additionalProducts?: Array<object>;
}

export interface UserState {
  isLoggedin:boolean,
  userLoginDetails: {
    token: string;
    userDetails: object;
  };
  allPoductsData:any,
  categorySelectedName:string;
  productDetails:object,
  modalProperties:{
    showModal:boolean,
    modalMesssage:string
  },

  userAddress: object;
  cartDetails: {
    items: CartItem[];
    total:0;
  };
  userAddress2: object;
  wishlistDetails: {
    items: WishlistItem[];
  };
 
  userOrderId:object,
  userOrdersList:[object],
  shoppingCategories:object,
}

const initialState: UserState = {
  isLoggedin:false,
  userLoginDetails: {
    token: "",
    userDetails: {},
  },
  allPoductsData:[],
  categorySelectedName:"",
  productDetails:{},
  modalProperties:{
    showModal:false,
    modalMesssage:''
  },
  userAddress: {},
  cartDetails: {
    items: [],
    total:0
  },

  userAddress2: {},
  wishlistDetails: {
    items: [],
  },
  userOrderId:{},
  userOrdersList:[{}],
  shoppingCategories:{}
};

export const UserReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loggedIn:(state,action:PayloadAction<{loggedin:boolean}>)=>{
      state.isLoggedin= action.payload.loggedin;
      
    },
    login: (state, action: PayloadAction<{ token: string; userDetails: object }>) => {
      state.userLoginDetails.token = action.payload.token;
      state.userLoginDetails.userDetails = action.payload.userDetails;
    },
    addProduct:(state,action:PayloadAction<{proddetails:object}>)=>{
      console.log("dispatched proddetails",action.payload.proddetails)
      state.productDetails=action.payload.proddetails;
      
    },
    allProducts:(state,action:PayloadAction<{allproducts:object}>)=>{
      console.log("dispatched proddetails",action.payload.allproducts)
      state.allPoductsData=action.payload.allproducts;
    },

    addToWishList: (state, action: PayloadAction<{ item: WishlistItem }>) => {
      const newItem = action.payload.item;
      console.log("Updated existing item:", newItem);
      console.log("Cart items:", state.wishlistDetails.items.length);
      console.log("New item prodid:", newItem.prodid);
      // console.log("New item prodid:", item.prodid);
      const existingItem = state.wishlistDetails.items.find((item) =>{
        console.log("prodid item",typeof item.prodid)
        console.log("prodid newItem",typeof newItem.prodid)
        return item.prodid === newItem.prodid

      });
      console.log("Updated existing item:", existingItem);
      
      if (existingItem) {
       
        existingItem.prodid = newItem.prodid;
        existingItem.quantity  = newItem.quantity;
        // existingItem.price = parseInt(existingItem.quantity) * parseInt(existingItem.price); 
        console.log("Updated existing item:", existingItem);
      } else {
       
        // state.wishlistDetails.items.push(newItem);
        state.wishlistDetails.items.push(newItem)
        console.log("Added new item to cart:", newItem);
      }
    },
    addCategorySelected:(state,action:PayloadAction<{categorySelected:string}>)=>{
        state.categorySelectedName=action.payload.categorySelected;
    },

    addToCart: (state, action: PayloadAction<{ item: CartItem }>) => {
      const newItem = action.payload.item;
      console.log("Updated existing item:", newItem);
      console.log("Wish items:", state.cartDetails.items);
console.log("New item prodid:", newItem.prodid);
      const existingItem = state.cartDetails.items.find((item) => {
        console.log("prodid item",item.prodid)
        console.log("prodid newItem",newItem.prodid)
        console.log("prodid newItem", item.prodid === newItem.prodid)
        return item.prodid === newItem.prodid
      });
      console.log("Updated existing item:", existingItem?.additionalProducts);
     
      if (existingItem) {
        
        existingItem.quantity= newItem.quantity;
        // existingItem.price = parseInt(existingItem.quantity) * parseInt(existingItem.price); // Update total price
        existingItem.price = newItem.price; // Update total price
        existingItem.updatedPrice=newItem.updatedPrice;
        console.log("Updatedddd existing item:", existingItem);
      } else {
        // Add new item to the cart
        state.cartDetails.items.push(newItem);
        console.log("Added new item to wish:", newItem);
      }
      // state.cartDetails.total = state.cartDetails.items.reduce((sum, item) => sum + item.price, 0);
      // state.cartDetails.total = state.cartDetails.items.reduce((sum, item) => sum + item.price, 0);
    },
    deleteCartItem: (state, action: PayloadAction<{ item: CartItem }>)=>{
      const newItem = action.payload.item;
      console.log("Updated delte item:", newItem.prodid);
      if(state.cartDetails.items.length>0)
      {
        state.cartDetails.items = state.cartDetails.items.filter(item => item.prodid !== newItem.prodid);
        console.log("Updated cart items:", state.cartDetails.items);
      }
      // return  state.cartDetails.items;
    },
    resetCart:(state)=>{
     
        state.cartDetails.items = []; // Clear the cart items
   
    },
    removeFromCart: (state, action: PayloadAction<{ id: string }>) => {
      const id = action.payload.id;
      const existingItem = state.cartDetails.items.find(item => item.prodid === id);

      if (existingItem) {
        console.log("remvoe item",id,existingItem.prodid)
        // state.cartDetails.totalQuantity -= existingItem.quantity; // Update total quantity
        // state.totalPrice -= existingItem.price * existingItem.quantity; // Update total price
        // state.items = state.items.filter(item => item.id !== id); // Remove item from cart
      }
    },
    setModalProperties: (state, action: PayloadAction<{showModal:boolean,modalMessage:string}>)=>{
 state.modalProperties.showModal = action.payload.showModal;
      state.modalProperties.modalMesssage = action.payload.modalMessage;
    },
    addUserOrderId:(state,action:PayloadAction<{orderId:Object}>)=>{
      state.userOrderId=action.payload.orderId

    },
    addUsersOrdersList:(state,action:PayloadAction<{orderslist:[]}>)=>{

    }

  },
});

// Action creators are generated for each case reducer function
export const { login, addToCart,removeFromCart,deleteCartItem,resetCart,loggedIn ,addProduct,addUserOrderId,
  addUsersOrdersList,setModalProperties,allProducts,addToWishList,addCategorySelected} = UserReducer.actions;

export default UserReducer.reducer;

