import React, { useState } from 'react';
import { Box, Typography, Grid, Divider } from '@mui/material';
import { Email as EmailIcon, Lock as LockIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/Button/customButton';
import CustomTextField from '../../../../components/Button/customTextField';

interface AdminLoginProps {
  AdminLoginButtonText?: string;
  registerButtonText?: string;
  googleButtonText?: string;
}

const AdminLogin: React.FC<AdminLoginProps> = ({
  AdminLoginButtonText = "Sign up",
  registerButtonText = "Register",
  googleButtonText = "Signup with Google",
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate('/register');
  };

  const handleLogin = () => {
  
    console.error('Login function not implemented.');
  };

  const handleForgotPasswordClick = () => {
  
    console.error('Forgot Password function not implemented.');
  };

  return (
    <Box sx={{ margin: 4 }}>
      <Grid container component="main" sx={{ height: '80vh', margin: 0, overflow: 'hidden' }}>
        <Grid item xs={12} sm={6} md={6} component={Box} display="flex" alignItems="center" justifyContent="center" sx={{ backgroundColor: '#fff', flexGrow: 1 }}>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: '400px',
              padding: '20px',
              width: '100%',
            }}
          >
            <img src="/logo-10q.png" alt="Hero Electro" style={{ width: '150px', marginBottom: '20px' }} />
            <Typography variant="h5" component="h2" sx={{ mb: 2, color: '#333', fontSize: 30 }}>
              Sign In to see Dashboard
            </Typography>
            <Typography variant="body1" component="p" sx={{ mb: 2, color: '#333', fontSize: 12 }}>
              Welcome To HERO ELECTRO! Please Enter Your Details
            </Typography>
            <CustomButton
              text={googleButtonText}
              variant="outlined"
              startIcon={<Box component="img" src="/google.png" alt="Google" sx={{ width: 20, height: 20 }} />}
              color="#3A3AC8"
              hoverColor="#2825A6"
            />
            <Divider sx={{ width: '100%', mb: 2 }}>
              <Typography variant="body2" color="textSecondary">
                OR
              </Typography>
            </Divider>
            <CustomTextField
              label="Email"
              value={email}
              icon={<EmailIcon />}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            />
            <CustomTextField
              label="Password"
              type="password"
              value={password}
              icon={<LockIcon />}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            />
            <Typography variant="body1" component="p" sx={{ mb: 2, fontSize: 12, alignSelf: 'flex-end', cursor: 'pointer', color: '#3A3AC8' }} onClick={handleForgotPasswordClick}>
              Forgot Password?
            </Typography>
            <CustomButton
              text={AdminLoginButtonText}
              variant="contained"
              onClick={handleLogin}
              color="#3A3AC8"
              hoverColor="#2825A6"
            />
            {/* <Typography variant="body1" component="p" sx={{ mb: 2, fontSize: 12 }}>
              Don't have an account? <span onClick={handleRegisterClick} style={{ cursor: 'pointer', color: '#3A3AC8' }}>Sign up</span>
            </Typography> */}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#3A3AC8',
            color: '#fff',
            padding: '20px',
            flexGrow: 1,
          }}
        >
          <Typography component="h1" variant="h4" sx={{ mb: 2 }}>
            Welcome To HERO ELECTRO!
          </Typography>
          <Typography component="h2" variant="h5" sx={{ mb: 2 }}>
            Sign In Now To Check Your Dashboard
          </Typography>
          <Typography component="p" variant="body1" sx={{ mt: 1, textAlign: 'center' ,paddingLeft:10,paddingRight:10}}>
            "Get a real-time overview of key metrics like sales, order statuses, and inventory levels. Track customer activity and performance insights to stay on top of your store’s operations."
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <img src="/adminImage.svg" alt="Dashboard Chart" style={{ width: '80%' }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminLogin;
