import React, { useEffect, useState } from 'react';
import { Box, Input, IconButton } from '@mui/joy';
import SearchIcon from '@mui/icons-material/Search';
import OrderCard from './order-card';
import { useSelector,useDispatch } from 'react-redux';
import { useFetch } from '../../../hooks/useFetch';
import { urls } from '../../../urls/urls';
import { BASE_URL_Test } from '../../../utils/baseUrl';

const OrderList: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const storeCart = useSelector((state:any)=>state.user.cartDetails)
  const userLoggedIn = useSelector((state:any)=>state.user.isLoggedin)
  const userLoggedToken = useSelector((state:any)=>state.user.userLoginDetails.token)
  
  console.log("storecatyy",userLoggedToken)

  // const {data:OrdersPurchasedData,error:OrdersPurchasedError,fetchData:OrdersPurchasedFetchData}=useFetch(BASE_URL_Test+urls('purchasedorders'),{
	// 	method: 'GET',
  //   // redirect: "follow",
	// 	headers: { 'Content-Type': 'application/json','Authorization': `${userLoggedToken}` }


	// })

  const { data: OrdersPurchasedData, error: OrdersPurchasedError, fetchData: OrdersPurchasedFetchData } = useFetch(
    BASE_URL_Test + urls('purchasedorders') + "?email=tokentest@gmail.com", // Add email as a query param in the URL
    {
      method: 'GET', // Use GET method
      headers: {
        'Content-Type': 'application/json', // Make sure to set the Content-Type as JSON if needed
        'Authorization': `Token ${userLoggedToken}`, // Authorization header with the Bearer token
      },
    }
  );
  

  useEffect(()=>{
    if(userLoggedIn)
    {
      OrdersPurchasedFetchData()
//       const myHeaders = new Headers();
// myHeaders.append("Authorization", "Token d8fc4164f724b4ef9ff7a6ebe16aceb999cc1f7a");

// const formdata = new FormData();
// formdata.append("email", "tokentest@gmail.com");
// console.log("email", "called");

// const requestOptions:RequestInit = {
//   method: "GET",
//   headers: myHeaders,
//   body: formdata,
//   redirect: "follow"
// };

// fetch("http://127.0.0.1:9000/api/v1/auth/purchasedorders/", requestOptions)
//   .then((response) => response.text())
//   .then((result) => console.log(result))
//   .catch((error) => console.error(error));
//     }

  }},[])

  useEffect(()=>{
    if(OrdersPurchasedError){

    }else if(OrdersPurchasedData)
    {
      console.log("OrdersPurchasedData",OrdersPurchasedData)
    }

  },[OrdersPurchasedData,OrdersPurchasedError])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const orders = [
    {
      imageUrl: './proguctimage.png',
      title: 'Elegant Peacock Design - Decorative Refrigerator Magnet',
      price: '₹1,740',
      status: 'delivered' as const,
      deliveryDate: 'Sep 10',
    },
    {
      imageUrl: './proguctimage.png',
      title: 'Lord Murugan Collection - Decorative Item',
      price: '₹1,200',
      status: 'delivered' as const,
      deliveryDate: 'Sep 8',
    },
    {
      imageUrl: './proguctimage.png',
      title: 'Christian Religious Art - Holy Cross',
      price: '₹800',
      status: 'delivered' as const,
      deliveryDate: 'Sep 1',
    },
    {
      imageUrl: './proguctimage.png',
      title: 'Traditional Kuthu Vilakku - Set of 3',
      price: '₹3,500',
      status: 'not_placed' as const,
      paymentMessage: 'Payment pending.',
    },
    {
      imageUrl: './proguctimage.png',
      title: 'Wildlife Inspirations - Lion Sculpture',
      price: '₹2,150',
      status: 'delivered' as const,
      deliveryDate: 'Sep 5',
    },
    {
      imageUrl: './proguctimage.png',
      title: 'Intricate Animal Figurine - Elephant',
      price: '₹1,750',
      status: 'not_placed' as const,
      paymentMessage: 'Please complete your payment.',
    },
    {
      imageUrl: './proguctimage.png',
      title: 'Festive Collection - Diwali Diyas Set',
      price: '₹2,300',
      status: 'delivered' as const,
      deliveryDate: 'Aug 30',
    },
    {
      imageUrl: './proguctimage.png',
      title: 'Colorful Peacock Wall Art',
      price: '₹950',
      status: 'delivered' as const,
      deliveryDate: 'Sep 12',
    },
    {
      imageUrl: './proguctimage.png',
      title: 'Handcrafted Christian Wall Decor',
      price: '₹650',
      status: 'delivered' as const,
      deliveryDate: 'Sep 3',
    },
    {
      imageUrl: './proguctimage.png',
      title: 'Ethnic Kuthu Vilakku - Single',
      price: '₹1,200',
      status: 'pending' as const,
      paymentMessage: 'Please check your payment status.',
    },
    {
      imageUrl: './proguctimage.png',
      title: 'Animal Figurine Collection - Set of 4',
      price: '₹3,800',
      status: 'delivered' as const,
      deliveryDate: 'Sep 8',
    },
  ];
  
 
  const filteredOrders = storeCart&&storeCart.items.filter((order:any) =>
    order.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box sx={{ width: '100%', maxWidth: '800px', margin: '0 auto', padding: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
        <Input
          placeholder="Search your orders here"
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
          startDecorator={<SearchIcon />}
        />
      </Box>
      {filteredOrders.length > 0 ? (
        filteredOrders.map((order:any, index:number) => (
          <OrderCard key={index} {...order} />
        ))
      ) : (
        <Box sx={{ textAlign: 'center', marginTop: 2 }}>  
          No orders found matching your search.
        </Box>
      )}
    </Box>
  );
};

export default OrderList;

