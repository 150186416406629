import React, { useState, useEffect, useRef } from 'react';
import { Box, styled } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import CustomCard from '../Button/customCard'; 
import { useDispatch,useSelector } from 'react-redux';
import { addProduct } from '../../store/reducers/userReducers';
import { BASE_URL_Test } from '../../utils/baseUrl';

const data = [
  {
    title: 'HEPC11.3 SIMPLE PEACOCK HOLDING CHAIN ON MOUTH 6X3 FEET',
    image: './cards.webp.png',
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
  {
    title: 'HEPC11.3 SIMPLE PEACOCK HOLDING CHAIN ON MOUTH 6X3 FEET',
    image: './cards.webp.png',
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
  {
    title: 'HEPC11.3 SIMPLE PEACOCK HOLDING CHAIN ON MOUTH 6X3 FEET',
    image: './cards.webp.png',
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
  {
    title: 'HEPC11.3 SIMPLE PEACOCK HOLDING CHAIN ON MOUTH 6X3 FEET',
    image: './cards.webp.png',
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
  {
    title: 'HEPC11.3 SIMPLE PEACOCK HOLDING CHAIN ON MOUTH 6X3 FEET',
    image: './cards.webp.png',
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
  {
    title: 'HEPC11.3 SIMPLE PEACOCK HOLDING CHAIN ON MOUTH 6X3 FEET',
    image: './cards.webp.png',
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
  {
    title: 'HEPC11.3 SIMPLE PEACOCK HOLDING CHAIN ON MOUTH 6X3 FEET',
    image: './cards.webp.png',
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
  {
    title: 'HEPC11.3 SIMPLE PEACOCK HOLDING CHAIN ON MOUTH 6X3 FEET',
    image: './cards.webp.png',
    originalPrice: '₹1,051.00',
    discountedPrice: '₹1,030.00',
    discount: '10% Off',
  },
];

const CarouselContainer = styled(Box)({
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  width: 'calc(86% - 20px)', 
  margin: '0 auto', 
});

const InnerContainer = styled(Box)({
  display: 'flex',
  transition: 'transform 0.5s ease-in-out',
  flexDirection: 'row',
  width: '100%',
});

const CardWrapper = styled(Box)({
  flex: '2%',
  boxSizing: 'border-box',
  cursor: 'pointer',
});

const Indicators = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

const Indicator = styled(Box)<{ active: boolean }>(({ active }) => ({
  height: '10px',
  width: '10px',
  borderRadius: '50%',
  backgroundColor: active ? '#F44336' : 'lightgray',
  margin: '0 5px',
  cursor: 'pointer',
}));

interface CardsDataProps{
  prodData:any;
}

{/* <CustomCard 
title={item.productname}
image={item.imagepathlink}
originalPrice={item.price}
discountedPrice={item.price}
discount={item.discount}
/> */}

const Cards: React.FC<CardsDataProps> = ({prodData}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef<number | null>(null);
  const navigate = useNavigate();  
  console.log("cards",prodData)
  // const extendedData = [...data, ...data, ...data];
  // const extendedData = [...prodData];
  const dispatch =useDispatch();
  // console.log("extebdedda",extendedData)
  useEffect(() => {
    const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % (data.length + 5));
    };

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(nextSlide, 9000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [currentIndex]);

  const handleIndicatorClick = (index: number) => {
    setCurrentIndex(index);
  };

  const handleCardClick = (item:{},index: number) => {

    
    dispatch(addProduct({proddetails:item})) //  dispatch(addProduct({proddetails:{"productname":"Test","image":"imageurl","originalPrice":"100","discountedPrice":"30","discount":"3" }}))
    navigate(`/product`); 
    console.log("item clicked",item)
  };

  return (
    <Box sx={{marginTop:5}}>
      <CarouselContainer>
        <InnerContainer
          style={{
            transform: `translateX(-${currentIndex * 20}%)`,
          }}
        >
                

          {prodData.map((item:any, index:number) => (
            
            <CardWrapper key={index} onClick={() => handleCardClick(item,index)}>
              <CustomCard
              
                title={item.productname} // custom name
                image={`${BASE_URL_Test}/api/v1/auth/media/`+ item.images} //image url form net
                originalPrice={""+Math.trunc(item.price)} //statis price
                discountedPrice={""+Math.trunc(item.cutoffprice)} //ststis price
                discount={""+Math.trunc(item.discount)} // statis
              />   
            </CardWrapper>
            
          ))}
        </InnerContainer>
      </CarouselContainer>
      <Indicators>
        {Array.from({ length: prodData.length }).map((_, index) => (
          <Indicator
            key={index}
            active={index === currentIndex}
            onClick={() => handleIndicatorClick(index)}
            sx={{ mb: 4 }}
          />
        ))}
      </Indicators>
    </Box>
  );
};

export default Cards;
