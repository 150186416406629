import { Stack } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import Cards from './cards';
import { CarouselImages } from '..';
import Categories from './categories';
import Banner from './banner';
import CustomerReviews from './customer-reviews';
import { useDispatch } from 'react-redux';
import { allProducts } from '../../store/reducers/userReducers';

import { BASE_URL,BASE_URL_Test } from '../../utils/baseUrl';
import { urls } from '../../urls/urls';
import { useFetch } from '../../hooks/useFetch';
import ProductCardTrending from './trending-deals';


interface HomeProps{
  prodData:any;
  prodImages:any;
  
}


const Home: React.FC<HomeProps> = ({prodData,prodImages}) => {
  console.log("Home leveldata",prodImages)
  const {data,isLoading,error,fetchData}=useFetch(BASE_URL_Test+urls('allProductsData'),{
    method:"GET",
    headers: { 'Content-Type': 'application/json' }
  })
  const [allProdData,setAllProdData]=useState<[]|null>([])
  const [, setSelectedCategory] = useState<string | null>(null);
  const dispatch=useDispatch();

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
  };

  useEffect(()=>{
    fetchData()
  },[])


  useEffect(()=>{
    if(error)
    {

    }
    else if(data)
    {
      
      if(data['status']==='success')
      {
        console.log("allproddata",data)
       
        const keysToSkip = ['id', 'discount'];
        const mergedArray = prodData.map((item1:any) => {
          const item2 = prodImages.find((item:any) => item.productid === item1.productid);
          const newItem = { ...item1 };
          if (item2) {
            Object.keys(item2).forEach(key => {
                if (!keysToSkip.includes(key)) {
                    newItem[key] = item2[key];
                }
            });
        }
        return newItem;
      
      });
      console.log("mergedArray",mergedArray)
      
      // console.log("mergedArray",mergedArray);
      dispatch(allProducts({allproducts:mergedArray}))
      setAllProdData(mergedArray)
      }
    }
  },[prodData,error,isLoading])

  return (
    <Stack>
      <Categories onCategoryClick={handleCategoryClick} />
      <CarouselImages />
      {/* <Cards  prodData={allProdData?allProdData:[]} /> */}
      <ProductCardTrending  prodData={allProdData?allProdData:[]}/>
      <Banner/>
      <CustomerReviews/>
      
      
    </Stack>
  );
};

export default Home;
