import { Stack, Box, Typography, useTheme } from '@mui/joy';
import { useMediaQuery } from '@mui/material';
import React, { useState } from 'react';

const categories = [
  { name: 'FEATURED PRODUCTS', imgSrc: './starr_Image.svg' },
  { name: 'INSTRUMENTS/EMBLEMS', imgSrc: './cards.webp.png' },
  { name: 'MURUGAN', imgSrc: './cards.webp.png' },
  { name: 'PEACOCKS', imgSrc: './cards.webp.png' },
  { name: 'CHRISTIAN', imgSrc: './cards.webp.png' },
  { name: 'AMMAN', imgSrc: './cards.webp.png' },
  { name: 'ANIMALS', imgSrc: './cards.webp.png' },
  { name: 'KUTHU VILAKKU', imgSrc: './cards.webp.png' },
];

interface CategoriesProps {
  onCategoryClick: (category: string) => void;
}

const Categories: React.FC<CategoriesProps> = ({ onCategoryClick }) => {
  const [activeCategory, setActiveCategory] = useState('INSTRUMENTS/EMBLEMS');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClick = (categoryName: string) => {
    if (categoryName !== 'FEATURED PRODUCTS') {
      setActiveCategory(categoryName);
      onCategoryClick(categoryName);
    }
  };

  return isSmallScreen ? (
   
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        justifyContent: 'center',
        overflowX: 'auto', 
        m: isSmallScreen ? 1 : 3,
      }}
    >
      {categories.map((category, index) => (
        <Box
          key={index}
          textAlign="center"
          onClick={() => handleClick(category.name)}
          sx={{
            cursor: category.name !== 'FEATURED PRODUCTS' ? 'pointer' : 'default',
            position: 'relative',
            borderRadius: '50%',
            border: category.name === activeCategory ? '2px solid red' : 'none',
            padding: category.name !== 'FEATURED PRODUCTS' ? '1px' : '0',
            width: isExtraSmallScreen ? '60px' : isSmallScreen ? '80px' : '100px',
            height: isExtraSmallScreen ? '60px' : isSmallScreen ? '100px' : '150px',
            transition: 'border-color 0.3s',
          }}
        >
          <Box
            component="img"
            src={category.imgSrc}
            alt={category.name}
            width={isExtraSmallScreen ? '60px' : isSmallScreen ? '80px' : '100px'}
            height={isExtraSmallScreen ? '60px' : isSmallScreen ? '80px' : '100px'}
            borderRadius="50%"
          />
          <Typography
            sx={{
              mt: 1,
              fontSize: isExtraSmallScreen ? '0.5rem' : isSmallScreen ? '0.65rem' : '0.75rem',
              fontWeight: category.name === activeCategory ? 'bold' : 'normal',
              color: category.name === activeCategory ? 'red' : 'inherit',
              transition: 'color 0.3s, font-weight 0.2s',
            }}
          >
            {category.name}
          </Typography>
        </Box>
      ))}
    </Stack>
  ) : (
    
    <Stack direction="row" spacing={2} alignItems="center" sx={{ justifyContent: 'center', m: 3 }}>
    {categories.map((category, index) => (
      <Box
        key={index}
        textAlign="center"
        onClick={() => handleClick(category.name)}
        sx={{
          cursor: category.name !== 'FEATURED PRODUCTS' ? 'pointer' : 'default',
          position: 'relative',
          borderRadius: '50%',
          border: category.name === activeCategory ? '2px solid red' : 'none', 
          padding: category.name !== 'FEATURED PRODUCTS' ? '1px' : '0',
          width: '170px',
          height: '170px',
          transition: 'border-color 0.3s',
        }}
      >
        <Box
          component="img"
          src={category.imgSrc}
          alt={category.name}
          width="170px"
          height="170px"
          borderRadius="50%"
        />
        <Typography
          sx={{
            mt: 1,
            fontWeight: category.name === activeCategory ? 'bold' : 'normal',
            color: category.name === activeCategory ? 'red' : 'inherit',
            transition: 'color 0.3s, font-weight 0.2s',
          }}
        >
          {category.name}
        </Typography>
      </Box>
    ))}
  </Stack>
  );
};

export default Categories;
