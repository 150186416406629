import React from 'react';
import { Box, Typography, Grid, Link, Container } from '@mui/material';
import whatsappIcon from '../../assets/socialmedia_icons/whatsapp.png';
import telegramIcon from '../../assets/socialmedia_icons/telegram.png';
import instagramIcon from '../../assets/socialmedia_icons/instagram.png';
import gmailIcon from '../../assets/socialmedia_icons/gmail.png';

const Footer = () => {
  return (
    <Box 
      component="footer" 
      sx={{ 
        py: 6, 
        px: 2, 
        mt: 'auto', 
        backgroundColor: "#212529",
        color: 'white'
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={3}>
            <img src={require("../../assets/logo-10q.png")} alt="Hero Electro Logo" width={150} />
            <Typography variant="h6" sx={{ color: 'white', mt: 2 }}>
              Hero Electro Sounds & Pixel LED Decors
            </Typography>
            <Typography variant="body2" sx={{ color: 'white', mt: 1 }}>
              Our journey began with a simple belief – that sound and decor have the power to transform ordinary moments into extraordinary memories. With a dedicated team of experts and a wealth of industry knowledge, we’ve grown to become a trusted name in the realm of auditory and visual artistry.
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>Reach Us</Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              Monday – Saturday<br />
              08:00 AM – 10:00 PM
            </Typography>
            <Box mt={2} sx={{ display: 'flex', gap: 1 }}>
              <Link href="#"><img src={whatsappIcon} alt="WhatsApp" style={{ width: 24, height: 24 }} /></Link>
              <Link href="#"><img src={instagramIcon} alt="Instagram" style={{ width: 24, height: 24 }} /></Link>
              <Link href="#"><img src={telegramIcon} alt="Telegram" style={{ width: 24, height: 24 }} /></Link>
              <Link href="#"><img src={gmailIcon} alt="Gmail" style={{ width: 24, height: 24 }} /></Link>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>Customer Service</Typography>
            <Link href="#" sx={{ display: 'block', color: 'white', mb: 1 }}>My account</Link>
            <Link href="#" sx={{ display: 'block', color: 'white' }}>My Orders</Link>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>Useful Hints</Typography>
            <Link href="#" sx={{ display: 'block', color: 'white', mb: 1 }}>Dashboard</Link>
            <Link href="#" sx={{ display: 'block', color: 'white', mb: 1 }}>Shopping</Link>
            <Link href="#" sx={{ display: 'block', color: 'white' }}>Customize</Link>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>Help Desk</Typography>
            <Link href="#" sx={{ display: 'block', color: 'white', mb: 1 }}>Terms and Conditions</Link>
            <Link href="#" sx={{ display: 'block', color: 'white', mb: 1 }}>Refund and Returns Policy</Link>
            <Link href="#" sx={{ display: 'block', color: 'white', mb: 1 }}>Privacy Policy</Link>
            <Link href="#" sx={{ display: 'block', color: 'white', mb: 1 }}>Shipping</Link>
            <Link href="/contactinfo" sx={{ display: 'block', color: 'white' }}>Contact Us</Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
