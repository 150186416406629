import React,{useEffect, useState} from 'react';
import { Box, Typography, Grid, TextField, Button, Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import UserLocationMap from './map-contactus';

import { BASE_URL_Test } from '../../utils/baseUrl';
import { urls } from '../../urls/urls';
import { useFetch } from '../../hooks/useFetch';
import Snackbar from '@mui/joy/Snackbar';

const ContactInfo = () => {

    const [name,setName]=useState('')
    const [email,setEmail]=useState('')
    const [message,setMessage]=useState('')
    const [showModal,setShowModal]=useState({
        showSnack:false,
        message:'',
        snackColor:"",
        snackType:""
      
      });



    const { data,error,isLoading,fetchData}=useFetch(BASE_URL_Test+urls('sendmessage'),{
		method: 'POST',
    // redirect: "follow",
		headers: { 'Content-Type': 'application/json'}

	})


    useEffect(()=>{
        if(error)
        {

        }
        else if(data)
        {
            console.log("send message result",data)
            if(data['status']==="success")
            {
                setName('')
                setEmail('')
                setMessage('')
                setShowModal({...showModal,showSnack:true,message:'Message sent successfully',snackColor:'success',snackType:'success'})
            }
        }

    },[data,error])


    const handleSendMessage=async ()=>{
        if(name)
        {
            if(email)
            {
                if(message)
                {
                    await fetchData({
                        "name":name,
                        "email":email,
                        "message":message
                      })
                }
                else{
                    setShowModal({...showModal,showSnack:true,message:'Please enter message',snackColor:'danger',snackType:'failed'})
                }
            }
            else{
                setShowModal({...showModal,showSnack:true,message:'Please enter email',snackColor:'danger',snackType:'failed'})
            }
        }
        else{
            setShowModal({...showModal,showSnack:true,message:'Please enter name',snackColor:'danger',snackType:'failed'})
        }
       


    }

   


  return (
   
    <Stack sx={{m:9}}>
          <><Box sx={{ mt: 1 }}>
          <UserLocationMap />
      </Box><Box sx={{ mt: 8 }}>
              <Typography variant="h4" sx={{ mb: 4, textAlign: 'center' }}>Contact Info</Typography>
              <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={6} md={3} textAlign="center">
                      <LocationOnIcon sx={{ fontSize: 50, color: '#5a5a5a' }} />
                      <Typography variant="h6" sx={{ mt: 2 }}>Address</Typography>
                      <Typography variant="body2">OM Muruga Electronics Thangavel Nagar Rd, Kambainallur Dharmapuri – 635202</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} textAlign="center">
                      <PhoneIcon sx={{ fontSize: 50, color: '#5a5a5a' }} />
                      <Typography variant="h6" sx={{ mt: 2 }}>Phone Number</Typography>
                      <Typography variant="body2">+91 90421 65139</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} textAlign="center">
                      <EmailIcon sx={{ fontSize: 50, color: '#5a5a5a' }} />
                      <Typography variant="h6" sx={{ mt: 2 }}>E-mail Address</Typography>
                      <Typography variant="body2">heroelectro2020@gmail.com</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} textAlign="center">
                      <AccessTimeIcon sx={{ fontSize: 50, color: '#5a5a5a' }} />
                      <Typography variant="h6" sx={{ mt: 2 }}>Working Days/Hours</Typography>
                      <Typography variant="body2">Mon – Sun / 8:00 AM – 10:00 PM</Typography>
                  </Grid>
              </Grid>
              <Snackbar
        autoHideDuration={3000}
        open={showModal.showSnack}
        variant={'solid'}
        color={showModal.snackType==='success'?"success":"danger"}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
           console.log("onclide",reason)
          }
          // setOpen(false);
          setShowModal({...showModal,showSnack:false,message:'',snackColor:''})
        }}
      >
       {showModal.message}
      </Snackbar>
          </Box><Box sx={{ mt: 8 }}>
              <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                      <Typography variant="h5" sx={{ mb: 4 }}>Send Us a Message</Typography>
                      <TextField fullWidth label="Your Name" sx={{ mb: 2 }} onChange={(event)=>setName(event.target.value)} />
                      <TextField fullWidth label="Your E-mail" sx={{ mb: 2 }}  onChange={(event)=>setEmail(event.target.value)} />
                      <TextField fullWidth label="Your Message" multiline rows={4} sx={{ mb: 2 }} onChange={(event)=>setMessage(event.target.value)} />
                      <Button variant="contained" color="primary" fullWidth sx={{ mb: 7 }} onClick={()=>handleSendMessage()}>Send Message</Button>
                  </Grid>

                  <Grid item xs={12} md={6}>
                      <Typography variant="h5" sx={{ mb: 4 }}>Frequently Asked Questions</Typography>
                      <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography>How durable are Poly Sheets?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Poly Sheets are known for their high impact resistance, UV resistance, and long lifespan.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>
                      <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography>How do you install Pixel LED strips?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Pixel LED strips are installed using adhesive backing or mounting clips, depending on the application.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>
                      <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography>What is the lifespan of Pixel LEDs?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Pixel LEDs typically have a lifespan of around 50,000 hours, depending on usage and environment.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>
                      <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography>What thicknesses are available for Poly Sheets?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Poly Sheets are available in various thicknesses ranging from 2mm to 10mm.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography>Is Cash On Delivery Available?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                              NO CASH ON DELIVERY
                              </Typography>
                          </AccordionDetails>
                      </Accordion>


                      <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography>How many days for delivery?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                              Expected delivery via courier service within 3 to 5 business days
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography>How out of inida will contact you?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                              Please contact us via WhatsApp (+91 90421 65139) with the product name or product ID if you are from outside of India!
                              </Typography>
                          </AccordionDetails>
                      </Accordion>
                  </Grid>
              </Grid>
          </Box></>
    </Stack>
    
  );
};

export default ContactInfo;
