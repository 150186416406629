import React, { useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { styled } from '@mui/joy/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { MenuItem, Select, SelectChangeEvent, Drawer, IconButton as MuiIconButton, List, ListItem, ListItemText, ListItemIcon, useMediaQuery, useTheme, ListItemButton } from '@mui/material';
import { Call, LocationOn, Close as CloseIcon ,Settings as CustomizeIcon, Category, ContactMail, ExitToApp, Favorite, Home, Search, Settings, ShoppingCart, DialerSipOutlined, CallReceived, PhoneAndroid, CallMade, Phone} from '@mui/icons-material';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import IconButtonn from '../Button/iconButton';
import Menu from '@mui/icons-material/Menu';

import colors from '../../utils/customColors';

import SocialMediaIcon from '../Button/socialMediaIcon';

import telegramIcon from '../../assets/socialmedia_icons/telegram.png';
import YouTubeIcon from '../../assets/socialmedia_icons/youtube.png';
import FacebookIcon from '../../assets/socialmedia_icons/facebook.png';
import InstagramIcon from '../../assets/socialmedia_icons/instagram.png';
import Whatsapp from '../../assets/socialmedia_icons/whatsapp3.png';
import { Badge, Button, ModalClose } from '@mui/joy';
import ShoppingCartDrawer from '../landingPage/shopping-card';
import AddressModal from '../payment/mapModal';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';


import { useSelector,useDispatch } from 'react-redux';
import { login,loggedIn } from '../../store/reducers/userReducers';

interface HeaderProps {
  logo: string;
  onLoginClick: () => void;
  onSearchClick: () => void;
  onCustomizeClick: () => void;
  allProdData:any;
  allProdImages:any;
}

const HeaderContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.5s ease-in-out',
});

const TopBar = styled(Box)({
  backgroundColor: colors.headerBg,
  padding: '8px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  height: '8vh',
  borderBottom: '1px solid #E1DDDC',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
});

const FollowUsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',
  marginLeft: '80px',
});

const SocialMediaRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

const MiddleContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
});

const BottomBar = styled(Box)({
  backgroundColor: colors.BottomBarHeader,
  padding: '8px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '8vh',
  borderBottom: '1px solid #E1DDDC',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
});

const ThirdBar = styled(Box)({
  backgroundColor: colors.headerBg,
  padding: '9px 16px',
  display: 'flex',
  justifyContent: 'space-between',  
  alignItems: 'center',
  height: '8vh',
  borderBottom: '1px solid #E1DDDC',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  
});

const LogoImage = styled('img')({
  height: '50px',
  marginLeft: '70px',
});

const SearchBox = styled(TextField)({
  borderRadius: '30px',
  backgroundColor: 'transparent',
  '& .MuiInputBase-root': {
    borderRadius: '30px',
    border: `1px solid #E1DDDC`,
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#D2CFCF',
  },
  width: '600px',
});




const Header: React.FC<HeaderProps> = ({ logo, onLoginClick, onSearchClick, onCustomizeClick ,allProdData,allProdImages}) => {
  const navigate = useNavigate(); 
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);  
  const [category, setCategory] = useState('All Categories');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const userDetails=useSelector((store:any)=>store.user.userLoginDetails)
  const isUserLoggedIn=useSelector((state:any)=>state.user.isLoggedin)
  const userCartDetails=useSelector((store:any)=>store.user)
  console.log("store user cart",userCartDetails)

  const [selectedLocation, setSelectedLocation] = useState('HubSpot, 25 First Street, Cambridge MA 02141, United States');
  const handleConfirm = (location: string) => {
    setSelectedLocation(location);
    setModalOpen(false);
  };

 
  const menuItems = [
    { label: 'Dashboard', icon: <DashboardIcon />, onClick: () => navigate('/') },
    { label: 'Orders', icon: <ShoppingCartIcon />, onClick: () => navigate('/orders') },
    { label: 'Addresses', icon: <LocationOnIcon />, onClick: () => navigate('/addresses') },
    { label: 'Account details', icon: <AccountCircleIcon />, onClick: () => navigate('/accountdetails') },
    { label: 'Wishlist', icon: <FavoriteIcon />, onClick: () => navigate('/wishlist') },
    isUserLoggedIn &&{ label: 'Log out', icon: <ExitToAppIcon />, onClick: () => handleLogout() },
  ];

  const   handleLogout=()=>{
    dispatch(login({token:'',userDetails:{}}))
    dispatch(loggedIn({ loggedin: false }));
  }
  
  const uniqueCategory2: string[] = [];




const category2Set: { [key: string]: boolean } = {};
 
 
  interface DataItem {
    category1: string;
    category2: string;
}


const [uniqueCategory2State, setUniqueCategory2State] = useState<string[]>(["All Categories"]);

  useEffect(()=>{

    if(allProdData)
      {
        allProdData.map((item:any)=>{
          
          // const uniqueCategory2 = [...new Set(data.map(item => item.category2))];
        allProdData.forEach((item:any) => {
          
    if (!category2Set[item.category2]) {

        category2Set[item.category2] = true;
        
        uniqueCategory2.push(item.category2);
    }
    setUniqueCategory2State(uniqueCategory2)
    // console.log("mapped item",uniqueCategory2)
    
});
        })
      }

  },[allProdData])

  // const WhatsAppButton = () => {
  //   const phoneNumber = '1234567890'; // Replace with your phone number
  //   const message = 'Hello, I would like to inquire about...'; // Customize your message
  //   const encodedMessage = encodeURIComponent(message);
  //   const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  
  //   return (
  //     <div>
  //       <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
  //         <button style={{ padding: '10px 20px', backgroundColor: '#25D366', color: 'white', border: 'none', borderRadius: '5px' }}>
  //           Chat on WhatsApp
  //         </button>
  //       </a>
  //     </div>
  //   );
  // };
  
  // export default WhatsAppButton;

  // const InstagramButton = () => {
  //   const instagramUsername = 'yourusername'; // Replace with the actual username
  //   const instagramUrl = `https://instagram.com/${instagramUsername}`;
  
  //   return (
  //     <div>
  //       <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
  //         <button style={{ padding: '10px 20px', backgroundColor: '#E1306C', color: 'white', border: 'none', borderRadius: '5px' }}>
  //           Follow on Instagram
  //         </button>
  //       </a>
  //     </div>
  //   );
  // };
  
  // export default InstagramButton;

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    setCategory(event.target.value as string);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > lastScrollTop) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollTop(scrollTop);

      
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  const handleLoginClick = () => {
    setDrawerOpen(true);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = (open: boolean) => {
    setIsDrawerOpen(open);
  };
  // console.log("uniqueCategory2",uniqueCategory2State)

  const handleClick=()=>{

  }
 
  const [open, setOpen] = React.useState(false);

  const handleNavigation = (path: string) => {
      navigate(path);
      setOpen(false); 
  };
  const handleDialer = () => {
    window.location.href = 'tel:+919042165139';
};
  return (
    <>
     {!isMobile ? (
      <HeaderContainer style={{ transform: isVisible ? 'translateY(0)' : 'translateY(-100%)' }}>
        <TopBar>
          <FollowUsContainer>
            <SocialMediaRow>
                    <a href="https://youtube.com/@HeroElectro2020" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <SocialMediaIcon IconComponent={YouTubeIcon} label="YouTube" />
        </a>
        <a href="https://www.instagram.com/hero_electro_offical/profilecard/?igsh=MXRmMmE4aDU2Y2xnNw==" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <SocialMediaIcon IconComponent={InstagramIcon} label="Instagram" />
        </a>
        <a href="https://www.facebook.com/profile.php?id=100092542658502&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <SocialMediaIcon IconComponent={FacebookIcon} label="Facebook" />
        </a>
        <a href="https://t.me/+HaZ21EsHRJUxZGNl" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <SocialMediaIcon IconComponent={telegramIcon} label="Telegram" />
        </a>
        <a href="https://wa.me/919042165139" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <SocialMediaIcon IconComponent={Whatsapp} label="WhatsApp" />
        </a>


            </SocialMediaRow>
          </FollowUsContainer>
          <MiddleContainer>
            <Typography sx={{ marginRight: 40, fontWeight: 'bold' }}>
              Flat <span style={{ color: 'red', fontSize: '1.5em', fontWeight: 'bold' }}>50% off</span> on first order
            </Typography>
          </MiddleContainer>
          <IconButtonn
            title={userDetails && userDetails['userDetails']['firstname'] ? userDetails['userDetails']['firstname']: 'Login'}
            iconname="login"
            onPress={handleLoginClick}
            sx={{
              backgroundColor: colors.headerBg,
              color: colors.whiteText,
              '&:hover': {
                backgroundColor: colors.headerBg,
                color: colors.whiteText,
              },
              marginRight: '80px',
            }}
          />
        </TopBar>

        <BottomBar>
          <LogoImage src={logo} alt="Logo" />
                  <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginRight: 2,
            cursor: 'pointer', 
            '&:hover': {
              opacity: 0.8, 
            }
          }}
          onClick={() => setModalOpen(true)}
          role="button" 
        >
  {/* <Typography sx={{ lineHeight: 1, fontSize: 13, color: 'gray' }}>Deliver to</Typography>
  <SocialMediaIcon IconComponent={LocationOn} label="533249" /> */}
</Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
            <SearchBox
              variant="outlined"
              placeholder="Search…"
              InputProps={{
                endAdornment: (
                  <>
                    <Select
                      value={category}
                      onChange={handleCategoryChange}
                      displayEmpty
                      sx={{
                        border: 'none',
                        outline: 'none',
                        paddingRight: '10px',
                        minWidth: '150px',
                        textAlign: 'right',
                        '& .MuiSelect-select': {
                          padding: 0,
                          display: 'flex',
                          alignItems: 'center',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '& .MuiSelect-icon': {
                          right: '5px',
                        },
                        boxShadow: 'none',
                      }}
                      disableUnderline
                    >

                    <MenuItem value="All Categories">All Categories</MenuItem>
                    {
                      uniqueCategory2State.map((category) => (
                        <MenuItem key={category} value={category}>
                          {category}
                        </MenuItem>
                      ))
                    }
                      
                     
                    
                      {/* <MenuItem value="All Categories">All Categories</MenuItem>
                      <MenuItem value="Electronics">Electronics</MenuItem>
                      <MenuItem value="Fashion">Fashion</MenuItem>
                      <MenuItem value="Home & Garden">Home & Garden</MenuItem> */}
                    </Select>
                    <InputAdornment position="end">
                      <SearchIcon sx={{ marginLeft: '-10px' }} />
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
            <SocialMediaRow sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 2 }}>
                <Typography sx={{ lineHeight: 1, fontSize: 13, color: 'gray' }}>Call Us</Typography>
                <SocialMediaIcon IconComponent={Call} label="+91 90421 65139" />
              </Box>
              {/* <SocialMediaIcon IconComponent={FavoriteBorderOutlinedIcon} label="Wishlist" /> */}
              <Box onClick={() => toggleDrawer(true)} sx={{ cursor: 'pointer' }}>
                <Badge badgeContent={userCartDetails && userCartDetails.cartDetails && userCartDetails.cartDetails.items.length>0 ? userCartDetails.cartDetails.items.length : ''}>
                <SocialMediaIcon IconComponent={ShoppingCartOutlinedIcon} label="Cart" />
                </Badge>
               
              </Box>

              <ShoppingCartDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
            </SocialMediaRow>
          </Box>
        </BottomBar>

        <ThirdBar>
          <Box sx={{ display: 'flex', alignItems: 'center',ml:10,mr:10 }}>
            <Typography
              sx={{ cursor: 'pointer', marginRight: 4, color: colors.whiteText }}
              onClick={() => navigate('/')}
            >
              Home
            </Typography>

            <Typography
              sx={{ cursor: 'pointer', marginRight: 4, color: colors.whiteText }}
              onClick={() => navigate('/categories')}
            >
              Shopping
            </Typography>

            <Select
              value={category}
              onChange={handleCategoryChange}
              displayEmpty
              sx={{
                border: 'none',
                outline: 'none',
                marginRight: 4,
                textAlign: 'right',
                '& .MuiSelect-select': {
                  padding: 0,
                  display: 'flex',
                  alignItems: 'center',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiSelect-icon': {
                  right: '5px',
                },
                boxShadow: 'none',
                color: colors.whiteText,
              }}
              disableUnderline
            >
              <MenuItem value="All Categories">Categories</MenuItem>
              
      {
        uniqueCategory2State.map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))
      }
                  </Select>

            <Typography
              sx={{ cursor: 'pointer', marginRight: 4, color: colors.whiteText }}
              onClick={() => navigate('/contactinfo')}
            >
              Contact Details
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MuiIconButton sx={{ color: colors.whiteText }} onClick={onCustomizeClick}>
              <CustomizeIcon />
            </MuiIconButton>
            <Typography
              sx={{ cursor: 'pointer', color: colors.whiteText, marginLeft: '4px' ,mr:10}}
              onClick={()=> navigate('/customize')}
            >
              Customize
            </Typography>
          </Box>
        </ThirdBar>
      </HeaderContainer>
       ) : (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 1,
                    backgroundColor: 'white',
                    borderBottom: '1px solid',
                    borderColor: 'neutral.outlinedBorder',
                }}
            >
                <IconButton onClick={() => setOpen(true)}>
                    <Menu  />
                </IconButton>

                <LogoImage src={logo} alt="Logo" />
                <IconButton onClick={handleDialer}>
                    <Phone />
                </IconButton>
                <Box onClick={() => toggleDrawer(true)} sx={{ cursor: 'pointer' }}>
                <Badge badgeContent={userCartDetails && userCartDetails.cartDetails && userCartDetails.cartDetails.items.length>0 ? userCartDetails.cartDetails.items.length : ''}>
                <IconButton>
                    <ShoppingCart />
                </IconButton>
                </Badge>
               
              </Box>
            </Box>

            <Drawer
                open={open}
                onClose={() => setOpen(false)}
                sx={{ width: '250px', flexShrink: 0 }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 2,
                        
                    }}
                >
                    <ModalClose onClick={() => setOpen(false)} />
                </Box>

                <Box sx={{ p: 2 }}>
                  
                    <Input
                        size="sm"
                        placeholder="Search"
                        variant="plain"
                        endDecorator={<Search />}
                        slotProps={{
                            input: {
                                'aria-label': 'Search anything',
                            },
                        }}
                        sx={{
                            borderBottom: '2px solid',
                            borderColor: 'neutral.outlinedBorder',
                            '&:hover': {
                                borderColor: 'neutral.outlinedHoverBorder',
                            },
                        }}
                    />

                </Box>
             

                <Box
      sx={{
        marginTop: isMobile ? 1 : 2,
        padding: isMobile ? '8px' : '16px',
      }}
    >
      <Typography
        sx={{
          color: '#555555',
          marginBottom: isMobile ? 2 : 4,
          fontWeight: 'bold',
          fontSize: isMobile ? 20 : 30,
        }}
      >
        Hi there,
      </Typography>

      <Typography
        sx={{
          color: '#555555',
          marginBottom: isMobile ? 2 : 4,
          fontWeight: 'bold',
          fontSize: isMobile ? 16 : 20,
        }}
      >
        {userDetails && userDetails['userDetails'] ? userDetails['userDetails']['firstname'] : ''}
      </Typography>

      {!isUserLoggedIn && (
        <Button
          sx={{
            backgroundColor: 'blue',
            color: '#fff',
            width: isMobile ? '100%' : '50%',
            padding: isMobile ? '8px 0' : '10px 0',
            '&:hover': {
              backgroundColor: 'blue',
            },
          }}
          onClick={handleLogin}
        >
          Login
        </Button>
      )}
    </Box>

                <List
                    component="nav"
                    sx={{
                        px: 2,
                    }}
                >
                    {[
                        { icon: <Home />, label: 'Home', path: '/' },
                        { icon: <ShoppingCart />, label: 'Shopping', path: '/categories' },
                        { icon: <ContactMail />, label: 'Contact Details', path: '/contactinfo' },
                        { icon: <Favorite />, label: 'Wishlist', path: '/wishlist' },
                        { icon: <Settings />, label: 'Customize', path: '/customize' },
                        // { icon: <Category />, label: 'Categories', path: '/categories' },
                        { icon: <ExitToApp />, label: 'Logout', path: '/logout' },
                    ].map((item, index) => (
                        <ListItemButton
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                px: 2,
                                py: 1,
                            }}
                            onClick={() => handleNavigation(item.path)}
                        >
                            {item.icon}
                            {item.label}
                        </ListItemButton>
                    ))}
                </List>
            </Drawer>
        </React.Fragment>
         )}

      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
  <Box
    sx={{
      width: 350,
      p: 2,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <MuiIconButton onClick={handleDrawerClose}>
        <CloseIcon />
      </MuiIconButton>
    </Box>

    <Box sx={{ marginTop: 2 }}>
      <Typography
        sx={{ color: '#555555', marginBottom: 4, fontWeight: 'bold', fontSize: 30 }}
      >
        Hi there,
      </Typography>

      <Typography
        sx={{ color: '#555555', marginBottom: 4, fontWeight: 'bold', fontSize: 20 }}
      >
        {userDetails && userDetails['userDetails']? userDetails['userDetails']['firstname']:''}
      </Typography>


      {isUserLoggedIn ? '':(  <Button
        sx={{
          backgroundColor: 'blue',
          color: '#fff',
          width: '50%',
          padding: '10px 0',
          '&:hover': {
            backgroundColor: 'blue',
          },
        }}
        onClick={handleLogin}
      >
        Login
      </Button>)}
    
    </Box>

    <List sx={{ marginTop: 4 }}>
      {menuItems.map((item, index) => (
        <ListItem button key={index} onClick={() => {
          item.onClick(); 
          handleDrawerClose(); 
        }}>
          
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.label} />
        </ListItem>
      ))}
    </List>
  </Box>
</Drawer>

      
    </>
  );
};

export default Header;
