import React, { useState } from 'react';
import { Stack, Typography } from '@mui/joy';
import Stepper from '@mui/joy/Stepper';
import Step, { stepClasses } from '@mui/joy/Step';
import StepIndicator, { stepIndicatorClasses } from '@mui/joy/StepIndicator';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import ShippingMethod from './shippingMethod'; 
import { useMediaQuery } from '@mui/material';

const Checkout: React.FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0); 
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(max-width:960px)');

  const handleStepChange = (step: number) => {
    setActiveStep(step); 
  };

  return (
    <Stack
      alignItems="center"
      mt={3}
      spacing={isSmallScreen ? 2 : 4}
      sx={{
        height: '100vh',
        padding: isSmallScreen ? '0 10px' : '0',
      }}
    >
      <Typography 
        sx={{ 
          textAlign: 'center', 
          mb: isSmallScreen ? 2 : 4, 
          fontSize: isSmallScreen ? 20 : 25, 
          color: '#0951BE' 
        }}
      >
        Hero Electro
      </Typography>

      {/* Stepper */}
      <Stack 
        alignItems="center" 
        justifyContent="center" 
        sx={{ width: '100%', maxWidth: isSmallScreen ? '90%' : '500px' }}
      >
        <Stepper
          sx={{
            width: '100%',
            '--StepIndicator-size': isSmallScreen ? '2.5rem' : '3rem',
            '--Step-connectorInset': '0px',
            [`& .${stepIndicatorClasses.root}`]: {
              borderWidth: isSmallScreen ? 3 : 4,
            },
            [`& .${stepClasses.root}::after`]: {
              height: isSmallScreen ? 3 : 4,
            },
            [`& .${stepClasses.completed}`]: {
              [`& .${stepIndicatorClasses.root}`]: {
                borderColor: 'primary.300',
                color: 'primary.300',
              },
              '&::after': {
                bgcolor: 'primary.300',
              },
            },
            [`& .${stepClasses.active}`]: {
              [`& .${stepIndicatorClasses.root}`]: {
                borderColor: 'primary.main',
                color: 'primary.main',
              },
            },
            [`& .${stepClasses.disabled} *`]: {
              color: 'neutral.outlinedDisabledColor',
            },
          }}
        >
          {/* Cart Step */}
          <Step
            completed={activeStep > 0}
            onClick={() => handleStepChange(0)} // Go to Cart
            active={activeStep === 0}
            indicator={
              <StepIndicator
                variant={activeStep === 0 ? "solid" : "outlined"}
                color={activeStep > 0 ? "primary" : activeStep === 0 ? "primary" : "neutral"}
              >
                <ShoppingCartRoundedIcon fontSize={isSmallScreen ? 'small' : 'medium'} />
              </StepIndicator>
            }
          >
            <Typography
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'lg',
                fontSize: isSmallScreen ? '0.875rem' : '1rem',
                letterSpacing: '0.5px',
              }}
            >
              Cart
            </Typography>
          </Step>

       
          <Step
            completed={activeStep > 1}
            onClick={() => handleStepChange(1)} 
            active={activeStep === 1}
            indicator={
              <StepIndicator
                variant={activeStep === 1 ? "solid" : "outlined"}
                color={activeStep > 1 ? "primary" : activeStep === 1 ? "primary" : "neutral"}
              >
                <LocalShippingRoundedIcon fontSize={isSmallScreen ? 'small' : 'medium'} />
              </StepIndicator>
            }
          >
            <Typography
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'lg',
                fontSize: isSmallScreen ? '0.875rem' : '1rem',
                letterSpacing: '0.5px',
              }}
            >
              Checkout
            </Typography>
          </Step>

         
          <Step
            onClick={() => handleStepChange(2)} 
            active={activeStep === 2}
            indicator={
              <StepIndicator
                variant={activeStep === 2 ? "solid" : "outlined"}
                color={activeStep === 2 ? "primary" : "neutral"}
              >
                <CreditCardRoundedIcon fontSize={isSmallScreen ? 'small' : 'medium'} />
              </StepIndicator>
            }
          >
            <Typography
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'lg',
                fontSize: isSmallScreen ? '0.875rem' : '1rem',
                letterSpacing: '0.5px',
                color: activeStep === 2 ? 'primary.main' : 'text.secondary',
              }}
            >
              Payment
            </Typography>
          </Step>
        </Stepper>
      </Stack>

     
      <ShippingMethod activeStep={activeStep} />
    </Stack>
  );
};

export default Checkout;
