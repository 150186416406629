// import * as React from 'react';
// import AspectRatio from '@mui/joy/AspectRatio';
// import Card from '@mui/joy/Card';
// import CardContent from '@mui/joy/CardContent';
// import CardOverflow from '@mui/joy/CardOverflow';
// import Divider from '@mui/joy/Divider';
// import Typography from '@mui/joy/Typography';
// import IconButton from '@mui/joy/IconButton';
// import Favorite from '@mui/icons-material/Favorite';
// import { Box, Button, Grid, Stack } from '@mui/material';



// interface Product {
//   id: number;
//   productid: string;
//   category1: string;
//   category2: string;
//   // Add other properties as needed
// }
// interface ProdListProps {
//   prodData: Product[];
// }
// // export default function
// const ProductList: React.FC<ProdListProps> = (prodData) => {

// console.log("prodData trending",prodData['prodData']);
//     const productsData = [
//         { id: 1, name: 'POLY SHEETS', images: ['./THUMB_POLY-600x600.png'], count: 1157 },
//         { id: 2, name: 'CONTROLLER DATA', images: ['./THUMB_POLY-600x600.png'], count: 1 },
//         { id: 3, name: 'OWN BRAND PCB’S', images: ['./THUMB_POLY-600x600.png'], count: 4 },
//         { id: 4, name: 'IC PROGRAMMER’S', images: ['./THUMB_POLY-600x600.png'], count: 3 },
//         { id: 5, name: 'SD CARDS', images: ['./THUMB_POLY-600x600.png'], count: 4 },
//       ];
//   const productList = [
//     {
//       imageUrl: './productManagement.svg',
//       title: 'HEGNGT1 VINAYAGAR DESIGNER GATE 24X21.5 FEET',
      
//       originalPrice: '₹12,967.00',
//       discountedPrice: '₹12,708.00',
//       isInStock: true,
//     },
//     {
//       imageUrl: './productManagement.svg',
//       title: 'HEDM45 MARIAMMAN BIG 20X20 FEET 4488 HOLES',
     
//       originalPrice: '₹10,000.00',
//       discountedPrice: '₹9,800.00',
//       isInStock: true,
//     },
//     {
//         imageUrl: './productManagement.svg',
//         title: 'HEDM57 PADMAVTI AMMAN BIG 10X16 FEET 9480 HOLES IN 4PARTS',
       
//         originalPrice: '₹10,000.00',
//         discountedPrice: '₹9,800.00',
//         isInStock: false,
//       },
//       {
//         imageUrl: './productManagement.svg',
//         title: 'HEGNGT2 VINAYAGAR DESIGNER GATE 20X18 FEET',
       
//         originalPrice: '₹10,000.00',
//         discountedPrice: '₹9,800.00',
//         isInStock: true,
//       },
//       {
//         imageUrl: './productManagement.svg',
//         title: 'HESK13 KRISHNA AND ARJUNAN RIDING ON HORSE CART BIG 15X22 FEET',
       
//         originalPrice: '₹10,000.00',
//         discountedPrice: '₹9,800.00',
//         isInStock: false,
//       },
//       {
//         imageUrl: './productManagement.svg',
//         title: 'HEDM14.3 MARIAMMAN WITH NEEM TREE 15X18 FEET 4751 HOLES 5 PARTS',
//         description: 'Example Description for Product 2',
//         originalPrice: '₹10,000.00',
//         discountedPrice: '₹9,800.00',
//         isInStock: false,
//       },
//   ];
//   {
//     prodData['prodData'].map((item)=>{
//       console.log("item data",item)
//     })
//   }

//   const uniqueCategories = {
//     category1: [...new Set(prodData['prodData'].map(item => item.category1))],
//     category2: [...new Set(prodData['prodData'].map(item => item.category2))]
// };

// console.log("uniqueCategories",uniqueCategories)
//   return (
//     <div style={{ backgroundColor: '#f8f2f2', padding: '2rem',paddingLeft:'8rem',paddingRight:'8rem'}}>
     
//      <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//                 <Typography
//                     sx={{ 
//                     textAlign: 'center', 
//                     color: '#E53935', 
//                     marginBottom: '0.5rem',  
//                     fontWeight: 'bold', 
//                     fontSize: 25 ,
//                     fontFamily: 'Krub, sans-serif',
                    
//                     }}
//                 >
//                 Categories
//                 </Typography>

            
//                 </Stack>

//                    { prodData && prodData['prodData'].length>0 ?
//                     (prodData['prodData'].map((item))=>{
//                       console.log("object",item)
//                     }) :''
//                    }
                    
                
//                 <img
//                 src='./THUMB_POLY-600x600.png'
//                 alt=""
//                 style={{  height: 'auto',  borderRadius: '4px',width:'20%' }} 
//               />
              
//               <Typography sx={{alignItems:'center',backgroundColor:'red'}}>
//                 Polysheet
//               </Typography>
//                <img
//                 src='./THUMB_POLY-600x600.png'
//                 alt=""
//                 style={{  height: 'auto',  borderRadius: '4px',width:'20%' }} 
//               />
//                <img
//                 src='./THUMB_POLY-600x600.png'
//                 alt=""
//                 style={{  height: 'auto',  borderRadius: '4px',width:'20%' }} 
//               />
//                <img
//                 src='./THUMB_POLY-600x600.png'
//                 alt=""
//                 style={{  height: 'auto',  borderRadius: '4px',width:'20%' }} 
//               />
//                <img
//                 src='./THUMB_POLY-600x600.png'
//                 alt=""
//                 style={{  height: 'auto',  borderRadius: '4px',width:'20%' }} 
//               />

//       {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
//       <Box
//       sx={{
//         flex: '1',
//         textAlign: 'center',
//         maxWidth: '40%',
//         position: 'relative',
//         overflow: 'hidden',  
//         borderRadius: '8px',  
//       }}
//     >
//       <Box
//         component="img"
//         src="./proguctimage.png"
//         alt="Ganesha Image"
//         sx={{
//           width: '100%',
//           height: '100%',
//           transition: 'transform 0.3s ease-in-out', 
//           '&:hover': {
//             transform: 'scale(1.1)', 
//           },
//         }}
//       />
//       <Button
//         sx={{
//           position: 'absolute',
//           top: '29px',
//           left: '26px',
//           backgroundColor: '#E53935',
//           color: '#fff',
//           padding: '10px 20px',
//           borderRadius: '20px',
//           fontSize: '13px',
//           fontFamily: 'Krub, sans-serif',
//           fontWeight: 'bold',
//           '&:hover': {
//             backgroundColor: '#D32F2F',  
//           }
//         }}
//       >
//         BUY NOW
//       </Button>
//     </Box>

       
//         <div style={{ 
//           flex: '1', 
//           display: 'grid', 
//           gridTemplateColumns: 'repeat(3, 1fr)', 
//           gap: '0.5rem', 
//           padding: '0px',
//           margin: '0px',
//           width: '50%' 
//         }}>
//           {productList.map((product, index) => (
//             <Card key={index} variant="outlined" sx={{ 
//               width: '90%', 
//               padding: 0, 
//               position: 'relative',
//               margin: '0', 
//               height: '100%' 
//             }}>
//               <CardOverflow>
//                 <AspectRatio ratio="1">
//                   <img src={product.imageUrl} alt={product.title} style={{ width: '100%', height: 'auto' }} />
//                 </AspectRatio>
//                 <IconButton
//                   aria-label="Add to favorites"
//                   size="sm"
//                   variant="solid"
//                   color="danger"
//                   sx={{
//                     position: 'absolute',
//                     zIndex: 2,
//                     borderRadius: '50%',
//                     right: '0.5rem',
//                     top: '0.5rem',
//                   }}
//                 >
//                   <Favorite />
//                 </IconButton>
//               </CardOverflow>
//               <CardContent sx={{ padding: '8px' }}>
//                 <Typography sx={{ fontWeight: 'bold', fontSize: '14px' ,fontFamily: 'Krub, sans-serif'}}>
//                   {product.title}
//                 </Typography>
//                 <Typography sx={{ textDecoration: 'line-through', fontSize: '12px', color: 'grey' ,fontFamily: 'Krub, sans-serif'}}>
//                   {product.originalPrice}
//                 </Typography>
//                 <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: 'red',fontFamily: 'Krub, sans-serif' }}>
//                   {product.discountedPrice}
//                 </Typography>
//                 <Typography sx={{ fontSize: '12px', color: product.isInStock ? 'green' : 'red', marginTop: '0.5rem' ,fontFamily: 'Krub, sans-serif'}}>
//                   {product.isInStock ? 'In Stock' : 'Out of Stock'}
//                 </Typography>
//               </CardContent>
              
//             </Card>
//           ))}
//         </div>

//       </div> */}
//     </div>
//   );
// }

// export default ProductList;



// import React from 'react';
// import { Box, Grid, Stack, Typography } from '@mui/material';

// interface Product {
//   id: number;
//   productid: string;
//   category1: string;
//   category2: string;
//   // Add other properties as needed
// }

// interface ProdListProps {
//   prodData:  Product[] ; // Adjust according to your data structure
// }

// const ProductList: React.FC<ProdListProps> = ({ prodData }) => {
//   console.log("prodData catergories",prodData)
//   if (!prodData  || prodData.length === 0) {
//     return <Typography>No products available.</Typography>;
//   }

//   const uniqueCategories = [{
//     id:0,
//     category1: [...new Set(prodData.map(item => item.category1))],
//   }];

//   return (
//     <div style={{ backgroundColor: '#f8f2f2', padding: '2rem', paddingLeft: '8rem', paddingRight: '8rem' }}>
//       <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//         <Typography
//           sx={{
//             textAlign: 'center',
//             color: '#E53935',
//             marginBottom: '0.5rem',
//             fontWeight: 'bold',
//             fontSize: 25,
//             fontFamily: 'Krub, sans-serif',
//           }}
//         >
//           Categories
//         </Typography>
//       </Stack>

//       {uniqueCategories.map(item => {
//         console.log("object", item);
//         return (
//           <Box key={item.id} sx={{ textAlign: 'center'}}>
//             <img
//               src='./THUMB_POLY-600x600.png'
//               // alt={item.category1}
//               style={{ height: 'auto', borderRadius: '4px', width: '20%' }}
//             />
//             <Typography>{item.category1}</Typography>
//           </Box>
//         );
//       })}
//     </div>
//   );
// };

// export default ProductList;


import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { addCategorySelected } from '../../store/reducers/userReducers';
import { useNavigate } from 'react-router-dom';

interface Product {
  id: number;
  productid: string;
  category1: string;
  category2: string;
  // Add other properties as needed
}

interface ProdListProps {
  prodData: Product[];
}

const ProductList: React.FC<ProdListProps> = ({ prodData }) => {
  console.log("prodData categories", prodData);
  const dispatch=useDispatch();
  const navigate = useNavigate();
  if (!prodData || prodData.length === 0) {
    return  <Typography
    sx={{
      textAlign: 'center',
      marginTop: { xs: 3, sm: 5 },    
      marginBottom: { xs: 3, sm: 5 }, 
    }}
  >
    No products available.
  </Typography>
  }

  // Create an array of unique categories
  const uniqueCategories = [...new Set(prodData.map(item => item.category1))];

  return (
    <div style={{ backgroundColor: '#f8f2f2', padding: '2rem', paddingLeft: '8rem', paddingRight: '8rem' }}>
      <Stack sx={{
    display: 'flex',          
    justifyContent: 'center', 
    alignItems: 'center',      
              
  }}>
        <Typography
          sx={{
            textAlign: 'center',
            color: '#E53935',
            marginBottom: '0.5rem',
            fontWeight: 'bold',
            fontSize: 25,
            fontFamily: 'Krub, sans-serif',
          }}
        >
          Categories
        </Typography>
      </Stack>

      <Grid container spacing={2} justifyContent="start" >
        {uniqueCategories.map((category, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <Box sx={{ textAlign: 'center',cursor: 'pointer',}} onClick={()=>{
              console.log("box clicked",uniqueCategories[index])
              
              dispatch(addCategorySelected({categorySelected:uniqueCategories[index]})) 
              navigate('/categorywiseproduct')
              }}>
              <img
                src='./THUMB_POLY-600x600.png'
                alt={category}
                style={{ height: 'auto', borderRadius: '4px', width: '80%' }}
              />
              <Typography>{category}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ProductList;


