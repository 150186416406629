import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Divider} from '@mui/material';
import { Email as EmailIcon, Lock as LockIcon, Person as PersonIcon,Phone as PhoneIcon } from '@mui/icons-material';
import CustomButton from '../../../components/Button/customButton';
import CustomTextField from '../../../components/Button/customTextField';
import { BASE_URL,BASE_URL_Test } from '../../../utils/baseUrl';
import { Alert } from '@mui/joy';
import {IconButton} from '@mui/joy';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useFetch } from '../../../hooks/useFetch';
import Snackbar from '@mui/joy/Snackbar';
import { emailValidation,phoneNumberValidation,passwordValidation } from '../../../utils/textFieldValidations';

// import Typography as T1 from '@mui/joy';

interface RegisterProps {
  registerButtonText?: string;
  googleButtonText?: string;
}

const Register: React.FC<RegisterProps> = ({
  registerButtonText = "Register",
  googleButtonText = "Signup with Google",
}) => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [showModal,setShowModal]=useState({
    showSnack:false,
    message:'',
    snackColor:"",
    snackType:""
  
  });

  const {data,isLoading,error,fetchData}=useFetch(BASE_URL_Test+'api/v1/auth/register/',{
    method:"POST",
    headers: { 'Content-Type': 'application/json' }
  })


  useEffect(()=>{

    if(error)
    {
        console.log("register error",error)
    }
    else if(data)
    {
      console.log("register data",data)
      if(data['status']==='success')
      {
          setShowModal({...showModal,showSnack:true,
                  message:'User Registered Successfully',
                    snackColor:'success',snackType:"success"})
                    setName('')
                    setPassword('')
                    setPhone('')
                    setEmail('')
                    
      }
      else if(data['status']==='failed')
      {
        setShowModal({...showModal,showSnack:true,
          message:data['data']['email'],
            snackColor:'danger',snackType:"failed"})
      }
    }

  },[data,error])


  const handleRegister= async ()=>{

    if(name)
    {
      if(phone && phoneNumberValidation(phone))
        {
          if(email && emailValidation(email))
            {
              if(password && passwordValidation(password))
                {
                  fetchData({
    
                    "email":email,
                    "password":password,
                    "phone":phone,
                    "firstname":name
                })
                }else{
                  setShowModal({...showModal,showSnack:true,message:'Please enter proper password',snackColor:'danger',snackType:'failed'})
                } 
            }else{
              setShowModal({...showModal,showSnack:true,message:'Please enter proper email',snackColor:'error',snackType:'failed'})
            }
          
        }else{
          setShowModal({...showModal,showSnack:true,message:'Please enter proper phonenumber',snackColor:'danger',snackType:'failed'})
        }
    
    }
    else{
      setShowModal({...showModal,showSnack:true,message:'Please enter name',snackColor:'error',snackType:'failed'})
    }

  

    

  }


 
  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          background: 'linear-gradient(135deg, #0A53C3 20%, #689EF1 90%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          padding: '20px',
        }}
      >
        <Typography component="h1" sx={{ mb: 2 }}>
          Login to your account.
        </Typography>
        <img src="/LoginImage2.png" alt="Hero Electro" style={{ width: '190px' }} />
        <Typography component="h2" sx={{ mt: 2 }}>
          HERO ELECTRO
        </Typography>
        <Typography component="p" sx={{ mt: 1 }}>
          "Customize your Poly Sheets and Pixel LED at a great price!"
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} md={6} display="flex" alignItems="center" justifyContent="center">
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '400px',
            padding: '20px',
            backgroundColor: '#fff',
          }}
        >
           {/* <Alert
          key={'SUccess'}
          sx={{ alignItems: 'flex-start' }}
          startDecorator={<CheckCircleIcon />}
          variant="soft"
          color={'success'}
          endDecorator={
            <IconButton variant="soft" color={'success'}>
              <CloseRoundedIcon />
            </IconButton>
          }
        >
          <div>
            <div>{'Success'}</div>
            <Typography variant='h2'  color={'success'} style={{width:'40vw'}}>
             User Registered Successfully
            </Typography>
          </div>
        </Alert> */}

<Snackbar
        autoHideDuration={3000}
        open={showModal.showSnack}
        variant={'solid'}
        color={showModal.snackType==='success'?"success":"danger"}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
           console.log("onclide",reason)
          }
          // setOpen(false);
          setShowModal({...showModal,showSnack:false,message:'',snackColor:''})
        }}
      >
       {showModal.message}
      </Snackbar>
          <Typography component="h2" sx={{ mb: 2, color: '#333', fontSize: 20 }}>
            Please enter your details
          </Typography>
          <CustomTextField
            label="Name"
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
            icon={<PersonIcon />}
          />
           <CustomTextField
            label="Phone Number"
            value={phone}
            type='tel'
            onChange={(e) => setPhone(e.target.value)}
            icon={<PhoneIcon />}
          />
           <CustomTextField
            label="Email"
            value={email}
            type='email'
            onChange={(e) => setEmail(e.target.value)}
            icon={<EmailIcon />}
          />
           <CustomTextField
            label="Password"
            value={password}
            type='password'
            onChange={(e) => setPassword(e.target.value)}
            icon={<LockIcon />}
          />
         <CustomButton text={registerButtonText} variant="contained"  onClick={handleRegister}/>
          <Divider sx={{ width: '110%', mb: 2 }}>
            <Typography variant="body2" color="textSecondary">
              OR
            </Typography>
          </Divider>
          <CustomButton
            text={googleButtonText}
            variant="outlined"
            startIcon={<Box component="img" src='/google.png' alt="Google" sx={{ width: 20, height: 20 }} />}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Register;
