import React, { useState } from 'react';
import { Box, Typography, TextField, Button, IconButton, Switch, Grid } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const SecuritySettings = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(true);
  const [logoutAllDevices, setLogoutAllDevices] = useState(false);

  const handlePasswordVisibilityToggle = (setFunction: React.Dispatch<React.SetStateAction<boolean>>) => {
    setFunction((prev) => !prev);
  };

  return (
    <Box sx={{ bgcolor: 'white', p: 4, borderRadius: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Change Password
      </Typography>

      {/* Change Password Section */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Old Password"
            type={showOldPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => handlePasswordVisibilityToggle(setShowOldPassword)}>
                  {showOldPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="New Password"
            type={showNewPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => handlePasswordVisibilityToggle(setShowNewPassword)}>
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Repeat New Password"
            type={showRepeatPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => handlePasswordVisibilityToggle(setShowRepeatPassword)}>
                  {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>

      {/* Action Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 4 }}>
        <Button variant="outlined" sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button variant="contained" sx={{ bgcolor: '#3f51b5', '&:hover': { bgcolor: '#303f9f' } }}>
          Save
        </Button>
      </Box>

      {/* Two-Factor Authentication */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Two-Factor Authentication
      </Typography>
      <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
        <Grid item xs={6}>
          <Typography variant="body1">2-Step Verification phones</Typography>
          <Typography variant="caption" color="textSecondary">
            tell me about the information after making the transaction
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            value="+919875172862"
            InputProps={{
              endAdornment: <Button variant="text">change</Button>,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            checked={twoFactorEnabled}
            onChange={() => setTwoFactorEnabled(!twoFactorEnabled)}
          />
        </Grid>
      </Grid>

      {/* Logout of All Devices */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Logout
      </Typography>
      <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
        <Grid item xs={6}>
          <Typography variant="body1">Logout of All Devices</Typography>
        </Grid>
        <Grid item xs={4}>
          <Switch
            checked={logoutAllDevices}
            onChange={() => setLogoutAllDevices(!logoutAllDevices)}
          />
        </Grid>
      </Grid>

      {/* Logout Action Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" sx={{ bgcolor: '#3f51b5', '&:hover': { bgcolor: '#303f9f' } }}>
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default SecuritySettings;
