import exp from 'constants';
import React, { Component } from 'react'
import {
    Box,
    Grid,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Typography,
    IconButton,
    Collapse,
    Chip,
    Button,
    MenuItem,
    Select,
    ToggleButton,
    ToggleButtonGroup,
    useMediaQuery,
    useTheme,
  } from '@mui/material';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RoundNumber } from '../../utils';
import { addProduct } from '../../store/reducers/userReducers';
import { BASE_URL_Test } from '../../utils/baseUrl';

interface SelectedCategoryProps{
  selectedCategory:string;
  
}

const CategoryWiseProduct:React.FC<SelectedCategoryProps> = ({selectedCategory}) => {
    const navigate = useNavigate();
  const dispatch =useDispatch()
  let productsData: any[]=[]
  const allProducts=useSelector((state:any)=>state.user.allPoductsData)
  const categogrySeleted=useSelector((state:any)=>state.user.categorySelectedName)
    //   console.log("selectedCategories",productsData)
    // const filteredProducts = allProducts.length > 0 
    //   ? productsData.filter((product: any) => allProducts.includes("Polysheet"))
    //   : productsData;
  console.log("categogrySeleted",categogrySeleted)
  
  
    const filteredProducts = allProducts.length > 0 
    ? allProducts.filter((product: any) => 
      categogrySeleted.includes(product.category1.trim())
      )
    : productsData;
  
  
      console.log("filteredProducts",filteredProducts)



      const handleProductClick = (item: {productname:string,productid:string}, index: number) => {
        console.log("item clicked",item.productname)
        // dispatch(addProduct({proddetails:item})) //  dispatch(addProduct({proddetails:{"productname":"Test","image":"imageurl","originalPrice":"100","discountedPrice":"30","discount":"3" }}))
      //  navigate(`/product`,{state:{productid:item.productid}});
      const encodedProductName = item.productname?item.productname.replace(/\s+/g, '-'):''; 

      console.log("productid",item.productid,encodedProductName)
      navigate(`/product/${item.productid}/${encodedProductName}`);
     
      };
  
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px', 
        }}
      > 
        {filteredProducts && filteredProducts.length > 0 ? 
          filteredProducts.map((product:any, index:number) => (
            <Box 
              key={product.id} 
              sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'space-between', 
                width: '200px', 
                height: '300px', 
                marginBottom: '20px', 
                padding: '10px', 
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)', 
                borderRadius: '8px', 
                backgroundColor: '#fff',
                cursor: 'pointer', 
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.05)',
                }
              }}
              onClick={() => handleProductClick(product, index)} 
            > 
              <img
                src={`${BASE_URL_Test}/media/` + product.images}
                alt={product.productname}
                style={{
                  width: '100%', 
                  height: '190px', 
                  objectFit: 'cover', 
                  marginBottom: '8px'
                }}
              />
              <Typography component="div" sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: 13 }}>
                {product.productname}
              </Typography>
              <Typography sx={{ color: 'red', fontSize: 22, fontWeight: 700 }}>
                ₹ {RoundNumber(parseInt(product.price))}
              </Typography>
            </Box>
          ))
          : <Typography>No Products Available</Typography>
        }
      </Box>
    );
  };

export default CategoryWiseProduct