import React, { ChangeEvent, useState } from 'react';
import { Box, Card, IconButton, Stack, TextField, Typography, Button, Grid } from '@mui/material';

const Addresses = () => {
  const [addresses, setAddresses] = useState([
    {
      id: 1,
      type: 'Billing',
      addressLine1: '1491 Ludhiana Street',
      addressLine2: '',
      city: 'Punjab',
      country: 'India',
      postalCode: '533249',
    },
  ]);

  const [newAddress, setNewAddress] = useState({
    addressLine1: '',
    addressLine2: '',
    city: '',
    country: '',
    postalCode: '',
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewAddress((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Box margin={5}>
      <Typography variant="h6" gutterBottom>
        The following addresses will be used on the checkout page by default.
      </Typography>

      <Grid container spacing={4}>
        
        <Grid item xs={12} md={6}>
          <Card variant="outlined" sx={{ padding: 3 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Billing info</Typography>
              <Button variant="text"  sx={{color:'#d13d11',fontWeight:'bold'}}>
                Edit
              </Button>
            </Stack>
            {addresses.map((address) => (
              <Box key={address.id} sx={{ marginTop: 2 }}>
                <Typography>{address.addressLine1}</Typography>
                <Typography>{address.addressLine2}</Typography>
                <Typography>
                  {address.city}, {address.country}
                </Typography>
                <Typography>{address.postalCode}</Typography>
              </Box>
            ))}
          </Card>
        </Grid>

       
        <Grid item xs={12} md={6}>
          <Card variant="outlined" sx={{ padding: 3 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Shipping info</Typography>
              <Button variant="text" sx={{color:'#d13d11',fontWeight:'bold'}}>
                Edit
              </Button>
            </Stack>
            <Box sx={{ marginTop: 2 }}>
              <Typography>You have not set up this type of address yet.</Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>

    
      <Box mt={5}>
        <Typography variant="h6">Add a new address</Typography>
        <Box my={2}>
          <TextField
            name="addressLine1"
            label="Address Line 1"
            value={newAddress.addressLine1}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box my={2}>
          <TextField
            name="addressLine2"
            label="Address Line 2"
            value={newAddress.addressLine2}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box my={2}>
          <TextField
            name="city"
            label="City"
            value={newAddress.city}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box my={2}>
          <TextField
            name="country"
            label="Country"
            value={newAddress.country}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box my={2}>
          <TextField
            name="postalCode"
            label="Postal Code"
            value={newAddress.postalCode}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Addresses;
