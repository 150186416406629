import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Avatar, Grid, MenuItem, InputAdornment, IconButton } from '@mui/material';
import { Delete, CalendarToday } from '@mui/icons-material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const PersonalInformation = () => {
  const [storeImage, setStoreImage] = useState<string | ArrayBuffer | null>(null);
  const [profileImage, setProfileImage] = useState<string | ArrayBuffer | null>(null);
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(new Date());

  const handleStoreImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          setStoreImage(event.target.result); // Handling undefined safely
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          setProfileImage(event.target.result); // Handling undefined safely
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <Box sx={{ bgcolor: 'white', p: 4, borderRadius: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Personal Information
      </Typography>

      {/* Photo Store Section */}
      <Grid container spacing={2} sx={{ mb: 3 }} alignItems="center">
        <Grid item xs={12} sm={3}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Photo Store
          </Typography>
          <Avatar
            alt="Store"
            src={storeImage ? storeImage.toString() : '/profile.webp'}
            sx={{ width: 80, height: 80, mb: 1 }}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Button
            variant="contained"
            component="label"
            sx={{ mr: 2, bgcolor: '#3f51b5', '&:hover': { bgcolor: '#303f9f' } }}
          >
            Upload image
            <input hidden accept="image/*" type="file" onChange={handleStoreImageChange} />
          </Button>
          <Button variant="outlined"  startIcon={<Delete />} onClick={() => setStoreImage(null)}>
            Delete
          </Button>
        </Grid>
      </Grid>

      {/* Store Name */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Store name"
            variant="outlined"
            defaultValue="Rainer Enterprise"
          />
        </Grid>
      </Grid>

    
      <Grid container spacing={2} sx={{ mb: 3 }} alignItems="center">
        <Grid item xs={12} sm={3}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Photo Profile
          </Typography>
          <Avatar
            alt="Owner"
            src={profileImage ? profileImage.toString() : '/profile.webp'}
            sx={{ width: 80, height: 80, mb: 1 }}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Button
            variant="contained"
            component="label"
            sx={{ mr: 2, bgcolor: '#3f51b5', '&:hover': { bgcolor: '#303f9f' } }}
          >
            Upload image
            <input hidden accept="image/*" type="file" onChange={handleProfileImageChange} />
          </Button>
          <Button variant="outlined"  startIcon={<Delete />} onClick={() => setProfileImage(null)}>
            Delete
          </Button>
        </Grid>
      </Grid>

      {/* Owner Name */}
      <TextField fullWidth label="Owner name" variant="outlined" defaultValue="Rainer Yeger" sx={{ mb: 3 }} />

      {/* Email */}
      <TextField fullWidth label="Email" variant="outlined" defaultValue="yourname@gmail.com" sx={{ mb: 3 }} />

      {/* Date of Birth */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Date of Birth"
          value={dateOfBirth}
          onChange={(newValue) => setDateOfBirth(newValue)}
         
        />
      </LocalizationProvider>

      {/* Country, State, City */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={4}>
          <TextField select fullWidth label="Country" defaultValue="India">
            <MenuItem value="India">India</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField select fullWidth label="State" defaultValue="Andhra Pradesh">
            <MenuItem value="Andhra Pradesh">Andhra Pradesh</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField select fullWidth label="City" defaultValue="Vizag">
            <MenuItem value="Vizag">Vizag</MenuItem>
          </TextField>
        </Grid>
      </Grid>

      {/* Address */}
      <TextField fullWidth label="Address" variant="outlined" defaultValue="Rainer Enterprise" sx={{ mb: 3 }} />

      {/* Pin Code */}
      <TextField fullWidth label="Pin Code" variant="outlined" defaultValue="002306" sx={{ mb: 3 }} />

      {/* GST No */}
      <TextField fullWidth label="GST No" variant="outlined" defaultValue="Rainer Enterprise" sx={{ mb: 3 }} />

      {/* Action Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" sx={{ mr: 2, color: '#3f51b5', borderColor: '#3f51b5' }}>
          Cancel
        </Button>
        <Button variant="contained" sx={{ bgcolor: '#3f51b5', '&:hover': { bgcolor: '#303f9f' } }}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default PersonalInformation;
