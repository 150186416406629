import React, { useEffect, useState } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { Box, Typography, Button, CardContent, Stack, Divider, Grid, Checkbox, RadioGroup, Breadcrumbs, Link ,Modal,ModalDialog,ModalClose,DialogTitle,DialogContent} from '@mui/joy';

import { Card, FormControl, FormControlLabel, FormLabel, IconButton ,useMediaQuery} from '@mui/material';



import { Favorite, FavoriteBorder, OpenInFull, ShoppingCart } from '@mui/icons-material';
import Radio from '@mui/joy/Radio';

import telegramIcon from '../../assets/socialmedia_icons/telegram.png';
import FacebookIcon from '../../assets/socialmedia_icons/facebook.png';
import InstagramIcon from '../../assets/socialmedia_icons/instagram.png';
import Whatsapp from '../../assets/socialmedia_icons/whatsapp3.png'; 
import Twitter from '../../assets/socialmedia_icons/twitter.png';
import '@fontsource/krub';

import { useLocation } from "react-router-dom";
import styled from '@emotion/styled';
import { useFetch } from '../../hooks/useFetch';
import { BASE_URL_Test } from '../../utils/baseUrl';
import { urls } from '../../urls/urls';
import { useDispatch,useSelector } from 'react-redux';
import { RoundNumber } from '../../utils';
import Snackbar from '@mui/joy/Snackbar';
import { useTheme } from '@mui/material/styles';

import { addToCart,addToWishList,setModalProperties } from '../../store/reducers/userReducers';
import { keyframes } from "@mui/system";

const flyUp = keyframes`
  0% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(-100px); opacity: 0; }
`;

interface QuantitySelectorProps {
    quantitySelected: (quantity: number) => void;
}

interface ProductImage {
  url: string;
  // Add other properties as needed
}
interface ProductData {

    productid: string;
        productname: string;
        slug: string;
        description:string;
        price: string;
        discount: string;
        cutoffprice: string;
        category1:string;
        category2: string;
        category3: string;
        category4: string;
        category5: string;
        category6: string;
        sku: string;
        imageid:string;
        imagepath: string;
        imagedescription: string;
        weight: string;
        width:string;
        height: string;
        holescount:string;
        dateadded:string;
        tags: string;
        published: string;
        featured:string;
        instock:string;
        stock: string;
        taxstatus:string;
        salesstart:string;
        salesend:string;
        backorders:string;
        sunrise: string;
        hero1_5bl: string;
        hero2bl: string;
        hero1_5lt:string;
        yt_link: string;
        options: string;
}
const QuantitySelector: React.FC<QuantitySelectorProps> = ({quantitySelected}) => {
    const [quantity, setQuantity] = React.useState(1);

   
    

    const handleIncrease = () => {
        setQuantity((prevQuantity) => {
            const newQuantity = prevQuantity + 1;
            console.log("Previous quantity:", newQuantity);
            quantitySelected(newQuantity);
            return newQuantity; 
        });
    };
    const handleDecrease = () => {
        setQuantity((prevQuantity) => {
            if(prevQuantity>=1)
            {
                const newQuantity = prevQuantity - 1;
                console.log("Previous quantity:", newQuantity);
                quantitySelected(newQuantity);
                return newQuantity; 
            }else{
                return 1
            }
           
        });
    };


 
   

   

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '12px', p: 1 }}>
            <IconButton onClick={handleDecrease}>
                <Typography sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>−</Typography>
            </IconButton>
            <Typography sx={{ mx: 2 }}>{quantity}</Typography>
            <IconButton onClick={handleIncrease}>
                <Typography sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>+</Typography>
            </IconButton>
        </Box>
    );
};

const VideoWrapper = styled(Box)({
  width: '100%',
  height: '100%',
});


// const optionsData = [
//     {
//       title: "Poly Sheet",
//       price: 0,
//       type: "radio",
//       required: 1,
//       sort_order: 1,
//       values: [
//         {
//           title: "Required",
//           price: 0,
//           sort_order: 1,
//           is_selected: 1,
//         },
//         {
//           title: "Not Required",
//           price: -1316,
//           sort_order: 2,
//         },
//       ],
//     },
//     {
//       title: "Poly Sheet Color",
//       price: 0,
//       type: "radio",
//       required: 1,
//       sort_order: 2,
//       values: [
//         {
//           title: "Black",
//           price: 0,
//           sort_order: 1,
//           is_selected: 1,
//         },
//         {
//           title: "White",
//           price: 112,
//           sort_order: 2,
//         },
//       ],
//     },
//     {
//       title: "Poly Sheet Size",
//       price: 0,
//       type: "radio",
//       required: 1,
//       sort_order: 3,
//       values: [
//         {
//           title: "12 MM FOR (5V MULTI-COLOR PIXEL LED)",
//           price: 0,
//           sort_order: 1,
//           is_selected: 1,
//         },
//         {
//           title: "09 MM FOR (12V SINGLE COLOR PIXEL LED)",
//           price: 0,
//           sort_order: 2,
//         },
//       ],
//     },
//     {
//       title: "Additional Products and Prices",
//       price: 0,
//       type: "checkbox",
//       required: 0,
//       sort_order: 4,
//       values: [
//         {
//           title: "DATA (CONTROLLERS)",
//           price: 881,
//           sort_order: 1,
//         },
//         {
//           title: "3D DATA (CONTROLLERS)",
//           price: 1322,
//           sort_order: 2,
//         },
//         {
//           title: "09 MM FOR (12V SINGLE COLOR PIXEL LED)",
//           price: 1586,
//           sort_order: 3,
//         },
//         {
//           title: "12 MM FOR (5V MULTI-COLOR PIXEL LED)",
//           price: 5110,
//           sort_order: 4,
//         },
//         {
//           title: "Power Supply(SMPS)",
//           price: 1586,
//           sort_order: 5,
//         },
//         {
//           title: "Frame Cost",
//           price: 2400,
//           sort_order: 6,
//         },
//       ],
//     },
//     {
//       title: "Additional Service and Prices",
//       price: 0,
//       type: "checkbox",
//       required: 0,
//       sort_order: 5,
//       values: [
//         {
//           title: "Wiring (Pixel LED) and labor costs",
//           price: 1938,
//           sort_order: 1,
//         },
//       ],
//     },
//   ];


const VideoBackground = styled('div')({
  position: 'relative',
  width: '100%',
  paddingTop: '62.25%',
});

const VideoIframe = styled('iframe')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  border: '0',
});


const CustomFormControl = styled(FormControlLabel)`
.css-ahj2mt-MuiTypography-root {
  font-size: 0rem;
}
`;

type OptionValue = {
  title: string;
  price: number;
  sort_order: number;
  is_selected?: number; // Optional, since not all values have this property
};
type Option = {
  title: string;
  price: number;
  type: 'radio' | 'checkbox'; // Limiting to only these two types
  required: number;
  sort_order: number;
  values: OptionValue[];
};
const ProductDetails: React.FC = () => {
    const [isFavorite, setIsFavorite] = useState(false);
    const [showHearts, setShowHearts] = useState(false);


    const [showLoginAlertModal,setShowLoginAlertModal]=useState(false);
    // console.log("productdetails data",data['prodData'])
    // console.log("productdetails id",data['prodData'])
    

    const [prodImages,setProdImages]=useState<ProductImage[]>([]);
    // const [prodDataSingle,setProdData]=useState([]);
    const [prodDataSingle, setProdData] = useState<ProductData | null>(null);
    const [quantitySelected,setQuantitySelected]=useState("1");

    const { urlproductid,productname } = useParams();
    console.log("url productname",urlproductid,productname)
    // const encodedProductName = encodeURIComponent(productname || "");
    const encodedProductName = productname?productname.replace(/\s+/g, '-'):'';
    console.log("encodedProductName",encodedProductName)
    // const [optionsData,setDataOptions]=useState([]); 
    const [optionsData, setDataOptions] = useState<Option[]>([]);

    
    const {data:productData,error:productDataError,isLoading:productDataLoading,fetchData:fetchProductData} = useFetch(BASE_URL_Test+urls('productData')+`${urlproductid}/`+`${encodedProductName}`,{
      method: 'GET',
      // redirect: "follow",
      headers: { 'Content-Type': 'application/json'}
  
  
    })
  
    const handleClick = () => {
      setIsFavorite(true);
      setShowHearts(true);
      addProductTowishlist();
      setTimeout(() => setShowHearts(false), 600); // Hide floating hearts after animation
    };

    const navigate = useNavigate(); 
    const [zoomPosition, setZoomPosition] = useState<{ x: number; y: number } | null>(null);
    const location = useLocation();
    const { productid } = location.state || {}; 
    console.log("productid from state",productid)


    useEffect(()=>{
      fetchProductData()

    },[productname])


    useEffect(()=>{
      if(productDataError)
      {

      }
      else if(productData && productData['status']=="success")
      {
        console.log("productDataproductData",productData)
        if(productData && productData['data'] && productData['data']['options'])
        {
                setProdImages(productData['images'])
                setProdData(productData['data'])
              setDataOptions(JSON.parse(productData['data']['options']))
        }
    
      
      }

    },[productData,productDataError])




    const [showModal,setShowModal]=useState({
        showSnack:false,
        message:'',
        snackColor:"",
        snackType:""
      
      });
 

    const cartStore=useSelector((store:any)=>store.user)
    // const data = location.state;
    const data = cartStore['productDetails'];
    console.log("proddetails",data)
    const isLoggedin=useSelector((store:any)=>store.user.isLoggedin)

    const breadCumCatData = [
      (prodDataSingle?.category1 || ""),
  (prodDataSingle?.category2 || ""),
  (prodDataSingle?.category3 || ""),
  (prodDataSingle?.category4 || ""),
  (prodDataSingle?.category5 || ""),
  (prodDataSingle?.category6 || ""),
      ].filter(category => category !== "" &&  category !== "nan");

      console.log("breadcumda",breadCumCatData)

    // console.log("isLoggedin",cartStore['productDetails']['productid'])
    console.log("isLoggedin", data  )
  //   const { data:prodData,error,isLoading,fetchData}=useFetch(BASE_URL_Test+urls('productimages')+`${cartStore['productDetails']['productid']}`,{
	// 	method: 'GET',
  //   // redirect: "follow",
	// 	headers: { 'Content-Type': 'application/json'}

	// })

    const dispatch = useDispatch();
    const handleQuantitySelected=(value:any)=>{
        setQuantitySelected(value)
    }

    // useEffect(()=>{

    //     // console.log("object useeffec",data['prodData']['productid'])
    //     fetchData()
        

    // },[])

    // useEffect(()=>{
    //     if(error)
    //     {
    
    //     }
    //     else if(prodData)
    //     {
    //     //   console.log("prodDataDetails",prodData['data'][0])
    //     //   console.log("prodDataImages",prodData['images'])
    //       if(Object.keys(prodData).length>0)
    //       {
    //         setProdImages(prodData['images'])
    //         setProdData(prodData['data'])
    //       }
          
    //     }
    
    //   },[prodData,error])

      
     
    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        const { offsetX, offsetY, target } = e.nativeEvent as MouseEvent & {
            target: HTMLDivElement;
        };

        const { width, height } = target.getBoundingClientRect();

        const x = (offsetX / width) * 100;
        const y = (offsetY / height) * 100;

        setZoomPosition({ x, y });
    };

    const handleMouseLeave = () => {
        setZoomPosition(null);
    };

    const [selectedValues, setSelectedValues] = useState<any>({});

    

    const addProductToCart=()=>{
        // if(isLoggedin)
        // {
            console.log("cart clicked",prodDataSingle)
            console.log("cart selectedItems",parseInt(quantitySelected)*(RoundNumber(parseInt(data['price']) )+calculateTotal()))
            const newItem = {
                prodid: prodDataSingle &&prodDataSingle['productid']? prodDataSingle['productid']:'0',
                name: prodDataSingle &&prodDataSingle['productname']? prodDataSingle['productname']:'No product',
                // price: prodData &&prodData['data']? parseInt(quantitySelected)*prodData['data'][0]['price']:'',
                price: selectedItems? parseInt(quantitySelected)*(RoundNumber(parseInt(data['price']) )+calculateTotal()):'',
                quantity: quantitySelected? quantitySelected:'1',
                imageUrl: data && data['images']? data['images']:'',
                description: 'A great product!',
                category: prodDataSingle &&prodDataSingle['category1']?prodDataSingle['category1']:'0',
                discountedPrice: prodDataSingle &&prodDataSingle['cutoffprice']? prodDataSingle['cutoffprice']:'',
                weight:prodDataSingle &&prodDataSingle['weight']? prodDataSingle['weight']:'',
                updatedPrice:(RoundNumber(parseInt(data['price']) )+calculateTotal())
              };
              
              dispatch(addToCart({ item: newItem }));
              dispatch(setModalProperties({showModal:true,modalMessage:'Item added to cart'}))
           
    }
    const addProductTowishlist=()=>{
       
            console.log("clisk wishlist")
            const newItem = {
                prodid: prodDataSingle &&prodDataSingle['productid']? prodDataSingle['productid']:'0',
                name: prodDataSingle &&prodDataSingle['productname']? prodDataSingle['productname']:'No product',
                price: prodDataSingle &&prodDataSingle['price']? prodDataSingle['price']:'',
                quantity: quantitySelected? quantitySelected:'1',
                imageUrl: data && data['images']? data['images']:'',
                description: 'A great product!',
                category: prodDataSingle &&prodDataSingle['category1']? prodDataSingle['category1']:'0',
                discountedPrice: prodDataSingle &&prodDataSingle['cutoffprice']? prodDataSingle['cutoffprice']:'',
                weight:prodDataSingle &&prodDataSingle['weight']? prodDataSingle['weight']:''
              };
              
              dispatch(addToWishList({ item: newItem }));
              
           
    }


    const handleSelection = (type: string, title: string, value: string | string[]) => {
      console.log("object",type,title,value)
        setSelectedValues((prevSelectedValues: any) => {
          console.log("prevSelectedValues",prevSelectedValues)
          if (type === "radio") {
            // console.log("prvval",...prevSelectedValues)
            if(Object.keys(selectedValues).length>0)
            {
              return { ...prevSelectedValues, [title]: value };
            }
            else{
             return {[title]: value}
            }
             // For radio, set the selected value
          }
          if (type === "checkbox") {
            return { ...prevSelectedValues, [title]: value }; // For checkbox, toggle selection
          }
          return prevSelectedValues;
        });
      };
      console.log("selevtedva",selectedValues)
    const calculateTotal = () => {
        let total = 0;
    
        optionsData && optionsData.forEach((option) => {
          option.values.forEach((value:any) => {
            if (option.type === "radio") {
              if (selectedValues[option.title] === value.title) {
                total += value.price;
              }
            } else if (option.type === "checkbox" && selectedValues[option.title]?.includes(value.title)) {
              total += value.price;
            }
          });
        });
    
        return total;
      };

    // {
    //     productid: '',
    //     name: '',
    //     price: "",
    //     quantity: "1",
    //     imageUrl: [],
    //     attributes: {
    //       polysheet: '',
    //       polysheetcolor: '',
    //       polysheetsize: '',
    //       addons: [],

    //     },
        
    //   },
      
    //   // Additional products...
    // ],
    // totalQuantity: "0",
    // totalPrice: '0',
    // discount: '0',
    

    const handleClose=()=>{
        setShowLoginAlertModal(!showLoginAlertModal)
    }

    // const handleLoginProdDetails=()=>{
    //     const newItem = {
    //         prodid: prodDataSingle &&prodDataSingle['prodid'] ? prodDataSingle['prodid']:'0',
    //         name: prodData &&prodData['data']? prodData['data'][0]['productname']:'No product',
    //         price: prodData &&prodData['data']? prodData['data'][0]['price']:'',
    //         quantity: prodData &&prodData['quantity']? prodData['data'][0]['quantity']:'1',
    //         imageUrl: data && data['images']? data['images']:'',
    //         description: 'A great product!',
    //         category: prodData &&prodData['data']? prodData['data'][0]['category1']:'0',
    //         discountedPrice: prodData &&prodData['data']? prodData['data'][0]['cutoffprice']:'',
    //       };
          
    //       dispatch(addToCart({ item: newItem }));
    //         navigate('/login', { state: { from: window.location.pathname } })
    // }

    const [isChecked, setIsChecked] = useState(false);
    const [selectedItems, setSelectedItems] = useState<number[]>([0]);
    const [totalPrice, setTotalPrice] = useState(0);

    const handleCheckboxChange = (event: { target: { checked: any; }; }, price: any) => {
        const itemChecked = event.target.checked;
        const itemPrice = price;
        
        if (itemChecked) {
            setSelectedItems([...selectedItems, itemPrice]);
        } else {
            setSelectedItems(selectedItems.filter((item) => item !== itemPrice));
        }
    };
    const calculateTotalPrice = () => {
        return selectedItems.reduce((acc, curr) => acc + curr, 0);
    };
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

   
    

    return (
        <Stack sx={{ maxWidth: 1200, mx: 'auto', my: 4, p: 2 }}>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={cartStore.modalProperties.showModal}
        variant={'solid'}
        color={"primary"}
        sx={{color:'#fff'}}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
           console.log("onclide",reason)
          }
          // setOpen(false);
        //   setShowModal({...showModal,showSnack:false,message:'',snackColor:''})
          dispatch(setModalProperties({showModal:false,modalMessage:''}))
        }}
      >
       {cartStore.modalProperties?.modalMessage || 'Item added to cart!'}
      </Snackbar>
            {showLoginAlertModal ? 
          (  <Modal open={showLoginAlertModal} onClose={handleClose} >
            <ModalDialog
              color="success"
              layout="center"
              size="lg"
            >
              <ModalClose />
              <DialogTitle>HeroElectro</DialogTitle>
          <DialogContent>Please Login before adding to cart</DialogContent>
          <Link title='Login' underline='hover' component="button" onClick={()=> {}}>
          Login
          </Link>
            </ModalDialog>
          </Modal>):''}
   <Breadcrumbs aria-label="breadcrumbs" separator=">" sx={{ mb: 2 }}>
  {breadCumCatData.map((item: string, index: number) => (
    <Link key={index} href="#" sx={{ color: 'gray', textDecoration: 'none' }}>
      {item}
    </Link>
  ))}
  <Typography sx={{ color: 'gray' }}>
    {data &&  data['productname'] ? data['productname']:''}
  </Typography>
</Breadcrumbs>



            <CardContent>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                            <Box
                                onMouseMove={handleMouseMove}
                                onMouseLeave={handleMouseLeave}
                                sx={{
                                    width: '120%',
                                    height: '550px',
                                    backgroundImage: `url(${BASE_URL_Test}${prodImages && prodImages.length>0 ? prodImages[0]['url']:''})`,
                                    // backgroundImage: `url(http://127.0.0.1:9000/media/images/PR13_1.jpg)`,
                                    
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    transition: 'transform 0.3s ease',
                                    cursor: 'crosshair',
                                }}
                            />
                        {zoomPosition && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: '102%',
                            width: '780px',
                            height: '700px',
                            border: '1px solid gray',
                            
                            backgroundImage: `url(${BASE_URL_Test}${prodImages[0]?.url || ''})`,

                            backgroundSize: '200%', 
                            backgroundPosition: `${zoomPosition.x}% ${zoomPosition.y}%`,
                            backgroundRepeat: 'no-repeat',
                            borderWidth: 2,
                            boxShadow: '6px 10px 20px rgba(0, 0, 0, 0.25), 6px 6px 6px rgba(0, 0, 0, 0.22)', 
                            zIndex: 10, 
                            backgroundColor: '#fff',
                        }}
                    />
                )}

                        </Box>
                        

                        {prodImages && prodImages.length > 0 ? (
                         
                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
                                {prodImages.map((item:any, index) => (
                                    <img
                                        key={index} // Always add a unique key when rendering lists
                                        src={`${BASE_URL_Test}${item.url}`}
                                        
                                        alt={`Thumbnail ${index + 1}`} // Unique alt text for accessibility
                                        style={{ width: 100, height: 100 }}
                                    />
                                ))}
                            </Box>
                        ) : null}

                       
                        <Box sx={{ mt: 2, borderRadius: 2, overflow: 'hidden', border: '1px solid #e0e0e0' }}>
                            <Grid container sx={{ backgroundColor: '#f9f9f9', padding: '8px 16px' }}>
                                <Grid xs={6}>
                                    <Typography sx={{ fontWeight: 'bold' ,fontFamily: 'Krub, sans-serif',}}>Weight</Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>{data['weight']}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ padding: '8px 16px' }}>
                                <Grid xs={6}>
                                    <Typography sx={{ fontWeight: 'bold' ,fontFamily: 'Krub, sans-serif',}}>Holes Count</Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>{prodDataSingle && prodDataSingle['holescount'] ? prodDataSingle['holescount']:'0'}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ backgroundColor: '#f9f9f9', padding: '8px 16px' }}>
                                <Grid xs={6}>
                                    <Typography sx={{ fontWeight: 'bold' ,fontFamily: 'Krub, sans-serif',}}>Measurement</Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>{prodDataSingle && prodDataSingle['width'] ? prodDataSingle['width']:'0'} X {prodDataSingle && prodDataSingle['height'] ? prodDataSingle['height']:'0'} FEET</Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ padding: '8px 16px' }}>
                                <Grid xs={6}>
                                    <Typography sx={{ fontWeight: 'bold' }}>Required Pixel LED (Holes/50)</Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>22 SETS</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ mt: 4, textAlign: 'center' }}>
                        {/* <iframe
                            width="590" 
                            height="315"
                            src="https://www.youtube.com/watch?v=dz1ltnMfzzs"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe> */}

                        {data && data['yt_link']?
                                 <VideoWrapper>
                                 <VideoBackground>
                                   <VideoIframe
                                     allowFullScreen
                                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                     title="Hero Electro"
                                     width="100%"
                                     height="100%"
                                     loading="eager"
                                     src={data['yt_link']}
                                     data-gtm-yt-inspected-7="true"
                                     style={{ border: 'none', pointerEvents: 'none' }}
                                   />
                                 </VideoBackground>
                               </VideoWrapper>:''
                        }
             
                        </Box>

                    </Grid>

                    <Grid xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',ml:8 }}>
                            <Typography sx={{ fontSize: '0.75rem', color: '#777', mr: 1 }}>
                                Did you like this product? Add to favorites now and follow the product.
                            </Typography>
                            <Box sx={{ position: "relative", display: "inline-flex", ml: 10 }}>
      <IconButton
        aria-label="add to favorites"
        onClick={handleClick}
        sx={{
          color: isFavorite ? "red" : "inherit",
        }}
      >
        {isFavorite ? <Favorite /> : <FavoriteBorder />}
      </IconButton>

      
      {showHearts && (
        <Box
          sx={{
            position: "absolute",
            top: -20,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          {[...Array(3)].map((_, index) => (
            <Favorite
              key={index}
              sx={{
                color: "rgba(255, 0, 0, 0.7)",
                fontSize: 24,
                animation: `${flyUp} 0.6s ease`,
                animationDelay: `${index * 0.1}s`,
                position: "relative",
              }}
            />
          ))}
        </Box>
      )}
    </Box>
                        </Box>
                        <Typography
                        sx={{
                            fontSize: '40px',
                            fontFamily: 'Krub, sans-serif',
                            color: '#212529',
                            margin: '0px 0px 20px',
                            fontWeight:'bold',
                            ml:8 
                        }}
                        >
                        {prodDataSingle && prodDataSingle['productname']}
                        </Typography>

                        <Typography sx={{ fontSize: 20, mb: 2, color: 'gray', ml:8 }}>
                {/* ₹ <s style={{ fontSize: 25, color: 'gray', fontWeight: 'bold' }}>{RoundNumber(parseInt(data['price']))}</s> ₹  */}
                <Typography sx={{ color:'#e55241', fontSize:40 }}>
                ₹  {RoundNumber(parseInt(prodDataSingle  && prodDataSingle['price']?prodDataSingle['price']:'0') + calculateTotal())}
                </Typography>
            </Typography>

                        <Typography sx={{ mb: 2, color: 'gray' ,ml:8 }}>
                        {prodDataSingle && prodDataSingle['description']}
                        </Typography>

                        <Box sx={{ mt: 2, textAlign: 'start' ,ml:8 }}>
                            <Typography sx={{ fontWeight: 'bold',fontSize: '16px',fontFamily: 'Krub, sans-serif',}}>Share with Your Loved Ones</Typography>

                            <Box>
                            <a href="https://wa.me/919042165139" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <img src={Whatsapp} alt="WhatsApp" style={{ width: 30, height: 30, margin: 5 }} />
                                </a>
                                <a href="https://www.instagram.com/hero_electro_offical/profilecard/?igsh=MXRmMmE4aDU2Y2xnNw==" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <img src={InstagramIcon} alt="Instagram" style={{ width: 30, height: 30, margin: 5 }} />
                                </a>
                                <a href="https://www.facebook.com/profile.php?id=100092542658502&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <img src={FacebookIcon} alt="Facebook" style={{ width: 30, height: 30, margin: 5 }} />
                                </a> <a href="https://t.me/+HaZ21EsHRJUxZGNl" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <img src={telegramIcon} alt="Telegram" style={{ width: 30, height: 30, margin: 5 }} />
                                </a>
                                <a href="https://youtube.com/@HeroElectro2020" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <img src={Twitter} alt="Twitter" style={{ width: 30, height: 30, margin: 5 }} />
                                </a>
                            </Box>
                        </Box>

                        <Divider sx={{marginLeft:8}}/>

                        <Box sx={{ marginLeft:8}}>
      <Grid container spacing={2}>
        {optionsData && optionsData.length>0 ? 
        optionsData.map((option:any, idx:number) => (
          // <Grid  xs={12} key={idx}>
           
             
          //       <Typography sx={{ fontWeight: 'bold',fontSize: '13px',fontFamily: 'Krub, sans-serif'}}>{option.title}</Typography>

          //       {option.type === "radio" ? (
          //        <FormControl sx={{ display: 'block' }}>
          //        <RadioGroup
          //          value={selectedValues[option.title] || ''}
          //          onChange={(e) => handleSelection("radio", option.title, e.target.value)}
          //          sx={{ gap: '2px', }} 
          //        >
          //          {option.values.map((value:any) => (
          //            <FormControlLabel
          //              key={value.sort_order}
          //              value={value.title}
          //              control={
          //                <Radio
          //                  sx={{
          //                    p: '0px', 
          //                    transform: 'scale(0.8)',
          //                  }}
          //                />
          //              }
          //              label={`${value.title} (+₹${value.price})`}
          //              sx={{
          //                '& .MuiFormControlLabel-label': { fontSize: '0.8rem' }, 
          //                margin: 0, 
          //              }}
          //            />
          //          ))}
          //        </RadioGroup>
          //      </FormControl>
               
                
          //       ) : (
          //         <Stack spacing={1}>
          //           {option.values.map((value:any) => (
          //             <FormControlLabel
          //               key={value.sort_order}
          //               control={
          //                 <Checkbox
          //                 checked={selectedValues[option.title]?.includes(value.title)}
          //                 onChange={(e) => {
          //                   const updatedSelection = e.target.checked
          //                     ? [...(selectedValues[option.title] || []), value.title]
          //                     : selectedValues[option.title].filter((val: string) => val !== value.title);
          //                   handleSelection("checkbox", option.title, updatedSelection);
          //                 }}
          //                 sx={{
          //                   transform: 'scale(0.8)', 
          //                   marginRight: 1,
          //                   p: '0px', 
          //                   color: 'black', 
          //                   '&.Mui-checked': {
          //                     color: 'black', 
          //                   },
          //                 }}
          //               />
                        
          //               }
          //               label={`${value.title} (+₹${value.price})`}
          //               sx={{
          //                 '& .MuiFormControlLabel-label': { fontSize: '0.8rem' }, 
          //                 margin: 0, 
          //               }}
                        
          //             />
          //           ))}
          //         </Stack>
          //       )}
             
           
          // </Grid>

          <Box key={idx} sx={{ mb: 2 }}>
          <Typography sx={{ fontWeight: 'bold' }}>{option.title}</Typography>
          {option.type === "radio" ? (
           
            <FormControl>
              <RadioGroup
                name={option.title}
                value={'Required'}
                
                onChange={(e) => {
                  console.log('Radio button clicked:', e.target.value); // Debugging statement
                  // handleSelection("radio", option.title, e.target.value); // Handle radio selection
                }}
              >
                
                {option.values.map((value:any) => (
                  // <FormControlLabel
                  //   key={value.sort_order}
                  //   value={value.title}
                  //   control={<Radio />}
                  //   onChange={(e) => {
                  //     console.log('Radio button clicked:', e.target); // Debugging statement
                  //     // handleSelection("radio", option.title, e.target.); // Handle radio selection
                  //   }}
                  //   label={`${value.title} ${value.price > 0 ? `+₹${value.price}` : ''}`}
                  // />
                                      
                      
                  <FormControl>
                  
              
                  <RadioGroup>
                  <Radio value={selectedValues[value.title] || ''} variant="soft" 
                  //  onChange={(e) => handleSelection("radio", value.title,value.price)}
                    label={`${value.title} ${value.price > 0 || value.price < 0? `+₹${value.price}` : ''}`}  />
                  </RadioGroup>
                </FormControl>
                          
                      
                      
                ))}
              </RadioGroup>
            </FormControl>
          ): (
              <Stack spacing={1}>
                  {option.values.map((value:any) => (
                      <FormControlLabel
                          key={value.sort_order}
                          control={
                              <Checkbox
                                  checked={(selectedValues[option.title] as string[] || []).includes(value.title)}
                                  onChange={(e) => {
                                      handleSelection("checkbox", option.title, value.title);
                                  }}
                              />
                          }
                          label={`${value.title} (+₹${value.price})`}
                      />
                  ))}
              </Stack>
          )}
           </Box>
        )):''}
      </Grid>

      {/* <Typography  sx={{ marginTop: 3 }}>
        Total Price: ${calculateTotal()}
      </Typography> */}

<Box
            sx={{
                mt: 4,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                flexDirection: isSmallScreen ? 'column' : 'row', 
                ml: 0,
            }}
        >
            <QuantitySelector quantitySelected={handleQuantitySelected} />

            <Button
                variant="solid"
                color="primary"
                size="lg"
                startDecorator={<ShoppingCart />}
                onClick={() => addProductToCart()}
                sx={{
                    backgroundColor: '#007bff',
                    color: '#fff',
                    padding: '10px 20px',
                    borderRadius: '12px',
                    fontFamily: 'Krub, sans-serif',
                    width: isSmallScreen ? '60%' : 'auto', 
                }}
            >
                Add to cart
            </Button>

            <Button
                variant="outlined"
                color="neutral"
                size="lg"
                sx={{
                    borderColor: '#e0e0e0',
                    color: '#007bff',
                    padding: '10px 20px',
                    borderRadius: '12px',
                    fontFamily: 'Krub, sans-serif',
                    width: isSmallScreen ? '60%' : 'auto', 
                }}
            >
                Buy Now
            </Button>
        </Box>
    </Box>
                        
                    </Grid>
                </Grid>

                <Box sx={{ display: 'none', flexDirection: 'row' }}>
                   
                    <Box sx={{ textAlign: 'start', mt: 4, ml: 4 }}>
                        <Typography sx={{ mb: 2, fontStyle: 'italic' ,fontFamily: 'Krub, sans-serif',}}>
                            <span style={{ color: 'red' }}>*</span> NO CASH ON DELIVERY
                        </Typography>
                        <Typography sx={{ fontStyle: 'italic', mb: 2 ,fontFamily: 'Krub, sans-serif',}}>
                            <span style={{ color: 'red' }}>*</span> Expected delivery via courier service within 3 to 5 business days
                        </Typography>
                        <Typography sx={{ fontStyle: 'italic', mb: 2 ,fontFamily: 'Krub, sans-serif',}}>
                            <span style={{ color: 'red' }}>*</span> Please contact us via WhatsApp (+91 90421 65139) with the product name or product ID if you are from outside of India!
                        </Typography>
                    </Box>
                </Box>
                <Divider sx={{ my: 4 }} />
                {/* <Typography sx={{ mb: 2, mt: 2, fontStyle: 'italic' }}>
                    HOLES SIZE:  {data['prodData']['imagedescription']}
                </Typography> */}
            </CardContent>
        </Stack>
    );
};

export default ProductDetails;
