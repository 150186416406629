import React from 'react';
import { Card, Typography, IconButton, Box, Stack } from '@mui/joy';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';

import { useTheme, useMediaQuery } from '@mui/material';

import { BASE_URL_Test } from '../../utils/baseUrl';


const Wishlist: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const userCartDetails = useSelector((store: any) => store.user);
  const wishlistItems = userCartDetails?.wishlistDetails?.items || [];

  return (
    <Box padding={4} marginX={isSmallScreen ? 2 : 10}>
      <Typography level="h4" gutterBottom>
        My Wishlist ({wishlistItems.length})
      </Typography>
      {wishlistItems.map((item: any, index: number) => (
        <Card 
          key={index} 
          variant="outlined" 
          sx={{ 
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'space-between', 
            alignItems: isSmallScreen ? 'flex-start' : 'center', 
            marginBottom: 2, 
            position: 'relative', 
            width: isSmallScreen ? '80%' : 800, 
            padding: 3
          }}
        >

<Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2} alignItems="center" sx={{ width: '100%' }}>
            <Box
              component="img"
              src={`${BASE_URL_Test}media/`+   item.imageUrl}
              alt="Product"
              sx={{ 
                width: isSmallScreen ? '100%' : 120, 
                height: 'auto', 
                objectFit: 'cover', 
                mb: isSmallScreen ? 1 : 0 
              }}
            />

          <Stack direction="row" spacing={2}>
        

            <Box>
              <Typography fontWeight="bold">{item.name}</Typography>
              <Stack direction="row" alignItems="baseline" spacing={1} marginTop={1}>
                <Typography fontSize={18} fontWeight="bold">
                  ₹{parseFloat(item.price).toFixed(2)}
                </Typography>
              </Stack>
              <Typography fontSize="sm" color="neutral" marginTop={1}>
                {item.description}
              </Typography>
            </Box>
          </Stack>
          <IconButton
            size="sm"
            variant="plain"
            sx={{ 
              position: isSmallScreen ? 'static' : 'absolute', 
              top: isSmallScreen ? 'unset' : 8, 
              right: isSmallScreen ? 'unset' : 8, 
              color: 'grey', 
              marginTop: isSmallScreen ? 2 : 0 
            }}
          >
            <DeleteIcon />
          </IconButton>
          </Stack>
        </Card>
      ))}
    </Box>
  );
};

export default Wishlist;
