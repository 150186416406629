import React, { Component,useState,useEffect,useCallback } from 'react'


export const useFetch=(url,options)=>{
    const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	console.log("baseurl",url)
	const fetchData= useCallback(async(requestBody)=>{
		setIsLoading(true)
		try {
			console.log("url",url,options.method,requestBody)
			// const response=await fetch(url,{
			// 	...options,
			// 	...(options.method && options.method.toUpperCase() !== 'GET' && { body: JSON.stringify(requestBody) })
			// })
			const response = await fetch(url, {
                ...options,
                method: options.method || 'GET',
                body: options.method && options.method.toUpperCase() !== 'GET' ? JSON.stringify(requestBody) : undefined,
                headers: {
                    'Content-Type': 'application/json',
                    ...(options.headers || {}),
                },
            });
			console.log('use fetch response', response);
			const result = await response.json();
			console.log('use fetch result', result);
			if (!response.ok) {
				throw new Error(result.message || 'Something went wrong');
			}
			setData(result);
			setError(null);
		} catch (error) {
			setError(error.message || 'Something went wrong');
		} finally {
			setIsLoading(false);
		
		}},[url,options])

	


		return { data, error, isLoading, fetchData };
   


}

export const useFetchFileUplaod=(url,options)=>{
    const [filedata, setFileData] = useState(null);
	const [fileerror, setfileError] = useState(null);
	const [isFileLoading, setIsFileLoading] = useState(false);
	console.log("baseurl",url)
	const fetchData= useCallback(async(requestBody)=>{
		setIsFileLoading(true)
		try {
			console.log("url",url,options.method,requestBody)
			// const response=await fetch(url,{
			// 	...options,
			// 	...(options.method && options.method.toUpperCase() !== 'GET' && { body: JSON.stringify(requestBody) })
			// })
			const response = await fetch(url, {
                ...options,
                method: options.method || 'GET',
                body: options.method && options.method.toUpperCase() !== 'GET' ? JSON.stringify(requestBody) : undefined,
                headers: {
                    'Content-Type': 'application/json',
                    ...(options.headers || {}),
                },
            });
			console.log('use fetch response file', response);
			const result = await response.json();
			console.log('use fetch result fileupload', result);
			if (!response.ok) {
				throw new Error(result.message || 'Something went wrong');
			}
			setFileData(result);
			setFileData(null);
		} catch (error) {
			setfileError(error.message || 'Something went wrong');
		} finally {
			setIsFileLoading(false);
		
		}},[url,options])

	


		return { filedata, fileerror, isFileLoading, fetchData };
   


}