import React, { useState } from 'react';
import {
  Box,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  IconButton,
  Collapse,
  Chip,
  Button,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
  Drawer
} from '@mui/material';

import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useSelector, useDispatch } from 'react-redux';
import { RoundNumber } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { addProduct } from '../../store/reducers/userReducers';
import { BASE_URL_Test } from '../../utils/baseUrl';

// Product data structure for example
let productsData: any[] = [];

const getCategoryData = (): Category[] => {
  const categories: { [key: string]: { count: number, subcategories: Set<string> } } = {};

  productsData = useSelector((state: any) => state.user.allPoductsData);
  console.log("productsData", productsData);

  if (productsData && Array.isArray(productsData)) {
    productsData.forEach((product: any) => {
      const { category1, category2, category3 } = product;

      if (!categories[category1]) {
        categories[category1] = { count: 0, subcategories: new Set() };
      }
      categories[category1].count += 1;
      if (category2) categories[category1].subcategories.add(category2);
      if (category3) categories[category1].subcategories.add(category3);
    });
  } else {
    console.warn("Warning: productsData is undefined or not an array.");
  }

  return Object.keys(categories).map((key) => ({
    label: key,
    count: categories[key].count,
    subcategories: Array.from(categories[key].subcategories).map((sub) => ({ label: sub })),
  }));
};

interface Subcategory {
  label: string;
}

interface Category {
  label: string;
  count: number;
  subcategories: Subcategory[];
}

// Sidebar component
const Sidebar: React.FC<{ onSelect: (selected: string[]) => void }> = ({ onSelect }) => {
  const [openCategories, setOpenCategories] = useState<{ [key: string]: boolean }>({});
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const categoriesData: Category[] = getCategoryData();

  // Toggle visibility of a category
  const handleToggle = (category: string) => {
    setOpenCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  // Handle selection change
  const handleSelectionChange = (item: string) => {
    setSelectedItems((prevState) => {
      const isSelected = prevState.includes(item);
      const updatedItems = isSelected ? prevState.filter((i) => i !== item) : [...prevState, item];
      onSelect(updatedItems);
      return updatedItems;
    });
  };

  // Recursive function to render categories and subcategories
  // const renderCategories = (categories: Category[], level: number = 0) => {
  //   return categories.map((category) => (
  //     <Box key={category.label} sx={{ marginBottom: 1, paddingLeft: level * 2 }}>
  //       <Box display="flex" justifyContent="space-between" alignItems="center">
  //         <Typography>
  //           {category.label} ({category.count})
  //         </Typography>
  //         {category.subcategories.length > 0 && (
  //           <IconButton size="small" onClick={() => handleToggle(category.label)}>
  //             {openCategories[category.label] ? '-' : '+'}
  //           </IconButton>
  //         )}
  //       </Box>
  //       {category.subcategories.length > 0 && (
  //         <Collapse in={openCategories[category.label]}>
  //           <FormGroup sx={{ pl: 2 }}>
  //             {category.subcategories.map((sub) => (
  //               <FormControlLabel
  //                 control={
  //                   <Checkbox
  //                     checked={selectedItems.includes(sub.label)}
  //                     onChange={() => handleSelectionChange(sub.label)}
  //                   />
  //                 }
  //                 label={sub.label}
  //                 key={sub.label}
  //               />
  //             ))}
  //           </FormGroup>
  //         </Collapse>
  //       )}
  //     </Box>
  //   ));
  // };

  const renderCategories = (categories: Category[], level: number = 0) => {
    return categories.map((category) => (
      <Box key={category.label} sx={{ marginBottom: 1, paddingLeft: level * 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>
            {category.label} ({category.count})
          </Typography>
          {category.subcategories.length > 0 && (
            <IconButton size="small" onClick={() => handleToggle(category.label)}>
              {openCategories[category.label] ? '-' : '+'}
            </IconButton>
          )}
        </Box>
        {category.subcategories.length > 0 && (
          <Collapse in={openCategories[category.label]}>
            <FormGroup sx={{ pl: 2 }}>
              {category.subcategories.map((sub) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedItems.includes(sub.label)}
                      onChange={() => handleSelectionChange(sub.label)}
                    />
                  }
                  label={sub.label}
                  key={sub.label}
                />
              ))}
              {/* Render nested subcategories */}
              {/* {sub.subcategories && renderCategories(sub.subcategories, level + 1)} */}
            </FormGroup>
          </Collapse>
        )}
      </Box>
    ));
  };

  return (
    <Box sx={{ width: 250, padding: 2 }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Categories
      </Typography>
      {renderCategories(categoriesData)}
    </Box>
  );
};

// Selected Filters component
const SelectedFilters: React.FC<{ selectedCategories: string[], onClear: () => void, onRemove: (category: string) => void }> = ({ selectedCategories, onClear, onRemove }) => {
  return (
    <Box sx={{ marginBottom: 2 }}>
      <Button startIcon={<ClearIcon />} onClick={onClear} sx={{ marginRight: 2 }}>
        Clear Filters
      </Button>
      {selectedCategories.map((category) => (
        <Chip
          key={category}
          label={category}
          onDelete={() => onRemove(category)}
          sx={{ marginRight: 1 }}
        />
      ))}
    </Box>
  );
};



const ProductGrid: React.FC<{ selectedCategories: string[], layout: 'grid' | 'list', itemsToShow: number }> = ({ selectedCategories, layout, itemsToShow }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const filteredProducts = selectedCategories.length > 0
  ? productsData.filter((product: any) => 
      [product.category1, product.category2, product.category3, product.category4, product.category5, product.category6]
        .some((category) => selectedCategories.includes(category.trim()))
    )
  : productsData;

    console.log("filteredProducts",filteredProducts)

  const limitedProducts = filteredProducts.slice(0, itemsToShow);



const dispatch =useDispatch()
console.log("selectedCategories",selectedCategories)
  // const filteredProducts = selectedCategories.length > 0 
  //   ? productsData.filter((product: any) => selectedCategories.includes(product.category2))
  //   : productsData;

  // const filteredProducts = selectedCategories.length > 0 
  // ? productsData.filter((product: any) => 
  //     selectedCategories.includes(product.category3.trim())
  //   )
  // : productsData;
  
    const handleProductClick = (item: {}, index: number) => {
      dispatch(addProduct({proddetails:item})) //  dispatch(addProduct({proddetails:{"productname":"Test","image":"imageurl","originalPrice":"100","discountedPrice":"30","discount":"3" }}))
    navigate(`/product`); 
    console.log("item clicked",item)
    };


  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexWrap: 'wrap', 
        justifyContent: isMobile ? 'center' : 'space-between',
        alignItems: 'center',
        padding: '10px', 
      }}
    > 
      {limitedProducts && limitedProducts.length > 0 ? 
        limitedProducts.map((product, index) => (
          <Box 
            key={product.id} 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'space-between', 
              width: '200px', 
              height: '300px', 
              marginBottom: '20px', 
              padding: '10px', 
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)', 
              borderRadius: '8px', 
              backgroundColor: '#fff',
              cursor: 'pointer', 
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.05)',
              }
            }}
            onClick={() => handleProductClick(product, index)} 
          > 
            <img
              src={`${BASE_URL_Test}api/v1/auth/media/` + product.images}
              alt={product.productname}
              style={{
                width: '100%', 
                height: '190px', 
                objectFit: 'cover', 
                marginBottom: '8px'
              }}
            />
            <Typography component="div" sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: 13 }}>
              {product.productname}
            </Typography>
            <Typography sx={{ color: 'red', fontSize: 22, fontWeight: 700 }}>
              ₹ {RoundNumber(parseInt(product.price))}
            </Typography>
          </Box>
        ))
        : <Typography>No Products Available</Typography>
      }
    </Box>
  );
};




const App: React.FC = () => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [itemsToShow, setItemsToShow] = useState<number>(16);
  const [layout, setLayout] = useState<'grid' | 'list'>('grid');
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const handleClearFilters = () => {
    setSelectedCategories([]);
  };

  const handleRemoveCategory = (category: string) => {
    setSelectedCategories((prevState) => prevState.filter((item) => item !== category));
  };

  const handleLayoutChange = (event: React.MouseEvent<HTMLElement>, newLayout: 'grid' | 'list') => {
    setLayout(newLayout);
  };

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: 4 }}>
      <Grid container spacing={4}>
        {(isSmallScreen || isMediumScreen) ? (
          <>
            <IconButton onClick={toggleDrawer} sx={{ marginBottom: 2 }}>
              <FilterListIcon /> <Typography>Filters</Typography>
            </IconButton>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
              <Sidebar onSelect={(selected) => setSelectedCategories(selected)} />
            </Drawer>
          </>
        ) : (
          <Grid item xs={12} md={3}>
            <Sidebar onSelect={(selected) => setSelectedCategories(selected)} />
          </Grid>
        )}

        <Grid item xs={12} md={9}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#f5f5f5',
              padding: 2,
              borderRadius: 1,
              gap: 2,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ marginRight: 1 }}>Show:</Typography>
              <Select
              value={itemsToShow}
              onChange={(e) => setItemsToShow(Number(e.target.value))}
              sx={{ width: '100px' }}
            >
              <MenuItem value={4}>4 Items</MenuItem>   
              <MenuItem value={12}>12 Items</MenuItem> 
              <MenuItem value={16}>16 Items</MenuItem>
              <MenuItem value={32}>32 Items</MenuItem>
              <MenuItem value={48}>48 Items</MenuItem>
            </Select>
            </Box>

            <ToggleButtonGroup
              value={layout}
              exclusive
              onChange={handleLayoutChange}
              aria-label="layout"
            >
              <ToggleButton value="grid" aria-label="grid view">
                <ViewModuleIcon />
              </ToggleButton>
              <ToggleButton value="list" aria-label="list view">
                <ViewListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <SelectedFilters
            selectedCategories={selectedCategories}
            onClear={handleClearFilters}
            onRemove={handleRemoveCategory}
          />

          <ProductGrid
            selectedCategories={selectedCategories}
            layout={layout}
            itemsToShow={itemsToShow} // Pass itemsToShow to ProductGrid
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default App;



