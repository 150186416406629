import React from 'react';
import { Box, Grid, TextField, Button, Typography } from '@mui/material';

const CustomizeForm = () => {
  return (
    <Box sx={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <Typography variant="h4" sx={{ marginBottom: '20px', textAlign: 'center' }}>
        Customize
      </Typography>
      <Grid container spacing={4}>
        {/* Left Side - Contact Information */}
        <Grid item xs={12} md={5} sx={{ paddingRight: '20px' }}>
          <Box>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
              How Can We Help You?
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px' }}>
              If you have any queries or custom Poly Sheets designs kindly submit the details as expected! We will
              reach you ASAP via WhatsApp!! Thanks for Keep Supporting Us!!!
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Address:
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: '10px' }}>
              OM Muruga Electronics Thangavel Nagar Rd, Kambainallur Dharmapuri - 635202
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Contact:
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: '10px' }}>
              +91 90421 65139
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Email:
            </Typography>
            <Typography variant="body2">heroelectro2020@gmail.com</Typography>
          </Box>
        </Grid>

        {/* Right Side - Form */}
        <Grid item xs={12} md={7} sx={{ paddingLeft: '20px' }}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Your name"
                required
                sx={{ backgroundColor: '#f5f5f5' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                required
                sx={{ backgroundColor: '#f5f5f5' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="WhatsApp Number"
                required
                sx={{ backgroundColor: '#f5f5f5' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Product Name / Product Link"
                required
                sx={{ backgroundColor: '#f5f5f5' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Enter Width* (in Feet)"
                required
                sx={{ backgroundColor: '#f5f5f5' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Enter Height* (in Feet)"
                required
                sx={{ backgroundColor: '#f5f5f5' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" component="label">
                Choose File
                <input type="file" hidden />
              </Button>
              <Typography variant="caption" sx={{ display: 'block', marginTop: '5px' }}>
                Upload up to 1 MB file (jpg | png | gif | img | doc | pdf)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Comments"
                multiline
                rows={4}
                sx={{ backgroundColor: '#f5f5f5' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomizeForm;
