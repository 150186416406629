import React from 'react';
import { TextField, InputAdornment } from '@mui/material';

interface CustomTextFieldProps {
  label: string;
  type?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon: React.ReactNode;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({ label, type, value, onChange, icon }) => (
  <TextField
    label={label}
    type={type}
    variant="outlined"
    fullWidth
    margin="normal"
    required
    value={value}
    onChange={onChange}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          {icon}
        </InputAdornment>
      ),
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#bdbdbd',
        },
        '&:hover fieldset': {
          borderColor: '#757575',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#000000',
        },
      },
      '& .MuiInputLabel-root': {
        color: '#757575',
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: '#000000',
      },
      width: "450px"
    }}
  />
);

export default CustomTextField;
