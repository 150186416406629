import React, { useState, useEffect } from 'react';
import { Box, Stack, Typography, Input, Button, Divider, Modal, ModalDialog, ModalClose, DialogTitle, DialogContent, Link, IconButton, Radio, RadioGroup } from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom'; 
import { useSelector,useDispatch } from 'react-redux';
import { ReceiptNumber, RoundNumber } from '../../utils';
import { calculateCost } from '../../utils/roundNumbers';
import { BASE_URL_Test } from '../../utils/baseUrl';
import { useFetch } from '../../hooks/useFetch';
import { urls } from '../../urls/urls';
import generateReceiptNumber from '../../utils/generateReceiptNumber';
import { addUsersOrdersList, addUserOrderId } from '../../store/reducers/userReducers';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddressModal from './mapModal';
import { FormControlLabel } from '@mui/material';
import { TextField } from '@mui/material';
import { addToCart,resetCart,deleteCartItem } from '../../store/reducers/userReducers';
import { Favorite, FavoriteBorder, ShoppingCart } from '@mui/icons-material';
import { keyframes } from "@mui/system";
import { formatISO, fromUnixTime } from 'date-fns';
import CircularProgress from '@mui/joy/CircularProgress';

interface YourOrderProps {
  activeStep: number; 
}
interface Address {
  id: string;
  firstName: string;
  companyName: string;
  streetAddress: string;
  mobileNumber: string; 
  state: string;
  zipCode: string;
}

const flyUp = keyframes`
  0% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(-100px); opacity: 0; }
`;

interface FinalCartValueProps{
  totalPrice: number;
  totalDiscountedPrice: number;
  totalWeight: number;
  totalSubTotal:number;
  totalShippingCharge:number;
}


const YourOrder: React.FC<YourOrderProps> = ({ activeStep }) => {
  const [discountCode, setDiscountCode] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); 
  const [isFavorite, setIsFavorite] = useState(false);
  const [showHearts, setShowHearts] = useState(false);
  const navigate = useNavigate();
  const cartStore = useSelector((state: any) => state.user.cartDetails.items);
  const userDetailsStore = useSelector((state: any) => state.user.userLoginDetails.userDetails);
  console.log("cartStore",userDetailsStore)
  const loginDetails = useSelector((state: any) => state.user.userLoginDetails);
  const isLoggedin = useSelector((store: any) => store.user.isLoggedin);
  const [showLoginAlertModal, setShowLoginAlertModal] = useState(false);
  const [showAfterPaymentModal, setShowAfterPaymentModal] = useState(false);
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const [totalCartValues,setTotalCartValues]=useState<FinalCartValueProps>()
  const [isModalmapOpen, setModalOpen] = useState(false);
  const [orderCreatedDbData,setOrderCreatedData]=useState();


  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const [openInstructionsModal, setOpenInstructionsModal] = useState(false);
  const [instructions, setInstructions] = useState('');

  const dispatch=useDispatch();

  console.log("isLoggedin",isLoggedin)

  // Creating Order ID
  const { data: OrderIdData, error, fetchData } = useFetch(BASE_URL_Test + urls('createorderid'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  });

  // Saving data of ORderID
  const { data: OrderIdDbData, error: OrderIdDbError, fetchData: OrderIdDbFetchData } = useFetch(BASE_URL_Test + urls('allorders'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  });

  // Savoin payment success in DB

  const { data: PaymentSuccessData, error: PaymentSuccessError, fetchData:PaymentSuccessFetchData } = useFetch(BASE_URL_Test + urls('payementsuccess'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  });

  const { data: addressSavedData, error: addressSavedDataError, fetchData: addressSavedFetchData } = useFetch(BASE_URL_Test + urls('addAddress'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  });

  useEffect(()=>{
    const finalcartValeus=getCartValues()
    setTotalCartValues(finalcartValeus)

  },[cartStore])



  const getCartValues=()=>{

 
      const summary = {
          totalPrice: 0,
          totalDiscountedPrice: 0,
          totalWeight: 0,
          totalSubTotal:0,
          totalShippingCharge:0
      };
  
      cartStore.forEach((product:any) => {
          summary.totalPrice += parseFloat(product.price);
          // summary.totalDiscountedPrice += parseFloat(product.price)-parseFloat(product.discountedPrice);
          summary.totalWeight += parseFloat(product.weight);
          summary.totalSubTotal += parseFloat(product.price);
          summary.totalShippingCharge+=calculateCost(parseFloat(product.weight));
      });
      console.log("summary",summary)
  
      return summary;
  
        
  }

  useEffect(() => {
    if (error) {
      
    } else if (OrderIdData) {
      console.log("objectOrderIdData",OrderIdData['id'])
      setOrderCreatedData(OrderIdData)
      //  Recived Order ID now pass it to raor pay for payment
      //  intiate payment and in payment success add orderIddata and paymentid into DB

      inititiatePayment(OrderIdData)

      // OrderIdDbFetchData({
      //   orderid: OrderIdData['id'],
      //   email: loginDetails?.userDetails?.email || '',
      //   name: loginDetails?.userDetails?.firstname || '',
      //   trackingno: "00000",
      //   created: OrderIdData['created_at'],
      //   total: OrderIdData['amount'],
      //   profit: "0",
      //   status: OrderIdData['status'],
      //   orderstatus: "pending",
      //   receiptno: OrderIdData['receipt']
      // });
    }
  }, [OrderIdData, error]);


  useEffect(()=>{
    if(addressSavedDataError)
    {

    }
    else if(addressSavedData)
    {
      console.log("addressSavedData",addressSavedData)
    }

  },[addressSavedData,addressSavedDataError])

  useEffect(() => {
    if (PaymentSuccessError) {
      
    } else if (PaymentSuccessData) {
      console.log("PaymentSuccessData",PaymentSuccessData)
      if (PaymentSuccessData['status'] === 'success') {
        setShowLoaderModal(!showLoaderModal)
        setShowAfterPaymentModal(true)
        addUserOrderId({ orderId: PaymentSuccessData['data'] });
        // inititiatePayment(OrderIdDbData['data']);
      }
    }
  }, [PaymentSuccessData, PaymentSuccessError]);

  const inititiatePayment = (orderCreatedData: any) => {
    console.log("objectOrderIdData CreatedData", orderCreatedDbData&&orderCreatedDbData['id'])
    const { id } = orderCreatedData && orderCreatedData['id']?orderCreatedData['id']:''; 
    console.log("total",orderCreatedData['amount'])
    console.log("objectOrderIdData id",id)
    const amountTotal=orderCreatedData['amount'] * 100;
    

    var options = {
      key: 'rzp_test_06e4gb0CMSOOPG', // Enter the Key ID generated from the Dashboard
      amount:amountTotal,// Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: 'INR',
      name: 'HeroElectro',
      "description": "Test Transaction",
      // "image": "https://example.com/your_logo",
      order_id: id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response:any){
              console.log("OrderIdData handlefun", response);
        if(response['razorpay_payment_id'])
        {
       
          //  setOpen(true);
          const updatedOrderData = {
            ...orderCreatedData,  // Spread existing properties
            razorpay_payment_id: response['razorpay_payment_id']  // Add new key and value
          };
          const date = fromUnixTime(orderCreatedData['created_at']);  // Convert to Date object
        const isoDateString = formatISO(date); 
        setShowLoaderModal(!showLoaderModal)

          PaymentSuccessFetchData({
            razorpay_order_id :id,
            razorpay_payment_id :response['razorpay_payment_id'] ,
            razorpay_signature : '',
            txn_id : '',
            payment_type : '',
            name :userDetailsStore['firstname'],
            email : userDetailsStore['email'],
            address :'',
            amount : orderCreatedData['amount'],
            paid_amount : orderCreatedData['amount_due'],
            paid_date :isoDateString,
            phone : userDetailsStore['phone'],
            paid : true,
            created_at : isoDateString,
            status : orderCreatedData['status']
          })
          

        }
        else{
          console.log("payment failed")
        }
      },
      prefill: {
        name: '',
        email: orderCreatedData['email'],
        contact: loginDetails['userDetails']['phone'],
      },
      "notes": {
          "address": "Razorpay Corporate Office"
      },
      "theme": {
        color: '#F37254',
      },
         modal: {
        escape: false,
        backdropclose: false,
      },  
       payment_options: {
        card: { // Disable other options if needed
          enabled: true,
        },
        netbanking: { enabled: true },
        upi: { enabled: true },
        wallets: { enabled: false },
        emi: { enabled: false },
        paylater: { enabled: false }
      }
  };
 
 
    const razorpay = new window.Razorpay(options);
    razorpay.open();
    razorpay.on('payment.failed', function (response:any){
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
});

  };

  const handleClose = () => {
    setShowLoginAlertModal(!showLoginAlertModal);
  };

  const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountCode(event.target.value);
  };

  const handleOpenModal = async () => {
    console.log("Modalclicked",RoundNumber((totalCartValues?.totalSubTotal)) + RoundNumber((totalCartValues?.totalShippingCharge)))
    if (isLoggedin) {
      // If user logged then only creaating Order ID
      
      await fetchData({ 
        "amount": cartStore && cartStore[0]['price'] ? (RoundNumber((totalCartValues?.totalSubTotal)) + RoundNumber((totalCartValues?.totalShippingCharge))): 0,
        "currency": "INR",
        "receipt": generateReceiptNumber(),
        "notes": {
          "key1": "value3",
          "key2": "value2"
        }
      });

  
    } else {
      setShowLoginAlertModal(!showLoginAlertModal);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOrderStatusClick = () => {
    setIsModalOpen(false); 
    navigate('/order-tracking'); 
  };

  const handleLoginProdDetails = () => {
    navigate('/login', { state: { from: window.location.pathname } });
    
  };
  const handleDelete = (item: any) => {
   
    // const updatedCartStore = cartStore.filter((item: any) => item.id !== id);
    // cartStore(updatedCartStore);
    dispatch(deleteCartItem({item:item}))
    // console.log("your order delete",item)



  };
  const [openModal, setOpenModal] = useState(false);
  const handleOpenMapModal = () => {
    setOpenModal(true);
  };
  
  const [addresses, setAddresses] = useState([
    {
      id: '',
      firstName: '',
      companyName: '',
      streetAddress: '',
      state: '',
      zipCode: '',
      mobileNumber:''
    },
  
  ]);

  const [newAddress, setNewAddress] = useState({
    firstName: '',
    companyName: '',
    streetAddress: '',
    mobileNumber: '',
    state: '',
    zipCode: '',
  });

 
  const handleNewAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditClick = (addressId: string) => {
    const addressToEdit = addresses.find((address) => address.id === addressId) as Address; 
    if (addressToEdit) {
     
      setNewAddress({
        ...addressToEdit,
        mobileNumber: addressToEdit.mobileNumber || '', 
      });
      setSelectedAddress(addressId); 
    }
  };

  const handleAddInstructionsClick = () => {
    setOpenInstructionsModal(true);
  };

  const handleCancel = () => {
    setNewAddress({
      firstName: '',
      companyName: '',
      streetAddress: '',
      mobileNumber: '',
      state: '',
      zipCode: '',
    });
    setSelectedAddress(null); 
  };

  const handleUseNewAddress = () => {
    if (selectedAddress) {
      
      setAddresses((prev) =>
        prev.map((address) =>
          address.id === selectedAddress ? { ...newAddress, id: selectedAddress } : address
        )
      );
    } else {
     
      setAddresses((prev) => [
        ...prev,
        { ...newAddress, id: `address${prev.length + 1}` }, 
      ]);
    }
    // await saveToDB();
    const formdata = new FormData();
formdata.append("email", "dfd@gmail.com");
formdata.append("firstname", newAddress.firstName);
formdata.append("lastname", newAddress.firstName);
formdata.append("companyname", newAddress.companyName);
formdata.append("street1", newAddress.streetAddress);
formdata.append("street2", newAddress.streetAddress);
formdata.append("phone", newAddress.mobileNumber);
formdata.append("city_town", "");
formdata.append("state",  newAddress.state);
formdata.append("postal_code", newAddress.zipCode);
formdata.append("country", " india");
formdata.append("isShippingAddress", "yes");
    // addressSavedFetchData({
    //   email : "",
    //   firstname : newAddress.firstName,
    //   lastname : newAddress.firstName,
    //   companyname : newAddress.companyName,
    //   street1 : newAddress.streetAddress,
    //   street2 : newAddress.streetAddress,
    //   phone : newAddress.mobileNumber,
    //   city_town : "",
    //   state : newAddress.state,
    //   postal_code : newAddress.zipCode,
    //   country : "",
    //   isShippingAddress : "yes",
    // })
    addressSavedFetchData(formdata)
    handleCancel();
  };

  const handleSaveInstructions = () => {
    
    console.log('Instructions saved:', instructions);
    setOpenInstructionsModal(false);
  };

  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('INR');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [showError, setShowError] = useState('');

  const handlePayment = async () => {
    const numericAmount = Number(amount); 
    if (isNaN(numericAmount) || numericAmount <= 0) {
      alert('Please enter a valid amount.');
      return;
    }
  
    const options = {
      key: 'YOUR_RAZORPAY_KEY_ID', 
      amount: numericAmount * 100,
      currency: currency,
      name: name,
      description: 'Test Transaction',
      image: 'https://example.com/your_logo.jpg', 
      handler: function (response: { razorpay_payment_id: any; }) {
        alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);

      },
      prefill: {
        name: name,
        email: email,
        contact: phone,
      },
      notes: {
        address: 'Customer Address',
      },
      theme: {
        color: '#F37254',
      },
    };
  
    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };

  const handleOpenpaymentModal = () => setOpen(true);

 
  const handleClosePaymentModal = () =>{
   
    
    dispatch(resetCart());
    setShowAfterPaymentModal(false)
    // setOpen(false)
  };

  const handleClick = () => {
    setIsFavorite(true);
    setShowHearts(true);
    addProductTowishlist();
    setTimeout(() => setShowHearts(false), 600); // Hide floating hearts after animation
  };

  const addProductTowishlist=()=>{

  }
  

  return (
    <>
<Box
  sx={{
    p: 3,
    width: '1300px', 
    minWidth: '1000px', 
    // height: '100%',
    maxHeight: '900px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    bgcolor: '#fff',
    ml: 4,
    overflowY:'hidden'
    
  }}
>
{cartStore && cartStore.length>0 ? <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}>

<Box sx={{ width: '60%' }}> 
      {showLoginAlertModal ? 
            (  <Modal open={showLoginAlertModal} onClose={handleClose} >
              <ModalDialog
                color="success"
                layout="center"
                size="lg"
              >
                <ModalClose />
                <DialogTitle>HeroElectro</DialogTitle>
            <DialogContent>Please Login before adding to cart</DialogContent>
            <Link title='Login' underline='hover' component="button" onClick={()=> handleLoginProdDetails()}>
            Login
            </Link>
              </ModalDialog>
            </Modal>):''}
        <Typography sx={{ fontWeight: 'bold', fontSize: '1.25rem', mb: 2 }}>Your Order</Typography>
  
  
  {showAfterPaymentModal ? (
       <Modal
       open={true}
       onClose={()=>handleClosePaymentModal()}
       // aria-labelledby="payment-modal-title"
       // aria-describedby="payment-modal-description"
     >
      
       <Box 
         sx={{
           position: 'absolute', 
           top: '50%', 
           left: '50%', 
           transform: 'translate(-50%, -50%)', 
           width: 600, 
           bgcolor: 'white', 
           boxShadow: 24, 
           p: 4
         }}
       >
          <IconButton 
           aria-label="close" 
          onClick={()=>handleClosePaymentModal()}
           sx={{
             position: 'absolute',
             right: 8,
             top: 8,
             color: 'grey.500',
           }}
         >
           <CloseIcon />
         </IconButton>
         
         
         <Typography id="payment-modal-description" sx={{ mt: 2 ,color:'green',fontSize:20,fontWeight:'bold'}}>
         We appreciate your business Orders... Kindly provide a screenshot of the payment along with the Order ID to +91 90421 65139 on WhatsApp  | வாட்ஸ்அப்பில் +91 90421 65139 என்ற எண்ணுக்கு ஆர்டர் ஐடியுடன் பணம் செலுத்தியதற்கான ஸ்கிரீன் ஷாட்டை வழங்கவும்
         </Typography>
         <a href='/'>
           <Typography>
             Shop More Products
           </Typography>
         </a>
        
       </Box>
  </Modal>
  ):''}


{showLoaderModal ? (
       <Modal
       open={true}
      //  onClose={()=>handleClosePaymentModal()}
       // aria-labelledby="payment-modal-title"
       // aria-describedby="payment-modal-description"
     >
      
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap',justifyContent:'center' }}>
      
      <CircularProgress variant="plain" />
    </Box>
  </Modal>
  ):''}
  
        {activeStep === 0 && ( 
          <>
        {cartStore.map((item: any, index: number) => (
          <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', width: '100%', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '70%' }}>
              <Box component="img" src={`${BASE_URL_Test}media/${item.imageUrl}`} alt="Product" sx={{ width: '70px', height: '70px', mr: 2 }} />
              <Box>
                <Typography>{item.name}</Typography>
                <Typography sx={{ fontSize: '0.875rem', color: '#757575' }}>
                  {item.quantity} X ₹ {RoundNumber(item.updatedPrice)}
                </Typography>
                <Typography sx={{ color: 'gray' }}>Order Total : -     ₹  {RoundNumber(item.price)} </Typography>
                {/* <Typography sx={{ color: 'gray' }}>Product Discount : -     ₹  {parseInt(item.discountedPrice) - parseInt(item.price)} </Typography> */}
                <Typography sx={{ color: 'gray' }}>Order SubTotal : -     ₹  {RoundNumber(item.price)} </Typography>
                <Typography sx={{ color: 'gray' }}>Order Weight : - {RoundNumber(item.weight)} Kg</Typography>

              </Box>
              
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '30%' }}>
            <IconButton
        aria-label="add to favorites"
        onClick={handleClick}
        sx={{
          color: isFavorite ? "red" : "inherit",
          
        }}
      >
        {isFavorite ? <Favorite /> : <FavoriteBorder />}
      </IconButton>
      {showHearts && (
        <Box
          sx={{
            position: "absolute",
            top: -20,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          {[...Array(3)].map((_, index) => (
            <Favorite
              key={index}
              sx={{
                color: "rgba(255, 0, 0, 0.7)",
                fontSize: 24,
                animation: `${flyUp} 0.6s ease`,
                animationDelay: `${index * 0.1}s`,
                position: "relative",
              }}
            />
          ))}
        </Box>
      )}
              <IconButton onClick={() => handleDelete(item)}>
             
                <DeleteIcon sx={{ color: 'grey' }} />
              </IconButton>
            </Box>
              <Divider sx={{mb:1}}/>

              
          </Box>
          
        ))}
      
       
  
      
      
       
        
         </>
      )}
        {activeStep === 1 && (
          <Box sx={{ width: '100%', p: 3 }}>
          <RadioGroup value={selectedAddress} onChange={(e) => setSelectedAddress(e.target.value)}>
            {addresses.map((address) => (
              <Box key={address.id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white', p: 2, mb: 2, borderRadius: '8px', border: '1px solid #E0E0E0' }}>
                <FormControlLabel
                  value={address.id}
                  control={<Radio />}
                  label={
                    <Box>
                      <Typography sx={{ fontWeight: 'bold', ml: 2 }}>{`${address.firstName} ${address.companyName}`}</Typography>
                      <Typography sx={{ ml: 2 }}>{`${address.streetAddress}, ${address.state} ${address.zipCode}`}</Typography>
                    </Box>
                  }
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ mr: 1, cursor: 'pointer', color: 'blue' }} onClick={() => handleEditClick(address.id)}>Edit</Typography>
                  {/* <Typography sx={{ cursor: 'pointer', color: 'blue' }} onClick={handleAddInstructionsClick}>Add Instructions</Typography> */}
                </Box>
              </Box>
            ))}
          </RadioGroup>
  
          {/* Add New Address Form */}
          <Box sx={{ border: '1px solid #4CAF50', borderRadius: '8px', p: 2, mt: 2 }}>
            <Typography sx={{ mb: 2 }}>Add  Address</Typography>
            <Box display="flex" gap={2}>
              <TextField label="First Name" name="firstName" value={newAddress.firstName} onChange={handleNewAddressChange} fullWidth required error={showError ? true : false} />
              <TextField label="Company Name" name="companyName" value={newAddress.companyName} onChange={handleNewAddressChange} fullWidth />
            </Box>
            <TextField label="Street Address" name="streetAddress" value={newAddress.streetAddress} onChange={handleNewAddressChange} fullWidth sx={{ mt: 2 }} required />
            <Box display="flex" gap={2} sx={{ mt: 2 }}>
              <TextField label="Mobile Number" name="mobilenumber" value={newAddress.mobileNumber} onChange={handleNewAddressChange} required />
              <TextField label="State" name="state" value={newAddress.state} onChange={handleNewAddressChange} required />
              <TextField label="Zip Code" name="zipCode" value={newAddress.zipCode} onChange={handleNewAddressChange} required />
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={2} gap={1}>
              <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
              <Button color="success" onClick={handleUseNewAddress}>Use This Address</Button>
            </Box>
  
          </Box>
  
          <Typography sx={{ color: '#0D47A1', cursor: 'pointer', mt: 2 }}>Return to cart</Typography>
  
          
          <Modal open={openInstructionsModal} onClose={() => setOpenInstructionsModal(false)}>
          <Box sx={{ width: 400, p: 4, backgroundColor: 'white', margin: 'auto', borderRadius: 2 }}>
  
  
  
  
       
              <Typography>Add Delivery Instructions</Typography>
              <TextField
                label="Instructions"
                fullWidth
                multiline
                rows={4}
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
                sx={{ mt: 2 }}
              />
              <Box display="flex" justifyContent="flex-end" gap={1} sx={{ mt: 2 }}>
                <Button variant="outlined" onClick={() => setOpenInstructionsModal(false)}>Cancel</Button>
                <Button color="success" onClick={handleSaveInstructions}>Save</Button>
              </Box>
  
            <Divider />
            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', mt: 2 }}>
              <Typography>Grand Total</Typography>
              <Typography>{(RoundNumber(parseInt(cartStore[0].weight)) + 80)}</Typography>
  
            </Box> */}
  
  
  
  
  
  
         </Box>
          </Modal>
  
        
      
        </Box>
                
              )}
              
               {activeStep === 2 && (
  
               <Box sx={{ width: '100%', p: 3 }}>
        <Typography  sx={{ mb: 2 }}>Payment Method</Typography>
        <Box sx={{ border: '1px solid #4CAF50', borderRadius: '8px', p: 2 }}>
          <Typography  sx={{ mb: 2 }}>Razorpay</Typography>
          {/* <TextField
            label="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          /> */}
          {/* <TextField
            label="Currency"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          /> */}
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          {/* <Button  color="success" onClick={handlePayment}>
            Pay Now
          </Button> */}
        </Box>
      </Box>
      )}
    
         </Box>


      <Box sx={{ width: '40%',ml:6}}> 
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <Input placeholder="discount code" value={discountCode} onChange={handleDiscountChange} fullWidth />
          <Button variant="solid" color="primary">Apply</Button>
        </Box>
  
        <Stack spacing={1}>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ color: 'gray' }}>Total</Typography>
            <Typography sx={{ color: 'gray' }}>{RoundNumber(totalCartValues?.totalPrice)}</Typography>
          </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ color: 'gray' }}>Discount</Typography>
            <Typography sx={{ color: 'gray' }}>{RoundNumber(totalCartValues?.totalDiscountedPrice)}</Typography>
          </Box>


          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ color: 'gray' }}>Subtotal</Typography>
            <Typography sx={{ color: 'gray' }}>{RoundNumber(totalCartValues?.totalSubTotal)}</Typography>
          </Box>
         
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ color: 'gray' }}>Shipping Charge</Typography>
            <Typography sx={{ color: 'gray' }}>{RoundNumber(totalCartValues?.totalShippingCharge)}</Typography>
          </Box>
          {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ color: 'gray' }}>Order Weight</Typography>
            <Typography sx={{ color: 'gray' }}>{RoundNumber(totalCartValues?.totalWeight)} Kg</Typography>
          </Box> */}
          {/* <Box sx={{ display: 'flex', justifyContent: 'space-between',fontWeight:'bold'}}>
            <Typography sx={{ color: 'gray' }}>Shipment cost detail</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between',flexDirection:'column' }}>
            <Typography sx={{ color: 'gray' }}>{`weight < 100 gm = 0`}</Typography>
            <Typography sx={{ color: 'gray' }}>{`weight > 100 gm - 500gm = 10`}</Typography>
            <Typography sx={{ color: 'gray' }}>{` weight > 500 gm - 1000gm = 80`}</Typography>
            </Box>
           
          </Box> */}
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', mt: 2 }}>
            <Typography>Grand Total</Typography>
            <Typography>{RoundNumber((totalCartValues?.totalSubTotal)) + RoundNumber((totalCartValues?.totalShippingCharge))}</Typography>
          </Box>
        </Stack>
  
        
  
        <Button variant="solid" color="primary" sx={{ mt: 2, width: '100%', py: 1.5 }} onClick={handleOpenModal}>
          Make Payment
        </Button>
     
      
  
       
      </Box>
  

    
  </Box>: 
  <Typography sx={{fontSize:22,fontWeight:700,height:'100%',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
    No Products in the cart..!!
    </Typography>}
</Box>

      
    </>
  );
}

export default YourOrder;