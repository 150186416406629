import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';

// import configureStore from './store';
import { Provider } from 'react-redux';
import {store} from './store/index'

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// const store=configureStore();
// console.log("goauhtcliuent",process.env.REACT_APP_GOOGLE_OAUTH_KEY)
let persistor=persistStore(store)
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_KEY?process.env.REACT_APP_GOOGLE_OAUTH_KEY:''}>
      <PersistGate persistor={persistor}>
      <App/>
      </PersistGate>
      
      </GoogleOAuthProvider>
    </Provider>
    
   
   
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
