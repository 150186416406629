import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Divider } from '@mui/material';
import { Email as EmailIcon, Lock as LockIcon } from '@mui/icons-material';
import { useNavigate , useLocation } from 'react-router-dom'; 
import CustomButton from '../../../components/Button/customButton';
import CustomTextField from '../../../components/Button/customTextField';
import { useFetch } from '../../../hooks/useFetch';
import { BASE_URL , BASE_URL_Test } from '../../../utils/baseUrl';
import { urls } from '../../../urls/urls';
import { GoogleLogin } from '@react-oauth/google';
import { gapi,loadGapiInsideDOM,loadAuth2 } from 'gapi-script';
import { useGoogleLogin } from '@react-oauth/google';
import { CustomGoogleLogin } from '../../../components';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { emailValidation,passwordValidation } from '../../../utils/textFieldValidations';
import Snackbar from '@mui/joy/Snackbar';
import { login,loggedIn } from '../../../store/reducers/userReducers';
import { setUserLoginDetails } from '../../../store/actions/userActions';
// import { setGoogleAuthDetails } from '../../../store/actions/userActions';

interface LoginProps {
  loginButtonText?: string;
  registerButtonText?: string;
  googleButtonText?: string;
}

const Login: React.FC<LoginProps> = ({
  loginButtonText = "Login",
  registerButtonText = "Register",
  googleButtonText = "Signup with Google",
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); 
  const user=useSelector((store:any)=>store.user)
  console.log("user store",user);
  const dispatch=useDispatch();
  const location = useLocation();
  const [showModal,setShowModal]=useState({
    showSnack:false,
    message:'',
    snackColor:"",
    snackType:""
  
  });
  // const dispatch =AppDispatch()

  const { from } = location.state || { from: { pathname: '/' } };
  const fromPath = typeof from === 'string' ? from : from.pathname;
  console.log("from",from)

  
  


  const { data,error,isLoading,fetchData}=useFetch(BASE_URL_Test+urls('login'),{
		method: 'POST',
    // redirect: "follow",
		headers: { 'Content-Type': 'application/json'}


	})
  const handleRegisterClick = () => {
    navigate('/register'); 
  };

  useEffect(()=>{
    if(error)
    {

    }
    else if(data)
    {
      console.log("data",data)
      dispatch(login({token:data['token'],userDetails:data['data'][0]}))
      dispatch(loggedIn({ loggedin: true }));
      if (fromPath.split('/').length > 0 && fromPath.split('/')[1] === 'checkoutpage') {
        dispatch(loggedIn({ loggedin: true }));
        navigate(fromPath);
    } else {
        navigate('/');
    }
      

      
      
      // navigate(from); // Redirect back to the intended page
      // dispatch(setUserLoginDetails(data))
    }

  },[data,error])

  const handleLogin = async()=>
  {
    
    if(email && emailValidation(email))
      {
        if(password)
          {
            await fetchData({
              "email":email,
              "password":password
            })
           
          }else{
            setShowModal({...showModal,showSnack:true,message:'Please enter proper password',snackColor:'danger',snackType:'failed'})
          } 
      }else{
        setShowModal({...showModal,showSnack:true,message:'Please enter proper email',snackColor:'error',snackType:'failed'})
      }
   

  }



  // const handleGoogleLogin=()=>{
  //     gapi.load('client:auth2',
  //       gapi.client.init({
  //         clientid:'144746575332-f4tpo9l0hr6s8pqqnn3o8no12i08221r.apps.googleusercontent.com'
  //       })
  //     )
  // }

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: codeResponse => console.log("code response",codeResponse),
    flow: 'auth-code',
  });

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          background: 'linear-gradient(135deg, #0C68F4 20%, #073D8E 90%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          padding: '20px',
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
          Login to your account.
        </Typography>
        <img src="/LoginImage2.png" alt="Hero Electro" style={{ width: '190px' }} />
        <Typography component="h2" variant="h6" sx={{ mt: 2 }}>
          HERO ELECTRO
        </Typography>
        <Typography component="p" variant="body1" sx={{ mt: 1 }}>
          "Customize your Poly Sheets and Pixel LED at a great price!"
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} md={6} component={Box} display="flex" alignItems="center" justifyContent="center">
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '400px',
            padding: '20px',
            backgroundColor: '#fff',
          }}
        >

      <Snackbar
        autoHideDuration={3000}
        open={showModal.showSnack}
        variant={'solid'}
        color={showModal.snackType==='success'?"success":"danger"}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
           console.log("onclide",reason)
          }
          // setOpen(false);
          setShowModal({...showModal,showSnack:false,message:'',snackColor:''})
        }}
      >
       {showModal.message}
      </Snackbar>

      
          <Typography variant="h5" component="h2" sx={{ mb: 2, color: '#333', fontSize: 20 }}>
            Please enter your email & password
          </Typography>
          <CustomTextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            icon={<EmailIcon />}
          />
          <CustomTextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            icon={<LockIcon />}
          />
          <CustomButton text={loginButtonText} variant="contained" onClick={()=>handleLogin()} />
          <CustomButton
            text={registerButtonText}
            variant="outlined"
            onClick={handleRegisterClick} 
          />
          <Divider sx={{ width: '110%', mb: 2 }}>
            <Typography variant="body2" color="textSecondary">
              OR
            </Typography>
          </Divider>
          {/* <CustomButton
            text={googleButtonText}
            variant="outlined" onClick={()=>handleGoogleLogin()}
            startIcon={<Box component="img" src='/google.png' alt="Google" sx={{ width: 20, height: 20 }} />}
          /> */}
          {/* <CustomGoogleLogin googleButtonText='Sign in with Google' loginButtonText='' registerButtonText='' /> */}
          <GoogleLogin
  onSuccess={(credentialResponse:any) => {
    console.log(jwtDecode(credentialResponse.credential));
    const data=jwtDecode(credentialResponse.credential)
    // dispatch(setGoogleAuthDetails({googleauthdetails:data}))
    
  }}
  onError={() => {
    console.log('Login Failed');
  }}
/>;
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
