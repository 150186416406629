import React from 'react';
import { Drawer, List, ListItem, Button, IconButton, Typography, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { stat } from 'fs';
import { RoundNumber } from '../../utils';
import { useDispatch } from 'react-redux';
import { deleteCartItem } from '../../store/reducers/userReducers';
import { BASE_URL_Test } from '../../utils/baseUrl';




interface ShoppingCartDrawerProps {
  isOpen: boolean;
  toggleDrawer: (open: boolean) => void;
}



const ShoppingCartDrawer: React.FC<ShoppingCartDrawerProps> = ({ isOpen, toggleDrawer }) => {
  const cartStore=useSelector((state:any)=>state.user.cartDetails.items )
  console.log("cartStore",cartStore)
  const dispatch=useDispatch()


  const handleDeleteCartItem=(data:any)=>{
      console.log("delete cart",data)
      dispatch(deleteCartItem({item:data}))
  }

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => toggleDrawer(false)} 
    >
      <div
        style={{ width: 400, padding: 20 }}
        role="presentation"
        onClick={() => toggleDrawer(false)}  
        onKeyDown={() => toggleDrawer(false)}  
      >
        <IconButton onClick={() => toggleDrawer(false)} style={{ float: 'right' }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" gutterBottom>
          Shopping Cart
        </Typography>
        {/* {cartStore && cartStore.length>0? cartStore.map((item:any)=>{
            // console.log("store loop",item)
            
          return(
            <List>
            <ListItem style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              
              <div>
                <Typography variant="body1">
                  {item.name}
                </Typography>
                <Typography variant="body2">{item.quantity} x ₹{item.discountedPrice}</Typography>
              </div>
              <div style={{ position: 'relative' }}>
                <img
                  src="./proguctimage.png"
                  alt="Product"
                  style={{ width: 90, marginRight: 10 }}
                />
                <IconButton 
                  size="small" 
                  style={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'white' }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </ListItem>
            <Divider sx={{color:'black'}}/>
          
            <Divider/>
          </List>
          
          )

        }): (<Typography>No items</Typography>)} */}
        {cartStore && Array.isArray(cartStore) && cartStore.length > 0 ? (
    cartStore.map((item) => {
        return (
            <List key={item.id}> {/* Assuming each item has a unique id */}
                <ListItem style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <Typography variant="body1">{item.name}</Typography>
                        <Typography variant="body2">{item.quantity} x ₹{RoundNumber(parseInt(item.updatedPrice))}</Typography>
                    </div>
                    <div style={{ position: 'relative' }}>
                        <img
                            src={`${BASE_URL_Test}api/v1/auth/media/` +   item.imageUrl}
                            alt="Product"
                            style={{ width: 90, marginRight: 10 }}
                        />
                        <IconButton 
                            size="small" 
                            style={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'white' }}
                            onClick={()=>handleDeleteCartItem(item)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                </ListItem>
                <Divider sx={{ color: 'black' }} />
                <Typography  style={{ display: 'flex', justifyContent: 'space-between',fontWeight:'bold' }}>
  <span>SUBTOTAL:</span>
  <span>₹ {parseInt(item.price)}</span>
</Typography>

        <Button 
          fullWidth 
          variant="contained" 
          style={{ 
            marginTop: 20, 
            backgroundColor: '#E0E0E0', 
            color: 'black', 
            boxShadow: 'none',
            border: '1px solid #E0E0E0'
          }}
        >
          View Cart
        </Button>
        <Button 
  fullWidth 
  variant="contained" 
  color="primary" 
  style={{ 
    marginTop: 10, 
    backgroundColor: '#007BFF' 
  }}
  component={Link}  
  to="/checkoutpage"    
>
  Check out
</Button>
            </List>
        );
    })
) : (
    <Typography sx={{justifyContent:'center',alignItems:'center',display:'flex',fontSize:22}}>No items</Typography>
)}

       
        
      </div>
    </Drawer>
  );
};

export default ShoppingCartDrawer;
