import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/joy';
import heroElectro1 from '../../assets/carouselImages/KUTTHU_VILAKKU_SLIDER-1.webp';
import heroElectro2 from '../../assets/carouselImages/ANIMALS_SLIDER.webp';
import heroElectro3 from '../../assets/carouselImages/BIRDS_SLIDER-1.webp';
import heroElectro4 from '../../assets/carouselImages/Container.png';
import heroElectro5 from '../../assets/carouselImages/OWN_PCB_SLIDER-1.webp';
import heroElectro6 from '../../assets/carouselImages/WELCOME_SLIDER.webp';
import heroElectro7 from '../../assets/carouselImages/WELCOME_WOMANS_SLIDER.png';

interface CarouselProps {
  images: string[];
}

const CarouselImages: React.FC<CarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(nextSlide, 5000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [currentIndex, images.length]);

  const handleIndicatorClick = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        width: '80vw', 
        maxWidth: '1900px', 
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto', 
        mt: 5
      }}
    >
      <Box
        sx={{
          display: 'flex',
          transition: 'transform 1s ease-in-out',
          transform: `translateX(-${currentIndex * 100}%)`,
          width: `${images.length * 100}%`,
        }}
      >
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              flex: '0 0 100%', 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={image}
              alt={`Carousel ${index}`}
              style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
            />
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 10,
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {images.map((_, index) => (
          <Box
            key={index}
            sx={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: currentIndex === index ? 'white' : 'gray',
              margin: '0 4px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
            }}
            onClick={() => handleIndicatorClick(index)}
          />
        ))}
      </Box>
    </Box>
  );
};

const images = [
  heroElectro6,  
  heroElectro1,
  heroElectro2,
  heroElectro3,
  heroElectro4,
  heroElectro5,
  heroElectro7
];

export default function App() {
  return <CarouselImages images={images} />;
}
