import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  CircularProgress,
  LinearProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { styled } from '@mui/system';
import { Stack } from '@mui/joy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { BASE_URL, BASE_URL_Test } from '../../../../utils/baseUrl';
import { urls } from '../../../../urls/urls';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import format from 'date-fns/format';
import { TextFieldProps } from '@mui/material';
import Snackbar from '@mui/joy/Snackbar';



const BASE_IMAGE_URL = 'https://heroelectroapi-production.up.railway.app';


const staticData = {
  images: [
    { id: 3, url: "/media/images/PR3_1.jpg" },
    { id: 4, url: "/media/images/PR3_2.jpg" },
    { id: 5, url: "/media/images/PR3_3.jpg" },
    { id: 6, url: "/media/images/PR3_4.jpg" },
  ],
 
  
};

const StyledPaper = styled(Paper)({
  padding: '16px',
  border: '1px solid #ddd',
  borderRadius: '4px',
  backgroundColor: '#fff',
});


const ImageItem = ({ name, error, onRemove }: { name: string; error?: boolean; onRemove: () => void }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', p: 1, border: '1px solid #ddd', borderRadius: '4px' }}>
    <img 
      src={name}  
      alt="Product thumbnail" 
      style={{ width: '50px', height: '50px', marginRight: '8px', objectFit: 'cover' }} 
    />
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="body2" color="textSecondary">
        {name}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={100}
        sx={{
          height: '6px',
          borderRadius: '2px',
          mt: 1,
          backgroundColor: 'inherit',
          '& .MuiLinearProgress-bar': { backgroundColor: error ? 'red' : 'green' },
        }}
      />
    </Box>
  
    <IconButton onClick={onRemove} sx={{ ml: 1 }}>
      <CloseIcon />
    </IconButton>
  </Box>
);



const ProductForm: React.FC<{ onBack: () => void }> = ({ onBack }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedBannerFile, setSelectedBannerFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const imageInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(new Date());
  const [showSnackBar,setShowSnackBar]=useState({
    show:false,
    message:""
  });

 
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [salesStart, setSalesStart] = useState<Date | null>(null);
const [salesEnd, setSalesEnd] = useState<Date | null>(null);
const [taxStatus, setTaxStatus] = useState('taxable'); // 


const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const files = event.target.files;
  const validFiles: File[] = [];
  const errors: string[] = [];

  if (files) {
   
    if (selectedFiles.length + files.length > 4) {
      setErrorMessage('You can only upload up to 4 images.');
      return;
    }

    Array.from(files).forEach((file) => {
      
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        validFiles.push(file);
      } else {
        errors.push('${file.name} is not a valid image. Only .jpg and .png are allowed.');
      }
    });

   
    if (errors.length > 0) {
      setErrorMessage(errors.join(', '));
    } else {
      setErrorMessage(null);
    }

   
    setSelectedFiles((prev) => [...prev, ...validFiles]);
  }
};
const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'image' | 'file') => {
  const file = event.target.files?.[0];
  if (file) {
    setSelectedFile(file);
    setSuccessMessage(null); 
  }
};
const handleFileBannerChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'image' | 'file') => {
  const file = event.target.files?.[0];
  if (file) {
    setSelectedBannerFile(file);
    setSuccessMessage(null); 
  }
};


const [formValues, setFormValues] = useState({
  productid: '',
  productname: '',
  description: '',
  price: '',
  discount: '',
  cutoffprice: '',
  category1: '',
  category2: '',
  category3: '',
  category4: '',
  category5: '',
  category6: '',
  sku: '',
  imageid: '',
  imagedescription: '',
  weight: '',
  // frames: '',
  width: '',
  height: '',
  holescount: '',
  tags: '',
  regularprice: '',
  saleprice: '',
  published:'',
  featured:'',
  instock:'',
  backorders:'',
  stock:'',
  yt_link:'',


});
const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
  const { name, value } = e.target;
  setFormValues(prevValues => ({
    ...prevValues,
    [name]: value
  }));
};


  const handleRemoveFile = () => {
    setSelectedFile(null);
  };



  const handleSubmit = async () => {
    console.log("selectediflr",selectedFile)
    if (selectedFile) {
      setLoading(true);
      const formData = new FormData();
      formData.append('bulkfile', selectedFile);
      try {
        const response = await fetch(BASE_URL_Test + urls('bulkuplaod'), {
          method: 'POST',
          body: formData,
        });
        const data = await response.json();
        if (data.status === 'success') {
          setSuccessMessage('File uploaded successfully!');
          setSelectedFile(null);
        } else {
          console.error('Upload failed:', data.message);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error during file upload:', error);
        setLoading(false);
      }
    }
  };

  const handleBannersSubmit = async () => {
    console.log("selectediflr",selectedBannerFile)
    if (selectedBannerFile) {
      setLoading(true);
      const formData = new FormData();
      formData.append('bulkfile', selectedBannerFile);
      try {
        const response = await fetch(BASE_URL_Test + urls('bannersupload'), {
          method: 'POST',
          body: formData,
        });
        const data = await response.json();
        if (data.status === 'success') {
          setSuccessMessage('File uploaded successfully!');
          setSelectedFile(null);
        } else {
          console.error('Upload failed:', data.message);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error during file upload:', error);
        setLoading(false);
      }
    }
  };

  



  const handleAddProduct = async () => {
    
    if (selectedFiles.length > 0) {
      const formData = new FormData();
      let imagepaths=''
      formData.append('productid', formValues.productid);
      formData.append('productname',formValues.productname);
      formData.append('description', formValues.description);
      formData.append('price', formValues.price);
      formData.append('discount', formValues.discount);
      formData.append('cutoffprice', formValues.cutoffprice);
      formData.append('category1', formValues.category1);
      formData.append('category2', formValues.category2);
      formData.append('category3', formValues.category3);
      formData.append('category4', formValues.category4);
      formData.append('category5', formValues.category5);
      formData.append('category6',formValues.category6);
      formData.append('sku', formValues.sku);
      formData.append('imageid', formValues.imageid);
      formData.append('imagedescription', formValues.imagedescription);
      formData.append('weight', formValues.weight);
      // formData.append('frames', formValues.frames);
      formData.append('width', formValues.width);
      formData.append('height', formValues.height);
      formData.append('holescount', formValues.holescount);
      formData.append('dateadded', new Date().toISOString()); 
      formData.append('tags',formValues.tags);
      formData.append('published', formValues.published);
      formData.append('featured', formValues.featured);
      formData.append('instock', formValues.instock);
      formData.append('stock', formValues.stock);
      formData.append('taxstatus', taxStatus);

      
      if (salesStart) {
        formData.append('salesstart', format(salesStart, 'dd-MM-yyyy'));
      }
      if (salesEnd) {
        formData.append('salesend', format(salesEnd, 'dd-MM-yyyy'));
      }
      formData.append('backorders', formValues.backorders);
      formData.append('yt_link', formValues.yt_link);
     
      
      selectedFiles.map((file, index) => {
        formData.append('imagepaths', file);
  
      });
      
      try {
        const response = await fetch(BASE_URL_Test + urls('addproduct'), {
          method: 'POST',
          body: formData,
        });
        const data = await response.json();
        if (data.status === 'success') {
          console.log('Product added successfully:', data);
          setShowSnackBar({...showSnackBar,show:true,message:"product added successfully"});
        } else {
          setShowSnackBar({...showSnackBar,show:true,message:data['data']});
          console.error('Upload failed:', data.message);
        }
      } catch (error) {
        console.error('Error during file upload:', error);
      }
    }
  };
  

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);
  const handleImageClick = () => {
    imageInputRef.current?.click();
  };

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  return (
    <>
    <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnackBar['show']}
        variant={'solid'}
        color={"primary"}
        sx={{color:'#fff'}}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
           console.log("onclide",reason)
          }
          // setOpen(false);
        //   setShowModal({...showModal,showSnack:false,message:'',snackColor:''})
          // dispatch(setModalProperties({showModal:false,modalMessage:''}))
        }}
      >
       {showSnackBar['message']}
      </Snackbar>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Add Product</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{ display: 'flex', gap: 2 }}>
  <StyledPaper sx={{ flex: 1 }}>
    <Box sx={{ display: 'flex', gap: 2 }}>
      {/* Product ID and Product Name */}
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Product ID
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          name="productid"
          value={formValues.productid}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
        />
      </Box>

      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Product Name
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          name="productname"
          value={formValues.productname}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
        />
      </Box>
    </Box>

    <Box sx={{ display: 'flex', gap: 2 }}>
     
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Description
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.description}
          onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>

      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Price
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.price}
          onChange={(e) => setFormValues({ ...formValues, price: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
    </Box>

    <Box sx={{ display: 'flex', gap: 2 }}>
      
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Discount
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.discount}
          onChange={(e) => setFormValues({ ...formValues, discount: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>

      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Cut Off Price
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.cutoffprice}
          onChange={(e) => setFormValues({ ...formValues, cutoffprice: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
    </Box>

   
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Category 1
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.category1}
          onChange={(e) => setFormValues({ ...formValues, category1: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Category 2
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.category2}
          onChange={(e) => setFormValues({ ...formValues, category2: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
    </Box>

    
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Category 3
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.category3}
          onChange={(e) => setFormValues({ ...formValues, category3: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Category 4
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.category4}
          onChange={(e) => setFormValues({ ...formValues, category4: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
    </Box>

    <Box sx={{ display: 'flex', gap: 2 }}>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Category 5
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.category5}
          onChange={(e) => setFormValues({ ...formValues, category5: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Category 6
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.category6}
          onChange={(e) => setFormValues({ ...formValues, category6: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
    </Box>

   
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          SKU
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.sku}
          onChange={(e) => setFormValues({ ...formValues, sku: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Image ID
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.imageid}
          onChange={(e) => setFormValues({ ...formValues, imageid: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
    </Box>
    <Typography variant="h6" gutterBottom>
                Image Description
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.imagedescription}
                onChange={(e) => setFormValues({ ...formValues, imagedescription: e.target.value })}
              />

<Typography variant="h6" gutterBottom>
                Number of Holes
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.holescount}
                onChange={(e) => setFormValues({ ...formValues, holescount: e.target.value })}
              />

         
              {/* <Typography variant="h6" gutterBottom>
               Frames
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.frames}
                onChange={(e) => setFormValues({ ...formValues, frames: e.target.value })}
              /> */}



            <Typography variant="h6" gutterBottom>
               Width
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.width}
                onChange={(e) => setFormValues({ ...formValues, width: e.target.value })}
              />




<Typography variant="h6" gutterBottom>
               Height
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.height}
                onChange={(e) => setFormValues({ ...formValues, height: e.target.value })}
              />


               <Typography variant="h6" gutterBottom>
               Weight
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.weight}
                onChange={(e) => setFormValues({ ...formValues, weight: e.target.value })}
              />
                <Typography variant="h6" gutterBottom>
               Tags
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.tags}
                onChange={(e) => setFormValues({ ...formValues, tags: e.target.value })}
              />
                  <Typography variant="h6" gutterBottom>
      Published
    </Typography>
    <FormControl fullWidth sx={{ mb: 2 }} size="small">
      <InputLabel>Published</InputLabel>
      <Select
        name="published"
        value={formValues.published}
        onChange={handleInputChange}
        label="Published"
      >
        <MenuItem value="yes">Yes</MenuItem>
        <MenuItem value="no">No</MenuItem>
      </Select>
    </FormControl>

    <Typography variant="h6" gutterBottom>
      Featured
    </Typography>
    <FormControl fullWidth sx={{ mb: 2 }} size="small">
      <InputLabel>Featured</InputLabel>
      <Select
        name="featured"
        value={formValues.featured}
        onChange={handleInputChange}
        label="Featured"
      >
        <MenuItem value="yes">Yes</MenuItem>
        <MenuItem value="no">No</MenuItem>
      </Select>
    </FormControl>

    <Typography variant="h6" gutterBottom>
      In Stock
    </Typography>
    <FormControl fullWidth sx={{ mb: 2 }} size="small">
      <InputLabel>In Stock</InputLabel>
      <Select
        name="instock"
        value={formValues.instock}
        onChange={handleInputChange}
        label="In Stock"
      >
        <MenuItem value="yes">Yes</MenuItem>
        <MenuItem value="no">No</MenuItem>
      </Select>
    </FormControl>

<Typography variant="h6" gutterBottom>
                Stock
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.stock}
                onChange={(e) => setFormValues({ ...formValues, stock: e.target.value })}
              />
    <Typography variant="h6" gutterBottom>
      Back Orders
    </Typography>
    <FormControl fullWidth sx={{ mb: 2 }} size="small">
      <InputLabel>Back Orders</InputLabel>
      <Select
        name="backorders"
        value={formValues.backorders}
        onChange={handleInputChange}
        label="Back Orders"
      >
        <MenuItem value="yes">Yes</MenuItem>
        <MenuItem value="no">No</MenuItem>
      </Select>
    </FormControl>
    
    <Box sx={{ display: 'flex', gap: 2 }}>
      
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Sales Starting Date"
          value={salesStart}
          onChange={(newValue) => setSalesStart(newValue)}
         
        />
      </LocalizationProvider>

     
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Sales Ending Date"
          value={salesEnd}
          onChange={(newValue) => setSalesEnd(newValue)}
         
        />
      </LocalizationProvider>
    </Box>

   
    <FormControl fullWidth sx={{ mt: 2 }}>
      <InputLabel id="tax-status-label">Tax Status</InputLabel>
      <Select
        labelId="tax-status-label"
        id="tax-status"
        value={taxStatus}
        label="Tax Status"
        onChange={(e) => setTaxStatus(e.target.value)}
      >
        <MenuItem value="taxable">Taxable</MenuItem>
        <MenuItem value="non-taxable">Non-Taxable</MenuItem>
      </Select>
    </FormControl>

    <Typography variant="h6" gutterBottom>
               Youtube Video Link
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.yt_link}
                onChange={(e) => setFormValues({ ...formValues, yt_link: e.target.value })}
              />

  </StyledPaper>

 
  <Box sx={{ width: '35%' }}>
    <StyledPaper sx={{ mb: 2, textAlign: 'center' }}>
      {selectedFiles.length > 0 ? (
        <img
          src={URL.createObjectURL(selectedFiles[0])}
          alt="Product"
          style={{ width: '80%' }}
        />
      ) : (
        <img src="/placeholder.jpg" alt="Placeholder" style={{ width: '80%' }} />
      )}
    </StyledPaper>

   
    <StyledPaper>
      <Typography variant="h6" gutterBottom>
        Product Gallery
      </Typography>
      <Box
        sx={{
          border: '2px dashed #ddd',
          borderRadius: '4px',
          p: 2,
          textAlign: 'center',
          mb: 2,
          cursor: 'pointer',
        }}
        onClick={() => imageInputRef.current?.click()}
      >
        <img src="/ph_image-light.svg" alt="Product" style={{ width: '20%' }} />
        <Typography variant="body1" color="textSecondary">
          Drop your image here, or browse
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Jpeg, png are allowed
        </Typography>
        <input
          type="file"
          accept="image/jpeg, image/png"
          multiple
          ref={imageInputRef}
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
      </Box>

     
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {selectedFiles.map((file, index) => (
          <ImageItem
            key={index}
            name={URL.createObjectURL(file)}
            error={file.type !== 'image/jpeg' && file.type !== 'image/png'}
            onRemove={() => {
              setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
            }}
          />
        ))}
      </Box>

      {errorMessage && (
        <Typography variant="body2" sx={{ mt: 2, color: 'red', fontWeight: 'bold' }}>
          {errorMessage}
        </Typography>
      )}
    </StyledPaper>

   
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
      <Button variant="contained" color="primary" sx={{ width: '32%' }} onClick={handleAddProduct}>
        UPDATE
      </Button>
      <Button variant="contained" color="error" sx={{ width: '32%' }}>
        DELETE
      </Button>
      <Button variant="contained" color="inherit" sx={{ width: '32%' }} onClick={onBack}>
        CANCEL
      </Button>
    </Box>
  </Box>
        </Box>

        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ marginTop: 5 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Bulk Upload</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 2, p: 2 }}>
          {selectedFile ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                border: '1px solid #ddd',
                borderRadius: '4px',
                padding: '8px',
              }}
            >
              <Typography variant="body1">{selectedFile.name}</Typography>
              <IconButton onClick={handleRemoveFile} size="small">
                <CloseIcon />
              </IconButton>
            </Box>
          ) : (
            <label htmlFor="upload-excel" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: 1 }}>
              <img src="/xlicon.webp" alt="Excel" style={{ width: '120px' }} />
              <input
                type="file"
                id="upload-excel"
                accept=".xlsx, .xls"
                style={{ display: 'none' }}
                onChange={(event) => handleFileChange(event, 'file')}
              />
            </label>
          )}
          {!selectedFile && (
            <Typography variant="body1" sx={{ color: '#bab7bc', fontSize: '20px' }}>
              Click this icon to upload an Excel file for bulk product upload...
            </Typography>
          )}
          {selectedFile && (
            <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2, backgroundColor: '#4F46E5', width: '100px' }}>
              {loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Upload'}
            </Button>
          )}
          {successMessage && (
            <Typography variant="body2" sx={{ mt: 2, color: 'green', fontWeight: 'bold' }}>
              {successMessage}
            </Typography>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>



 {/* Banners   */}


    <Accordion sx={{ marginTop: 5 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Add Banners</Typography>
        </AccordionSummary>

        <AccordionDetails>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 2, p: 2 }}>
          {selectedFile ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                border: '1px solid #ddd',
                borderRadius: '4px',
                padding: '8px',
              }}
            >
              <Typography variant="body1">{selectedFile.name}</Typography>
              <IconButton onClick={handleRemoveFile} size="small">
                <CloseIcon />
              </IconButton>
            </Box>
          ) : (
            <label htmlFor="upload-excel" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: 1 }}>
              <img src="/xlicon.webp" alt="Excel" style={{ width: '120px' }} />
              <input
                type="file"
                id="upload-excel"
                accept=".xlsx, .xls"
                style={{ display: 'none' }}
                onChange={(event) => handleFileBannerChange(event, 'file')}
              />
            </label>
          )}
          {!selectedBannerFile && (
            <Typography variant="body1" sx={{ color: '#bab7bc', fontSize: '20px' }}>
              Click this icon to upload an Excel file for bulk product upload banners...
            </Typography>
          )}
          {selectedBannerFile && (
            <Button variant="contained" onClick={handleBannersSubmit} sx={{ mt: 2, backgroundColor: '#4F46E5', width: '100px' }}>
              {loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Upload'}
            </Button>
          )}
          {successMessage && (
            <Typography variant="body2" sx={{ mt: 2, color: 'green', fontWeight: 'bold' }}>
              {successMessage}
            </Typography>
          )}
        </Box>
      </AccordionDetails>
        {/* <AccordionDetails>
        <Box sx={{ display: 'flex', gap: 2 }}>
  

 
  <Box sx={{ width: '35%' }}>
    <StyledPaper sx={{ mb: 2, textAlign: 'center' }}>
      {selectedFiles.length > 0 ? (
        <img
          src={URL.createObjectURL(selectedFiles[0])}
          alt="Product"
          style={{ width: '80%' }}
        />
      ) : (
        <img src="/placeholder.jpg" alt="Placeholder" style={{ width: '80%' }} />
      )}
    </StyledPaper>

   
    <StyledPaper>
      <Typography variant="h6" gutterBottom>
        Product Gallery
      </Typography>
      <Box
        sx={{
          border: '2px dashed #ddd',
          borderRadius: '4px',
          p: 2,
          textAlign: 'center',
          mb: 2,
          cursor: 'pointer',
        }}
        onClick={() => imageInputRef.current?.click()}
      >
        <img src="/ph_image-light.svg" alt="Product" style={{ width: '20%' }} />
        <Typography variant="body1" color="textSecondary">
          Drop your image here, or browse
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Jpeg, png are allowed
        </Typography>
        <input
          type="file"
          accept="image/jpeg, image/png"
          multiple
          ref={imageInputRef}
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
      </Box>

     
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {selectedFiles.map((file, index) => (
          <ImageItem
            key={index}
            name={URL.createObjectURL(file)}
            error={file.type !== 'image/jpeg' && file.type !== 'image/png'}
            onRemove={() => {
              setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
            }}
          />
        ))}
      </Box>

      {errorMessage && (
        <Typography variant="body2" sx={{ mt: 2, color: 'red', fontWeight: 'bold' }}>
          {errorMessage}
        </Typography>
      )}
    </StyledPaper>

   
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
      <Button variant="contained" color="success" sx={{ width: '32%' }} onClick={handleAddProduct}>
        Save
      </Button>
     
      <Button variant="contained" color="error" sx={{ width: '32%' }} onClick={onBack}>
        CANCEL
      </Button>
    </Box>
  </Box>
        </Box>

        </AccordionDetails> */}
      </Accordion>

{/* Featured Product  */}

     
    <Accordion sx={{ marginTop: 5 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Add Featured Product</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{ display: 'flex', gap: 2 }}>
  <StyledPaper sx={{ flex: 1 }}>
    <Box sx={{ display: 'flex', gap: 2 }}>
      {/* Product ID and Product Name */}
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Product ID
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          name="productid"
          value={formValues.productid}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
        />
      </Box>

      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Product Name
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          name="productname"
          value={formValues.productname}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
        />
      </Box>
    </Box>

    <Box sx={{ display: 'flex', gap: 2 }}>
     
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Description
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.description}
          onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>

      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Price
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.price}
          onChange={(e) => setFormValues({ ...formValues, price: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
    </Box>

    <Box sx={{ display: 'flex', gap: 2 }}>
      
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Discount
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.discount}
          onChange={(e) => setFormValues({ ...formValues, discount: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>

      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Cut Off Price
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.cutoffprice}
          onChange={(e) => setFormValues({ ...formValues, cutoffprice: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
    </Box>

   
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Category 1
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.category1}
          onChange={(e) => setFormValues({ ...formValues, category1: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Category 2
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.category2}
          onChange={(e) => setFormValues({ ...formValues, category2: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
    </Box>

    
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Category 3
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.category3}
          onChange={(e) => setFormValues({ ...formValues, category3: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Category 4
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.category4}
          onChange={(e) => setFormValues({ ...formValues, category4: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
    </Box>

    <Box sx={{ display: 'flex', gap: 2 }}>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Category 5
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.category5}
          onChange={(e) => setFormValues({ ...formValues, category5: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Category 6
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.category6}
          onChange={(e) => setFormValues({ ...formValues, category6: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
    </Box>

   
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          SKU
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.sku}
          onChange={(e) => setFormValues({ ...formValues, sku: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" gutterBottom>
          Image ID
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={formValues.imageid}
          onChange={(e) => setFormValues({ ...formValues, imageid: e.target.value })}
          sx={{ mb: 2 }}
        />
      </Box>
    </Box>
    <Typography variant="h6" gutterBottom>
                Image Description
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.imagedescription}
                onChange={(e) => setFormValues({ ...formValues, imagedescription: e.target.value })}
              />

<Typography variant="h6" gutterBottom>
                Number of Holes
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.holescount}
                onChange={(e) => setFormValues({ ...formValues, holescount: e.target.value })}
              />

         
              {/* <Typography variant="h6" gutterBottom>
               Frames
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.frames}
                onChange={(e) => setFormValues({ ...formValues, frames: e.target.value })}
              /> */}



<Typography variant="h6" gutterBottom>
               Width
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.width}
                onChange={(e) => setFormValues({ ...formValues, width: e.target.value })}
              />




<Typography variant="h6" gutterBottom>
               Height
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.height}
                onChange={(e) => setFormValues({ ...formValues, height: e.target.value })}
              />


               <Typography variant="h6" gutterBottom>
               Weight
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.weight}
                onChange={(e) => setFormValues({ ...formValues, weight: e.target.value })}
              />
                <Typography variant="h6" gutterBottom>
               Tags
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.tags}
                onChange={(e) => setFormValues({ ...formValues, tags: e.target.value })}
              />
                  <Typography variant="h6" gutterBottom>
      Published
    </Typography>
    <FormControl fullWidth sx={{ mb: 2 }} size="small">
      <InputLabel>Published</InputLabel>
      <Select
        name="published"
        value={formValues.published}
        onChange={handleInputChange}
        label="Published"
      >
        <MenuItem value="yes">Yes</MenuItem>
        <MenuItem value="no">No</MenuItem>
      </Select>
    </FormControl>

    <Typography variant="h6" gutterBottom>
      Featured
    </Typography>
    <FormControl fullWidth sx={{ mb: 2 }} size="small">
      <InputLabel>Featured</InputLabel>
      <Select
        name="featured"
        value={formValues.featured}
        onChange={handleInputChange}
        label="Featured"
      >
        <MenuItem value="yes">Yes</MenuItem>
        <MenuItem value="no">No</MenuItem>
      </Select>
    </FormControl>

    <Typography variant="h6" gutterBottom>
      In Stock
    </Typography>
    <FormControl fullWidth sx={{ mb: 2 }} size="small">
      <InputLabel>In Stock</InputLabel>
      <Select
        name="instock"
        value={formValues.instock}
        onChange={handleInputChange}
        label="In Stock"
      >
        <MenuItem value="yes">Yes</MenuItem>
        <MenuItem value="no">No</MenuItem>
      </Select>
    </FormControl>

<Typography variant="h6" gutterBottom>
                Stock
              </Typography>
              <TextField 
                fullWidth 
                variant="outlined" 
                size="small" 
                sx={{ mb: 2 }} 
                value={formValues.stock}
                onChange={(e) => setFormValues({ ...formValues, stock: e.target.value })}
              />
    <Typography variant="h6" gutterBottom>
      Back Orders
    </Typography>
    <FormControl fullWidth sx={{ mb: 2 }} size="small">
      <InputLabel>Back Orders</InputLabel>
      <Select
        name="backorders"
        value={formValues.backorders}
        onChange={handleInputChange}
        label="Back Orders"
      >
        <MenuItem value="yes">Yes</MenuItem>
        <MenuItem value="no">No</MenuItem>
      </Select>
    </FormControl>
    
    <Box sx={{ display: 'flex', gap: 2 }}>
      
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Sales Starting Date"
          value={salesStart}
          onChange={(newValue) => setSalesStart(newValue)}
         
        />
      </LocalizationProvider>

     
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Sales Ending Date"
          value={salesEnd}
          onChange={(newValue) => setSalesEnd(newValue)}
         
        />
      </LocalizationProvider>
    </Box>

   
    <FormControl fullWidth sx={{ mt: 2 }}>
      <InputLabel id="tax-status-label">Tax Status</InputLabel>
      <Select
        labelId="tax-status-label"
        id="tax-status"
        value={taxStatus}
        label="Tax Status"
        onChange={(e) => setTaxStatus(e.target.value)}
      >
        <MenuItem value="taxable">Taxable</MenuItem>
        <MenuItem value="non-taxable">Non-Taxable</MenuItem>
      </Select>
    </FormControl>

  </StyledPaper>

 
  <Box sx={{ width: '35%' }}>
    <StyledPaper sx={{ mb: 2, textAlign: 'center' }}>
      {selectedFiles.length > 0 ? (
        <img
          src={URL.createObjectURL(selectedFiles[0])}
          alt="Product"
          style={{ width: '80%' }}
        />
      ) : (
        <img src="/placeholder.jpg" alt="Placeholder" style={{ width: '80%' }} />
      )}
    </StyledPaper>

   
    <StyledPaper>
      <Typography variant="h6" gutterBottom>
        Product Gallery
      </Typography>
      <Box
        sx={{
          border: '2px dashed #ddd',
          borderRadius: '4px',
          p: 2,
          textAlign: 'center',
          mb: 2,
          cursor: 'pointer',
        }}
        onClick={() => imageInputRef.current?.click()}
      >
        <img src="/ph_image-light.svg" alt="Product" style={{ width: '20%' }} />
        <Typography variant="body1" color="textSecondary">
          Drop your image here, or browse
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Jpeg, png are allowed
        </Typography>
        <input
          type="file"
          accept="image/jpeg, image/png"
          multiple
          ref={imageInputRef}
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
      </Box>

     
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {selectedFiles.map((file, index) => (
          <ImageItem
            key={index}
            name={URL.createObjectURL(file)}
            error={file.type !== 'image/jpeg' && file.type !== 'image/png'}
            onRemove={() => {
              setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
            }}
          />
        ))}
      </Box>

      {errorMessage && (
        <Typography variant="body2" sx={{ mt: 2, color: 'red', fontWeight: 'bold' }}>
          {errorMessage}
        </Typography>
      )}
    </StyledPaper>

   
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
      <Button variant="contained" color="primary" sx={{ width: '32%' }} onClick={handleAddProduct}>
        UPDATE
      </Button>
      <Button variant="contained" color="error" sx={{ width: '32%' }}>
        DELETE
      </Button>
      <Button variant="contained" color="inherit" sx={{ width: '32%' }} onClick={onBack}>
        CANCEL
      </Button>
    </Box>
  </Box>
        </Box>

        </AccordionDetails>
      </Accordion>



    
    </>
  );
};

export default ProductForm;