import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, Box, IconButton, Avatar, AppBar, Toolbar, InputAdornment, TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Button, Modal } from '@mui/material';
import { Notifications, CalendarToday, Search } from '@mui/icons-material';
import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { useFetch } from '../../../../hooks/useFetch';
import { urls } from '../../../../urls/urls';
import { BASE_URL_Test } from '../../../../utils/baseUrl';

import CloseIcon from '@mui/icons-material/Close';

import { ReceiptNumber,convertTimestampToDate } from '../../../../utils';
import { Stack } from '@mui/joy';

import { getCurrentGreeting,getDate } from '../../../../utils/functionalOperations';

interface Transaction {
  id: string;
  card_id: string;
  created_at: string;
  amount: number;
  method: string;
  status: string;
}

interface StatisticsDataProps{
  totalorders:string;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const DashboardContent: React.FC = () => {
  const { data: TransactionsData, error, isLoading, fetchData } = useFetch(BASE_URL_Test + urls('allpayments'), {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });


  const { data: statsData, error:statsDataError, isLoading:statsDataLoadin, fetchData:fetchStatsData } = useFetch(BASE_URL_Test + urls('overallstats'), {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });


  const { formattedDate, formattedTime } = getDate();
  const [openModal, setOpenModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);
  const [paymentTransactions, setPaymentTransactions] = useState<Transaction[]>([]);

  const [statisticsData,setStatisticsData]=useState<StatisticsDataProps[]>([]);
  const cards = [
    { title: 'Total Orders', value: '$350.40', image: '/totalProducts.svg' },
    { title: 'Completed Orders', value: '$642.39', image: '/orders.svg' },
    { title: 'Cancelled Orders', value: '$642.39', image: '/orders.svg' },
    { title: 'Today Revenue', value: '$2,000.00', image: '/Flag.svg '},
    { title: 'Top Products', value: '154', image: '/topProducts.svg' },
  ];
  const images=[{
    image: '/totalProducts.svg'
  },{
     image: '/orders.svg'
  },{
    image:'/orders.svg'
  },{
    image: '/Flag.svg '
  },{
    image:'/topProducts.svg'
  }]
  const [captureSuccess, setCaptureSuccess] = useState(false); 

 
  const handleStatusClick = (transaction: Transaction) => {
    setSelectedTransaction(transaction);
    setCaptureSuccess(false); 
    setOpenModal(true);
  };


  const handleCloseModal = () => {
    setOpenModal(false);
  };

  
  const handleCaptureClick = () => {
   
    setCaptureSuccess(true);
  };


  useEffect(() => {
    fetchData();
    fetchStatsData();
  }, []);

  useEffect(() => {
    console.log("TransactioDa",TransactionsData)
    if (TransactionsData && TransactionsData['status'] === 'success' && TransactionsData['data']['count'] > 0) {
      setPaymentTransactions(TransactionsData['data']['items']);
    }
  }, [TransactionsData, error]);
const cardsDataArray:{title:string,value:string,image:string}[]=[];

  useEffect(()=>{

    if(statsDataError)
    {

    }else if(statsData)
    {
      console.log("statsData",statsData)
      if(statsData['status']=='success')
      {
        // { title: 'Total Products', value: '$350.40', image: '/totalProducts.svg' },
        const statsDataarr:[]=statsData['data'];
        const keysarray=Object.keys(statsData['data']);
        // for (let index = 0; index < statsDataarr.length; index++) {
        //   // const element = array[index];
        //   cardsDataArray.push({ title: cards[index]['title'],value: statsData[index][keysarray[index]],image: ''+images[index]})
          
        // }
        console.log('cards length:', statsDataarr.length);
        for (let index = 0; index < cards.length; index++) {
          // Ensure statsData and keysarray are defined and have the necessary length
          if (index < statsDataarr.length && index < keysarray.length && index < images.length) {
              // cardsDataArray.push({
              //     title: cards[index]['title'],
              //     value: statsData[index][keysarray[index]],
              //     image: images[index].image // Assuming images is an array of objects
              // });
          } else {
              console.warn(`Index ${index} is out of bounds for statsData, keysarray, or images`);
          }
      }
      
        setStatisticsData(statsData['data'])
      }
    }

  },[statsData,statsDataError])

  const salesData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
    datasets: [
      {
        label: 'Revenue',
        data: [1200, 1900, 3000, 5000, 2300, 3400, 7000, 9000, 11000],
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const mostPurchasedProductsData = {
    labels: ['Light1', 'Light2', 'Light3', 'Light4', 'Light5', 'Light6'],
    datasets: [{
      label: 'Products',
      data: [100, 150, 200, 120, 130, 140],
      backgroundColor: [
        'rgba(75, 192, 192, 0.5)',
        'rgba(192, 75, 75, 0.5)',
        'rgba(75, 75, 192, 0.5)',
        'rgba(192, 192, 75, 0.5)',
        'rgba(75, 192, 75, 0.5)',
        'rgba(192, 75, 192, 0.5)'
      ]
    }]
  };

  return (

    <Box sx={{ display: 'flex',  }}>
     {/* <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            width: 400,
            p: 3,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            bgcolor: '#fff',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Stack alignItems="center" spacing={2}>
            <CheckCircleIcon sx={{ fontSize: 60, color: 'green' }} />
            <Typography sx={{ fontSize: 24, fontWeight: 'bold', color: 'green' }}>Successful Payment</Typography>
            <Stack spacing={1} sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Payment type</Typography>
                <Typography>Net Banking</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Phone number</Typography>
                <Typography>+12345678910</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Email</Typography>
                <Typography>GN1996@gmail.com</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Transaction id</Typography>
                <Typography>2345678910</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', mt: 2 }}>
                <Typography>Amount Paid</Typography>
                <Typography>$543.02</Typography>
              </Box>
            </Stack>
            <Button variant="solid" color="primary" sx={{ mt: 2, width: '100%' }} onClick={handleOrderStatusClick}>
              Order Status
            </Button>
          </Stack>
        </Box>
      </Modal> */}


      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#EEF2FF', p: 3, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
        <AppBar position="static" elevation={0} sx={{ borderBottom: '1px solid #ddd', mb: 2, backgroundColor: "white", color: 'black' }}>
          <Toolbar>
            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            {getCurrentGreeting()} 
            </Typography>
            <IconButton>
              <CalendarToday />
            </IconButton>
            <Typography variant="body1" sx={{ mx: 2 }}>
            {formattedDate} / {formattedTime}
            </Typography>
            <IconButton>
              <Notifications />
            </IconButton>
            <Avatar alt="Gn" src="/profile.webp" />
          </Toolbar>
        </AppBar>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
          {statisticsData.map((card:any, index:number) => (
              <Grid item xs={12} sm={6} md={2.4} key={index}>
                <Card>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img src={images[0].image} alt={card.title} style={{ width: '43px', marginRight: '16px' }} />
                      <Box>
                        <Typography color="textSecondary" gutterBottom>
                          {cards[index].title}
                        </Typography>
                        <Typography variant="h5" component="div">
                        $ {statisticsData[index].totalorders}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
                 
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
              
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" component="div">
                        Your Sales Report
                      </Typography>
                      <Typography variant="h4" component="div" sx={{ mt: 2 }}>
                        ₹ 7000.80
                      </Typography>
                      <Typography variant="body2" color="#00D03A">
                        ₹ 2500.00 (+3.5%)
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" color="textSecondary">
                          1D 1W <span style={{ color: '#3f51b5', fontWeight: 'bold' }}>1M</span> 6M 1Y
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" component="div">
                        Revenue Year - 2024
                      </Typography>
                      <Line data={salesData} height={100} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

        
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: 2 }}>
              <Typography variant="h5" component="h2" gutterBottom>
                Add Your Favorite Quote
              </Typography>
              <TextField
                fullWidth
                label="Enter Quote"
                variant="outlined"
                sx={{ marginBottom: 2 }}
              />
              <Button
                variant="contained"
                fullWidth
                sx={{ backgroundColor: 'violet', color: 'white' }}
              >
                Submit
              </Button>
            </Card>
          </Grid>
        </Grid>

         

          <Grid container spacing={2} sx={{ mt: 2 }} direction="row">
         
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={8}>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="div">Last Transactions</Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <TextField
                      placeholder="Search"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Order ID</TableCell>
                          <TableCell>Item</TableCell>
                          <TableCell>Category</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Type of Payment</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paymentTransactions.map((transaction) => (
                          <TableRow key={transaction.id}>
                            <TableCell>{transaction.id}</TableCell>
                            <TableCell>{"test"}</TableCell>
                            <TableCell>{transaction.status}</TableCell>
                            <TableCell>{convertTimestampToDate(transaction.created_at)}</TableCell>
                            <TableCell>{transaction.amount}</TableCell>
                            <TableCell>{transaction.method}</TableCell>
                            <TableCell>
                              <Typography
                                color={transaction.status === 'captured' ? 'green' : 'red'}
                                onClick={() => handleStatusClick(transaction)} 
                                style={{ cursor: 'pointer' }} 
                              >
                                {transaction.status}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>

            <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="transaction-modal-title"
      aria-describedby="transaction-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="transaction-modal-title" variant="h6" component="h2" sx={{fontWeight:'bold'}}>
          Transaction Details
        </Typography>
        <Typography id="transaction-modal-description" sx={{ mt: 2 }}>
          <strong>Order ID:</strong> {selectedTransaction?.id}
        </Typography>

        <Typography><strong>Status:</strong> {selectedTransaction?.status}</Typography>
        <Typography><strong>Amount:</strong> {selectedTransaction?.amount}</Typography>
        <Typography><strong>Date:</strong> {convertTimestampToDate(selectedTransaction?.created_at)}</Typography>

        {selectedTransaction?.status === 'authorized' && !captureSuccess && (
          <Button
            variant="contained"
            sx={{ mt: 2, backgroundColor: '#3f51b5', color: 'white' }}
            onClick={handleCaptureClick}
          >
            Capture
          </Button>
        )}

       
        {captureSuccess && (
          <Typography sx={{ mt: 2, color: 'green' }}>
            Successfully Captured!
          </Typography>
        )}
      </Box>
    </Modal>
            <Grid item xs={12} md={4}>
            <Card>
                <CardContent>
                  <Typography variant="h6" component="div">
                    Most Purchased Products
                  </Typography>
                  <Bar data={mostPurchasedProductsData} />
                </CardContent>
              </Card>
              <Card sx={{ mt: 2 }}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    Present Discount
                  </Typography>
                  <Typography variant="h4" component="div" sx={{ mt: 2 }}>
                    40%
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ mt: 2 }}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    Total Users
                  </Typography>
                  <Typography variant="h4" component="div" sx={{ mt: 2 }}>
                    200
                  </Typography>
                </CardContent>
              </Card>
              
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardContent;
