export const urls=(type,productName = '')=>{ 
    switch (type) {
        case 'login':
            return '/api/v1/auth/login/'
        case 'register':
            return '/api/v1/auth/register/'   
        case 'addAddress':
            return '/api/v1/auth/add_address/'
        case 'allProductsData':
            return '/api/v1/auth/getData/'
        case 'bulkuplaod':
            return '/api/v1/auth/uploadbulk/'
        case 'addproduct':
            return '/api/v1/auth/addproduct/'
        case 'sendmessage':
            return '/api/v1/auth/sendmessage/'
        case 'productimages':
            return '/api/v1/auth/productimages/'
        case 'test':
            return '/api/v1/auth/test/'
        case 'allorders':
            return '/api/v1/auth/userorders/'
        case 'createorderid':
            return '/api/v1/auth/orderid/'
        case 'capturepayment':
            return '/api/v1/auth/capturepayment'
        case 'trackorder':
            return '/api/v1/auth/trackorder'
        case 'payementsuccess':
            return '/api/v1/auth/payementsuccess/'
        case 'purchasedorders':
            return '/api/v1/auth/purchasedorders/'
        case 'productData':
            // return `api/v1/auth/productData/${productName}/'}`
            return `/api/v1/auth/productData/`;

        // Admin Dashboard
        case 'getusersdata':
            return '/api/v1/auth/getallusers/'
        case 'bannersupload':
            return '/api/v1/auth/bannersupload/'
        
     
        
        case 'allpayments':
            return '/api/v1/auth/allpayments/'
        case 'createorderid':
            return 'api/v1/auth/orderid/'

        case 'capturepayment':
            return '/api/v1/auth/capturepayment'

        // Admin Dashboard
        case 'allpayments':
            return '/api/v1/auth/allpayments/'
        case 'overallstats':
            return '/api/v1/auth/overallstats/'

        default:
            break;
    }
    
   

}