export const emailValidation=(emailText)=>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(emailText);

}

export const passwordValidation=(passwordText)=>{
    const minLengthRegex = /.{8,}/;   
    return minLengthRegex.test(passwordText)
}

export const phoneNumberValidation=(phoneNumber)=>{
    // const phoneNumberRegex = /^\+(\d{2})\d{10}$|^\+91\d{10}$/;
    const phoneNumberRegex = /^\+?\d{10}$/;
    return phoneNumberRegex.test(phoneNumber);
}