export default  function convertTimestampToDate(timestamp) {
    // Create a new Date object from the timestamp (in milliseconds)
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

    // Get the year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    // Format to yyyy-mm-dd
    return `${year}-${month}-${day}`;

}

export  function convertTimestampTofivedays(timestamp,dayCount){
    // Given timestamp


// Convert the timestamp to milliseconds (JavaScript timestamps are in milliseconds)
// let date = new Date(timestamp * 1000);

// // Add 5 days (5 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
// date.setTime(date.getTime() + (5 * 24 * 60 * 60 * 1000));

// // Format the new date to a readable format (YYYY-MM-DD HH:MM:SS)
// let formattedDate = date.getFullYear() + '-' + 
//                     ('0' + (date.getMonth() + 1)).slice(-2) + '-' + 
//                     ('0' + date.getDate()).slice(-2) + ' ' + 
//                     ('0' + date.getHours()).slice(-2) + ':' + 
//                     ('0' + date.getMinutes()).slice(-2) + ':' + 
//                     ('0' + date.getSeconds()).slice(-2);

// console.log("formattedDate",formattedDate);
// return formattedDate


    // Convert the timestamp to milliseconds (JavaScript timestamps are in milliseconds)
    let date = new Date(timestamp * 1000);

    // Add the specified number of days
    date.setTime(date.getTime() + (dayCount * 24 * 60 * 60 * 1000));

    // Format the new date to a readable format (YYYY-MM-DD HH:MM:SS)
    let formattedDate = date.getFullYear() + '-' + 
                        ('0' + (date.getMonth() + 1)).slice(-2) + '-' + 
                        ('0' + date.getDate()).slice(-2) + ' ' + 
                        ('0' + date.getHours()).slice(-2) + ':' + 
                        ('0' + date.getMinutes()).slice(-2) + ':' + 
                        ('0' + date.getSeconds()).slice(-2);

    console.log("formattedDate", formattedDate);
    return formattedDate; // Returning the formatted date



}




 
export function getCurrentGreeting(){
    const now = new Date();
    const hours = now.getHours();

    if (hours < 12) {
      return "Good morning";
    } else if (hours < 18) {
      return "Good afternoon";
    } else {
      return "Good night";
    }

  };

  export function getDate(){
    const now = new Date();
    
    // Format the date
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = now.toLocaleDateString('en-US', options);
    
    // Get the time
    const formattedTime = now.toLocaleTimeString('en-US', { hour12: false });
    
    return { formattedDate, formattedTime };
  }








