// import React, { useRef, useState } from 'react';
// import { Box, Typography, Card, Input, Tab, Tabs, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, IconButton, Collapse } from '@mui/material';
// import { CheckCircle, Cancel, HourglassBottom, Print as PrintIcon, MoreVert as MoreVertIcon, ExpandMore, ExpandLess } from '@mui/icons-material';

// interface Order {
//   id: string;
//   created: string;
//   total: number;
//   payment: string;
//   status: 'Completed' | 'Pending' | 'Canceled';
//   items: {
//     sku: string;
//     name: string;
//     price: number;
//     qty: number;
//     discount: number;
//   }[];
// }

// interface CustomerManagementDetailsProps {
//     orderId: string; 
// }

// const ordersData: Order[] = [
//   {
//     id: '#6548',
//     created: '2 min ago',
//     total: 654,
//     payment: 'CC',
//     status: 'Completed',
//     items: [
//       { sku: 'Lightset1', name: 'Lightset1', price: 999.29, qty: 1, discount: 5 },
//       { sku: 'Lightset2', name: 'Lightset2', price: 999.29, qty: 1, discount: 5 },
//       { sku: 'Lightset3', name: 'Lightset3', price: 999.29, qty: 1, discount: 5 },
//     ],
//   },
//   {
//     id: '#6549',
//     created: '3 min ago',
//     total: 654,
//     payment: 'COD',
//     status: 'Canceled',
//     items: [
//       { sku: 'Lightset1', name: 'Lightset1', price: 999.29, qty: 1, discount: 5 },
//       { sku: 'Lightset2', name: 'Lightset2', price: 999.29, qty: 1, discount: 5 },
//       { sku: 'Lightset3', name: 'Lightset3', price: 999.29, qty: 1, discount: 5 },
//     ],
//   },
//   {
//     id: '#6550',
//     created: '4 min ago',
//     total: 654,
//     payment: 'CC',
//     status: 'Pending',
//     items: [
//       { sku: 'Lightset1', name: 'Lightset1', price: 999.29, qty: 1, discount: 5 },
//       { sku: 'Lightset2', name: 'Lightset2', price: 999.29, qty: 1, discount: 5 },
//       { sku: 'Lightset3', name: 'Lightset3', price: 999.29, qty: 1, discount: 5 },
//     ],
//   },
// ];

// const OrderStatusIcon = ({ status }: { status: Order['status'] }) => {
//   switch (status) {
//     case 'Completed':
//       return <CheckCircle color="success" />;
//     case 'Canceled':
//       return <Cancel color="error" />;
//     case 'Pending':
//       return <HourglassBottom color="warning" />;
//     default:
//       return null;
//   }
// };


// const CustomerManagementDetails: React.FC<CustomerManagementDetailsProps> = ({ orderId }) => {
//   const [tab, setTab] = useState<string>('All Orders');
//   const [openOrderId, setOpenOrderId] = useState<string | null>(null);
//   const printRef = useRef<HTMLDivElement | null>(null);

//   const handleRowClick = (orderId: string) => {
//     setOpenOrderId(openOrderId === orderId ? null : orderId);
//   };
//   const handlePrint = () => {
//     if (printRef.current) {
//       const printContent = printRef.current.innerHTML;
//       const originalContent = document.body.innerHTML;
      
  
//       document.body.innerHTML = printContent;
//       window.print();
  
//       // After printing, restore the original content
//       document.body.innerHTML = originalContent;
//       window.location.reload(); // Reload to restore the React component state
//     }
//   };

//   return (
//     <Box sx={{ padding: 2 }}>
//       {/* Profile Information Section */}
//       <Card sx={{ padding: 3, display: 'flex', justifyContent: 'space-between', mb: 2 }}>
//         <Box>
//           <Typography variant="h6" fontWeight="bold">Robert Fox</Typography>
//           <Typography color="textSecondary">robert@gmail.com</Typography>
//         </Box>
//         <Box>
//           <Typography color="textSecondary">Contact Number: (201) 555-0124</Typography>
//           <Typography color="textSecondary">Gender: Male</Typography>
//           <Typography color="textSecondary">Date of Birth: 1 Jan, 1985</Typography>
//           <Typography color="textSecondary">Member Since: 3 March, 2023</Typography>
//         </Box>
//         <Box>
//           <Typography color="textSecondary">Shipping Address:</Typography>
//           <Typography>3517 W. Gray St. Utica, Pennsylvania 57867</Typography>
//           <Typography color="textSecondary">Total Orders: 150</Typography>
//           <Typography color="textSecondary">Completed: 140</Typography>
//           <Typography color="textSecondary">Canceled: 10</Typography>
//         </Box>
//       </Card>

//       {/* Tabs for Order Status */}
//       <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} sx={{ marginBottom: 2 }}>
//         <Tab label="All Orders" value="All Orders" />
//         <Tab label="Completed" value="Completed" />
//         <Tab label="Canceled" value="Canceled" />
//       </Tabs>

//       {/* Search Input and Date Filter */}
//       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
//         <Input placeholder="Search by order id" sx={{ width: '50%' }} />
//         <Button variant="outlined" sx={{ ml: 2 }}>Filter by date range</Button>
//       </Box>

//       {/* Order Table */}
//       <TableContainer component={Card}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Order ID</TableCell>
//               <TableCell>Created</TableCell>
//               <TableCell>Total</TableCell>
//               <TableCell>Payment</TableCell>
//               <TableCell>Status</TableCell>
//               <TableCell></TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {ordersData.map((order) => (
//               <>
//                 <TableRow key={order.id}>
//                   <TableCell>{order.id}</TableCell>
//                   <TableCell>{order.created}</TableCell>
//                   <TableCell>${order.total}</TableCell>
//                   <TableCell>{order.payment}</TableCell>
//                   <TableCell>
//                     <OrderStatusIcon status={order.status} /> {order.status}
//                   </TableCell>
//                   <TableCell>
//                     <IconButton onClick={() => handleRowClick(order.id)}>
//                       {openOrderId === order.id ? <ExpandLess /> : <ExpandMore />}
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>

//                 {/* Collapsible Order Details Row */}
//                 <TableRow>
//                   <TableCell colSpan={6}>
//                     <Collapse in={openOrderId === order.id} timeout="auto" unmountOnExit>
//                       <Table size="small">
//                         <TableHead>
//                           <TableRow>
//                             <TableCell>SKU</TableCell>
//                             <TableCell>Name</TableCell>
//                             <TableCell>Price</TableCell>
//                             <TableCell>Qty</TableCell>
//                             <TableCell>Discount</TableCell>
//                             <TableCell>Total</TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {order.items.map((item, index) => (
//                             <TableRow key={index}>
//                               <TableCell>{item.sku}</TableCell>
//                               <TableCell>{item.name}</TableCell>
//                               <TableCell>${item.price}</TableCell>
//                               <TableCell>{item.qty}</TableCell>
//                               <TableCell>{item.discount}%</TableCell>
//                               <TableCell>${(item.price - (item.price * item.discount) / 100).toFixed(2)}</TableCell>
//                             </TableRow>
//                           ))}
//                         </TableBody>
//                       </Table>
//                       <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
//                         <Typography>Subtotal: $2,847.96</Typography>
//                         <Typography>Shipping: $5.50</Typography>
//                         <Typography>Discount: $150.32</Typography>
//                         <Typography fontWeight="bold">Total: $2,647.32</Typography>
//                         <Button startIcon={<PrintIcon />} onClick={handlePrint}>
//                         Print
//                       </Button>
//                       </Box>
//                     </Collapse>
//                   </TableCell>
//                 </TableRow>
//               </>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Box>
//   );
// };

// export default CustomerManagementDetails;


import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Card, Input, Tab, Tabs, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, IconButton, Collapse, Modal, TextField, Rating } from '@mui/material';
import { CheckCircle, Cancel, HourglassBottom, Print as PrintIcon, ExpandMore, ExpandLess, CloudUpload } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import { urls } from '../../../../urls/urls';
import { useFetch } from '../../../../hooks/useFetch';
import { BASE_URL_Test } from '../../../../utils/baseUrl';
interface Order {
  id: string;
  created: string;
  total: number;
  payment: string;
  status: 'Completed' | 'Pending' | 'Canceled';
  items: {
    sku: string;
    name: string;
    price: number;
    qty: number;
    discount: number;
  }[];
}

interface UserAddressProps{
  id?: number,
  user_id?: number,
  firstname?: string,
  lastname?: string,
  companyname?: string,
  street1?: string,
  street2?: string,
  phone?: string,
  city_town?: string,
  state?: string,
  postal_code?: string,
  country?: string
}

interface UserOrdersProps {
  id: number;
  orderid: string;
  email: string;
  name: string;
  trackingno: string;
  created: string; // You might want to consider using a Date type instead.
  total: string; // If it's a number, change this to `number`.
  profit: string; // Same here; if it's a number, change to `number`.
  status: string;
  orderstatus: string;
  receiptno: string;
}


interface CustomerManagementDetailsProps {
  orderId: string;
}



const ordersData: Order[] = [
  {
    id: '#6548',
    created: '2 min ago',
    total: 654,
    payment: 'CC',
    status: 'Completed',
    items: [
      { sku: 'Lightset1', name: 'Lightset1', price: 999.29, qty: 1, discount: 5 },
      { sku: 'Lightset2', name: 'Lightset2', price: 999.29, qty: 1, discount: 5 },
      { sku: 'Lightset3', name: 'Lightset3', price: 999.29, qty: 1, discount: 5 },
    ],
  },
  {
    id: '#6549',
    created: '3 min ago',
    total: 654,
    payment: 'COD',
    status: 'Canceled',
    items: [
      { sku: 'Lightset1', name: 'Lightset1', price: 999.29, qty: 1, discount: 5 },
      { sku: 'Lightset2', name: 'Lightset2', price: 999.29, qty: 1, discount: 5 },
      { sku: 'Lightset3', name: 'Lightset3', price: 999.29, qty: 1, discount: 5 },
    ],
  },
  {
    id: '#6550',
    created: '4 min ago',
    total: 654,
    payment: 'CC',
    status: 'Pending',
    items: [
      { sku: 'Lightset1', name: 'Lightset1', price: 999.29, qty: 1, discount: 5 },
      { sku: 'Lightset2', name: 'Lightset2', price: 999.29, qty: 1, discount: 5 },
      { sku: 'Lightset3', name: 'Lightset3', price: 999.29, qty: 1, discount: 5 },
    ],
  },
];






const OrderStatusIcon = ({ status }: { status: Order['status'] }) => {
  switch (status) {
    case 'Completed':
      return <CheckCircle color="success" />;
    case 'Canceled':
      return <Cancel color="error" />;
    case 'Pending':
      return <HourglassBottom color="warning" />;
    default:
      return null;
  }
};

const CustomerManagementDetails: React.FC<CustomerManagementDetailsProps> = ({ orderId }) => {
  const [tab, setTab] = useState<string>('All Orders');
  console.log("orderId",orderId)
  const [openOrderId, setOpenOrderId] = useState<string | null>(null);
  const printRef = useRef<HTMLDivElement | null>(null);
  const [completeuserData,setCompleteUserData] = useState();
  const [userCommpletAddress, setUserAddress] = useState<UserAddressProps>({});
  // const [userOrders,setUserOrders] = useState();
  const [userOrders, setUserOrders] = useState<UserOrdersProps[]>([]);

  const {data:CustomersData,error:CustomersError,isLoading:CustomerLoading,fetchData:CustomersDataFetch}=useFetch(BASE_URL_Test+urls('getusersdata'),{
    method:"GET",
    headers: { 'Content-Type': 'application/json'}

  })


  // useEffect(()=>{
  //   const requestOptions:RequestInit = {
  //     method: "GET",
  //     redirect: "follow"
  //   };
    
  //   fetch(`http://127.0.0.1:9000/api/v1/auth/getallusers/${orderId}`, requestOptions)
  //     .then((response) => response.text())
  //     .then((result) => setCompleteUserData(result))
  //     .catch((error) => console.error(error));
  // },[orderId])


  useEffect(() => {
    const requestOptions: RequestInit = {
        method: "GET",
        redirect: "follow"
    };

    fetch(`${BASE_URL_Test}api/v1/auth/getallusers/${orderId}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json(); // Parse response as JSON
        })
        .then((result) => {
          if(result['status']=='success')
          {
            if(result['data']&& result['data']['user'])
            {
              setCompleteUserData(result['data']['user'])
            }
            if(result['data']&& result['data']['address'])
            {
              setUserAddress(result['data']['address'][0])
              console.log("useraddress",userCommpletAddress['companyname'])
            }
            if(result['data']&& result['data']['orders'])
              {
                setUserOrders(result['data']['orders'])
              }
            
          }
         
        }) // Assuming result is the correct type
        .catch((error) => console.error(error));
}, [orderId]);


  useEffect(()=>{
    if(CustomersError)
    {

    }
    else if(CustomersData && CustomersData['status']==='success')
    {
      console.log("CustomersDataorderid",CustomersData)
      //  setCustomersAllData(CustomersData['data'])
    }

  },[CustomersData,CustomersError])


  const handleRowClick = (orderId: string) => {
    setOpenOrderId(openOrderId === orderId ? null : orderId);
  };

  const handlePrint = () => {
    if (printRef.current) {
      window.print();
    }
  };
  const [open, setOpen] = useState(false);
 

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

 

  useEffect(() => {
    
    if (open) {
      setIsSubmitted(false);
      setRating(0);
      setReview('');
      setCharCount(0);
      setUploadedFile(null);
    }
  }, [open]);

 

  const handleSubmitReview = () => {
   
    setIsSubmitted(true);
  
  
    const submissionData = {
      rating: rating,
      review: review,
      uploadedFile: uploadedFile ? uploadedFile.name : null,  
    };
  
   
    console.log(JSON.stringify(submissionData, null, 2));
  
    
    setTimeout(() => {
      handleClose();  
    }, 5000);
  };
  

const handleReviewChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  setReview(event.target.value);
  setCharCount(event.target.value.length);
};
const handleFileRemove = () => {
  setUploadedFile(null);
};

// console.log("useraddres",userAddresses[0]['companyname'])
  return (
    <Box sx={{ padding: 2 }}>
      
      <Card sx={{ padding: 3, display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box>
          <Typography variant="h6" fontWeight="bold">{ completeuserData   ?completeuserData[0]['firstname']:''}</Typography>
          <Typography color="textSecondary">{ completeuserData   ?completeuserData[0]['email']:''}</Typography>
        </Box>
        <Box>
          <Typography color="textSecondary">Contact Number: { completeuserData   ?completeuserData[0]['phone']:''}</Typography>
          <Typography color="textSecondary">Gender: -</Typography>
          <Typography color="textSecondary">Date of Birth: -</Typography>
          <Typography color="textSecondary">Member Since: -</Typography>
        </Box>
        <Box>
          {/* { userAddresses   ?userAddresses[0]['companyname']:''} */}
          <Typography color="textSecondary">Shipping Address:</Typography>
          <Typography>{userCommpletAddress && userCommpletAddress.companyname ? userCommpletAddress.companyname : 'Company name not available'}</Typography>

          <Typography>{ userCommpletAddress && userCommpletAddress['street1']}</Typography>
          <Typography>{ userCommpletAddress && userCommpletAddress['street2']}</Typography>
          <Typography>{ userCommpletAddress && userCommpletAddress['city_town']}</Typography>
          <Typography>{ userCommpletAddress && userCommpletAddress['companyname']}</Typography>
          <Typography>{ userCommpletAddress && userCommpletAddress['state']}</Typography>
          <Typography>{ userCommpletAddress && userCommpletAddress['postal_code']}</Typography>
          <Typography>{ userCommpletAddress && userCommpletAddress['country']}</Typography>
          <Typography color="textSecondary">Total Orders: { userOrders && userOrders.length}</Typography>
          <Typography color="textSecondary">Completed: 140</Typography>
          <Typography color="textSecondary">Canceled: 10</Typography>
        </Box>
        <Button 
  variant="contained" 
  onClick={handleOpen} 
  sx={{ 
    height: '30px', 
    backgroundColor: '#4CAF50', 
    color: 'white',  
    '&:hover': { backgroundColor: '#45A049' }  
  }}
>
  Rate & Reviews
</Button>

      </Card>
      <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          maxHeight: '90vh', 
          overflowY: 'auto',  
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '10px',
        }}
      >
       
        {!isSubmitted && (
          <IconButton
            sx={{ position: 'absolute', top: 8, right: 8 }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        )}

        {isSubmitted ? (
          <Box
            sx={{
              textAlign: 'center',
              color: '#4caf50',
              animation: 'fadeIn 1s ease-in-out',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display: 'inline-block',
                background: 'radial-gradient(circle, rgba(76,175,80,0.5) 20%, transparent 80%)',
                borderRadius: '50%',
                padding: '20px',
                animation: 'pulseBackground 2s infinite',
              }}
            >
              <CheckCircle
                sx={{
                  fontSize: '130px',
                  animation: 'scaleUp 0.6s ease-in-out',
                  position: 'relative',
                  zIndex: 1,
                }}
              />
            </Box>

            <Typography
              variant="h6"
              component="h2"
              sx={{
                mt: 2,
                fontWeight: 'bold',
                fontSize: '24px',
              }}
            >
              Review Recorded Successfully!
            </Typography>

            <style>
              {`
                @keyframes scaleUp {
                  0% {
                    transform: scale(0);
                  }
                  50% {
                    transform: scale(1.2);
                  }
                  100% {
                    transform: scale(1);
                  }
                }

                @keyframes pulseBackground {
                  0% {
                    transform: scale(1);
                    opacity: 1;
                  }
                  50% {
                    transform: scale(1.1);
                    opacity: 0.8;
                  }
                  100% {
                    transform: scale(1);
                    opacity: 1;
                  }
                }

                @keyframes fadeIn {
                  0% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }
              `}
            </style>
          </Box>
        ) : (
          <>
            <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
              Rate and Reviews
            </Typography>

            <Box sx={{ mb: 3 }}>
              <Typography color="textSecondary" sx={{ mb: 1 }}>
                Add Photo or Video
              </Typography>
              {uploadedFile ? (
                <Box
                  sx={{
                    position: 'relative',
                    display: 'inline-block',
                    width: '100%',
                    borderRadius: '8px',
                    overflow: 'hidden',
                  }}
                >
                  {uploadedFile.type.startsWith('image/') && (
                    <img
                      src={URL.createObjectURL(uploadedFile)}
                      alt="Uploaded"
                      style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '8px',
                      }}
                    />
                  )}
                  {uploadedFile.type.startsWith('video/') && (
                    <video
                      src={URL.createObjectURL(uploadedFile)}
                      controls
                      style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '8px',
                      }}
                    />
                  )}
                  
                  <IconButton
                    sx={{ position: 'absolute', top: 8, right: 8, color: 'white' }}
                    onClick={handleFileRemove}
                  >
                    <Close />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '2px dashed #c4c4c4',
                    padding: 2,
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                >
                  <IconButton color="primary" component="label">
                    <CloudUpload fontSize="large" />
                    <input
                      type="file"
                      hidden
                      onChange={(event) => {
                        const file = event.target.files?.[0] ?? null;
                        setUploadedFile(file);
                      }}
                    />
                  </IconButton>
                  <Typography sx={{ ml: 2 }}>Upload Media</Typography>
                </Box>
              )}
            </Box>

            <Typography color="textSecondary" sx={{ mb: 1 }}>
              Rate this Product
            </Typography>
            <Rating
              name="simple-controlled"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue ?? 0);
              }}
              precision={0.5}
              size="large"
              sx={{ mb: 3 }}
            />

            <Typography color="textSecondary" sx={{ mb: 1 }}>
              Post your Review
            </Typography>
            <TextField
              variant="outlined"
              multiline
              rows={4}
              placeholder="Have any feedback you'd like to give about this product?"
              fullWidth
              value={review}
              onChange={handleReviewChange}
              helperText={`${charCount}/250`}
              inputProps={{ maxLength: 250 }}
            />

            <Button
              variant="contained"
              onClick={handleSubmitReview}
              sx={{ mt: 3, backgroundColor: '#4F46E5' }}
              fullWidth
            >
              Submit Review
            </Button>
          </>
        )}
      </Box>
    </Modal>
      <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} sx={{ marginBottom: 2 }}>
        <Tab label="All Orders" value="All Orders" />
        <Tab label="Completed" value="Completed" />
        <Tab label="Canceled" value="Canceled" />
      </Tabs>

     
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Input placeholder="Search by order id" sx={{ width: '50%' }} />
        <Button variant="outlined" sx={{ ml: 2 }}>Filter by date range</Button>
      </Box>

      
      <TableContainer component={Card}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Payment</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersData.map((order) => (
              <>
                <TableRow key={order.id}>
                  <TableCell>{order.id}</TableCell>
                  <TableCell>{order.created}</TableCell>
                  <TableCell>${order.total}</TableCell>
                  <TableCell>{order.payment}</TableCell>
                  <TableCell>
                    <OrderStatusIcon status={order.status} /> {order.status}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleRowClick(order.id)}>
                      {openOrderId === order.id ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </TableCell>
                </TableRow>

              
                <TableRow>
                  <TableCell colSpan={6}>
                    <Collapse in={openOrderId === order.id} timeout="auto" unmountOnExit>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>SKU</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>Discount</TableCell>
                            <TableCell>Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order.items.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item.sku}</TableCell>
                              <TableCell>{item.name}</TableCell>
                              <TableCell>${item.price}</TableCell>
                              <TableCell>{item.qty}</TableCell>
                              <TableCell>{item.discount}%</TableCell>
                              <TableCell>${(item.price - (item.price * item.discount) / 100).toFixed(2)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        <Typography>Subtotal: $2,847.96</Typography>
                        <Typography>Shipping: $5.50</Typography>
                        <Typography>Discount: $150.32</Typography>
                        <Typography fontWeight="bold">Total: $2,647.32</Typography>
                        <Button startIcon={<PrintIcon />} onClick={handlePrint}>
                          Print
                        </Button>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    
      <div ref={printRef} style={{ display: 'none' }}>
   
        <h1>Order Details</h1>
        
      </div>
    </Box>
  );
};

export default CustomerManagementDetails;

