import React,{useEffect,useState} from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { Header, Footer } from './components';
import Home from './components/landingPage/Home';
import Login from './pages/Auth/Login/login';
import Register from './pages/Auth/Login/register';
import AdminLogin from './pages/Admin/Auth/Login/adminLogin';
import AdminDashboard from './pages/Admin/Auth/Dashboard/dashboard';
import OrderTracking from './components/payment/orderTracking';
import Checkout from './components/payment/checkoutPage';
import ProductDetails from './components/landingPage/product-details';

import ContactInfo from './components/ContactUs/contactus';
import CustomizeForm from './components/Customize/customize-form';

import ShopPage from './components/landingPage/categoryPage';

import { useFetch } from './hooks/useFetch';
import { BASE_URL,BASE_URL_Test } from './utils/baseUrl';
import { urls } from './urls/urls';
import Wishlist from './components/landingPage/wishlist';
import AccountDetails from './components/landingPage/account-details';
import OrderList from './components/landingPage/orders/order-list';
import Addresses from './components/landingPage/adresses';
import CategoryWiseProduct from './components/landingPage/CategoryWiseProducts';
import { HeaderResponsive } from './components/Header/header-responsive';



const App = () => {
  console.log("baseurl",BASE_URL_Test)
  const {data,isLoading,error,fetchData}=useFetch(BASE_URL_Test+urls('allProductsData'),{
    method:"GET",
    headers: { 'Content-Type': 'application/json' }
  })
  const [allProdData,setAllProdData]=useState<[]|null>([])
  const [allProdImages,setAllProdImages]=useState<[]|null>([])
  const [selectedCategory,setSelectedCategory]=useState("")

  useEffect(()=>{
    if(error)
    {

    }
    else if(data)
    {
      
      if(data['status']==='success')
      {
       console.log("alldata",data)
        setAllProdData(data['data'])
        setAllProdImages(data['images'])
      }
    }
  },[data,error,isLoading])
  
  useEffect(()=>{
    fetchData()
  },[])
  const location = useLocation();
  const handleLoginClick = () => {
    console.log("Login Clicked");
  }

  const handleSearchClick = () => {
    console.log("Search Clicked");
  }

  const handleCustomizeClick = () => {
    console.log("Customize Clicked");
  }

  const handleCategoryClick=(catergoryName:string)=>{
    setSelectedCategory(catergoryName)
  }

  const shouldShowHeader = !['/adminLogin', '/register', '/dashboardContent','/login','/dashboard',].includes(location.pathname);
  const shouldShowFooter = !['/adminLogin', '/register', '/dashboardContent','/login','/dashboard',].includes(location.pathname);

  return (
    <>
      {shouldShowHeader && (
        <Header
          logo={require('./assets/logo-10q.png')}
          onLoginClick={handleLoginClick}
          onSearchClick={handleSearchClick}
          onCustomizeClick={handleCustomizeClick}
          allProdData={allProdData}
          allProdImages={allProdImages}
        />
      )}
      {/* <HeaderResponsive  logo={require('./assets/logo-10q.png')}
          onLoginClick={handleLoginClick}
          onSearchClick={handleSearchClick}
          onCustomizeClick={handleCustomizeClick}
          allProdData={allProdData}
          allProdImages={allProdImages}/> */}
      <Routes>
        <Route path="/" element={<Home  prodData={allProdData} prodImages={allProdImages} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/adminLogin" element={<AdminLogin />} />
        <Route path="/dashboard" element={ <AdminDashboard/>} />
        <Route path="/order-tracking" element={<OrderTracking />} />
        <Route path="/checkoutpage" element={<Checkout />} />
        {/* <Route path="/productdetails/:HEDM9 DURGAI AMMAN HALF FACE 2x2 FEET 1330 HOLES" element={<ProductDetails />} /> */}
        {/* <Route path="/product/:productname" element={<ProductDetails />} /> */}
        <Route path="/product/:urlproductid/:productname" element={<ProductDetails />} />
        <Route path="/contactinfo" element={<ContactInfo />} />
        <Route path="/categories" element={<ShopPage />} />
        <Route path="/customize" element={<CustomizeForm />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/accountdetails" element={<AccountDetails />} />
        <Route path="/orders" element={<OrderList />} />
        <Route path="/addresses" element={<Addresses />} />
        <Route path="/categorywiseproduct" element={<CategoryWiseProduct selectedCategory={selectedCategory}  />} />

       
      </Routes>
      
      

      {shouldShowFooter && (  <Footer />)}
    
    </>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
