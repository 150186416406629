import React, { useEffect, useState } from 'react';
import { Typography, Box, IconButton, Avatar, AppBar, Toolbar, Button } from '@mui/material';
import { Notifications, CalendarToday, Add } from '@mui/icons-material';
import CustomDataCard from '../../../../components/Button/customDataCard';
import ProductForm from './productForm';
import { BASE_URL,BASE_URL_Test } from '../../../../utils/baseUrl';
import { urls } from '../../../../urls/urls';
import { getCurrentGreeting,getDate } from '../../../../utils/functionalOperations';

const ProductManagement: React.FC<{ showForm: boolean; setShowForm: (value: boolean) => void }> = ({
  showForm,
  setShowForm,
}) => {
  const [cardData, setCardData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { formattedDate, formattedTime } = getDate();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(BASE_URL_Test+urls('allProductsData'));
        const result = await response.json();
        
        if (result.status === 'success') {
          console.log("produccarddata",result)
          const keysToSkip = ['id', 'discount'];
          const mergedArray = result['data'].map((item1:any) => {
            const item2 = result['images'].find((item:any) => item.productid === item1.productid);
            const newItem = { ...item1 };
            if (item2) {
              Object.keys(item2).forEach(key => {
                  if (!keysToSkip.includes(key)) {
                      newItem[key] = item2[key];
                  }
              });
          }
          return newItem;
        
        });
        // console.log("mergedArray",mergedArray)
          setCardData(mergedArray); 
        } else {
          setError('Failed to load products');
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: '#EEF2FF', p: 3, overflowY: 'auto', display: 'flex', flexDirection: 'column', height: '100vh' }}
      >
        <AppBar position="static" elevation={0} sx={{ borderBottom: '1px solid #ddd', mb: 2, backgroundColor: 'white', color: 'black' }}>
          <Toolbar>
            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
             {getCurrentGreeting()}, 
            </Typography>
            <IconButton>
              <CalendarToday />
            </IconButton>
            <Typography variant="body1" sx={{ mx: 2 }}>
             {formattedDate} / {formattedTime}
            </Typography>
            <IconButton>
              <Notifications />
            </IconButton>
            <Avatar alt="Gn" src="/profile.webp" />
          </Toolbar>
        </AppBar>

        {showForm ? (
          <ProductForm onBack={() => setShowForm(false)} />
        ) : (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginY: 2 }}>
              <Button
                variant="contained"
                sx={{ backgroundColor: '#3f51b5' }}
                startIcon={<Add />}
                onClick={() => setShowForm(true)}
              >
                ADD NEW PRODUCT
              </Button>
            </Box>

            {loading ? (
              <Typography variant="h6">Loading...</Typography>
            ) : error ? (
              <Typography variant="h6" color="error">
                {error}
              </Typography>
            ) : (
              <Box 
                sx={{ 
                  display: 'flex', 
                  flexWrap: 'wrap', 
                  gap: 2, 
                  justifyContent: 'space-between', 
                  rowGap: 4,
                }}
              >
                {cardData && cardData.map((data) => (
                  <CustomDataCard
                    key={data.id}
                    title={data.productname}
                    summary={data.description}
                    price={data.price}
                    sales={data.sku} 
                    remaining={data.stock} 
                    image={data.images}
                    sx={{ width: '300px', flexBasis: '30%' }} 
                  />
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ProductManagement;
