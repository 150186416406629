import React, { useState } from 'react';
import { Stack, Typography, Box, RadioGroup, Button } from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';
import { FormControlLabel, IconButton, Modal, Radio as MuiRadio, Radio, TextField } from '@mui/material';
import YourOrder from './yourOrder';
import { Add, Close } from '@mui/icons-material';
import AddressModal from './mapModal';

interface ShippingMethodOption {
  label: string;
  description: string;
  price: string;
  
}

interface ShippingMethodProps {
  activeStep: number; // Receive activeStep as a prop
}

const ShippingMethod: React.FC<ShippingMethodProps> = ({ activeStep }) => {
  const [selectedMethod, setSelectedMethod] = useState<string>('express');
  

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMethod(event.target.value);
  };


  const shippingOptions: ShippingMethodOption[] = [
    { label: 'Free Shipping', description: '7-30 business days', price: '$0' },
    { label: 'Regular Shipping', description: '3-14 business days', price: '$7.50' },
    { label: 'Express Shipping', description: '1-3 business days', price: '$22.50' },
  ];

    function setIsCheckout(arg0: boolean): void {
        throw new Error('Function not implemented.');
    }
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState('HubSpot, 25 First Street, Cambridge MA 02141, United States');
    const handleConfirm = (location: string) => {
      setSelectedLocation(location);
      setModalOpen(false);
    };
    const handleOpenModal = () => {
      setOpenModal(true);
    };
  
    const handleCloseModal = () => {
      setOpenModal(false);
    };
  
    const handleOpenEditModal = () => {
      setOpenEditModal(true);
    };
  
    const handleCloseEditModal = () => {
      setOpenEditModal(false);
    };
    const [cardNumber, setCardNumber] = useState('');

    const handleCardNumberChange = (event: { target: { value: string; }; }) => {
      let value = event.target.value.replace(/\D/g, ''); 
      value = value.match(/.{1,4}/g)?.join('-') || ''; 
      setCardNumber(value);
    };

  return (
    <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
      
      
      <YourOrder activeStep={activeStep} />
      <AddressModal open={isModalOpen} onClose={() => setModalOpen(false)}  onConfirm={handleConfirm}/>
    </Stack>
  );
};

export default ShippingMethod;
