import { useState } from 'react';
import { Box, Typography } from '@mui/joy';
import { ShoppingCart, Dashboard, People, BarChart, Layers, Settings } from '@mui/icons-material';
import DashboardContent from './dashboardContent';

import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ProductManagement from './productManagement';
import OrderManagement from './orderManagement';
import CustomerMnagement from './customerManagement';
import SettingsPage from './settings-page';


const Analytics = () => <div>Analytics Content</div>;
const Transactions = () => <div>Transactions Content</div>;
const SettingsConfiguration = () => <div>Settings & Configuration Content</div>;

const AdminDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState('Dashboard');
  const [showProductForm, setShowProductForm] = useState(false);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    if (tab !== 'Product Management') {
      setShowProductForm(false); 
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'Dashboard':
        return <DashboardContent />;
      case 'Product Management':
        return <ProductManagement showForm={showProductForm} setShowForm={setShowProductForm} />;
      case 'Order Management':
        return <OrderManagement />;
      case 'Customer Management':
        return <CustomerMnagement/>;
      // case 'Analytics':
      //   return <Analytics />;
      // case 'Transactions':
      //   return <Transactions />;
      case 'Settings & Configuration':
        return <SettingsPage />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Box sx={{ width: '250px', backgroundColor: '#fff', boxShadow: 1, overflowY: 'auto', p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <img src="/logo-10q.png" alt="Hero Electro" style={{ width: '70px' }} />
          <Typography sx={{ ml: 2, fontWeight: 'bold', fontSize: '1.2rem' }}>
            HERO ELECTRO
          </Typography>
        </Box>
        <List>
          {[
            'Dashboard',
            'Product Management',
            'Order Management',
            'Customer Management',
            // 'Analytics',
            // 'Transactions',
            'Settings & Configuration',
          ].map((text) => (
            <ListItem
              button
              key={text}
              onClick={() => handleTabClick(text)}
              sx={{
                backgroundColor: activeTab === text ? '#EEF2FF' : 'transparent',
                color: activeTab === text ? '#3f51b5' : 'inherit',
                '&:hover': {
                  backgroundColor: '#EEF2FF',
                  color: '#3f51b5',
                },
              }}
            >
              <ListItemIcon sx={{ color: activeTab === text ? '#3f51b5' : 'inherit' }}>
                {text === 'Dashboard' && <Dashboard />}
                {text === 'Product Management' && <ShoppingCart />}
                {text === 'Order Management' && <ShoppingCart />}
                {text === 'Customer Management' && <People />}
                {text === 'Analytics' && <BarChart />}
                {text === 'Transactions' && <Layers />}
                {text === 'Settings & Configuration' && <Settings />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box sx={{ flex: 1, overflowY: 'auto' }}>
        {renderContent()}
      </Box>
    </Box>
  );
};

export default AdminDashboard;
