import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, IconButton, Divider, LinearProgress, SxProps, Modal, Button } from '@mui/material';
import { ArrowUpward, MoreVert } from '@mui/icons-material';
import { RoundNumber } from '../../utils';
import { BASE_URL_Test } from '../../utils/baseUrl';

interface CustomDataCardProps {
  title: string;
  summary: string;
  price: string;
  sales: number;
  remaining: number | string;
  image: string;
  sx?: SxProps; 
}

const CustomDataCard: React.FC<CustomDataCardProps> = ({ title, summary, price, sales, remaining, image, sx }) => {
  const [openModal, setOpenModal] = useState(false);
  

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleEdit=()=>{
console.log("edi clicked item",sales)
  }

  return (
    <>
      <Card sx={{ width: 360, height: 360, margin: 2, borderRadius: 2, boxShadow: 3, overflow: 'hidden' }}>
        <CardContent sx={{ padding: 1, height: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: 2 }}>
            <CardMedia
              component="img"
              sx={{ width: 80, height: 80, borderRadius: 1 }}
              image={`${BASE_URL_Test}api/v1/auth/media/` + image}
              alt={title}
            />
            <Box sx={{ flex: 1, marginLeft: 2 }}>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  marginBottom: 1,
                  whiteSpace: 'normal',   
                  wordBreak: 'break-word' 
                }}>
                {title}
              </Typography>
            </Box>
            <IconButton onClick={handleOpenModal}>
              <MoreVert />
            </IconButton>
          </Box>

          <Typography sx={{ fontSize: 20, fontWeight: 'bold', marginBottom: 1 }}>
          ₹ {RoundNumber(price)}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
            Summary
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
            {summary}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Typography variant="body2" color="text.secondary">
                Sales
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', color: 'orange' }}>
                <ArrowUpward fontSize="small" />
                <Typography variant="body2" sx={{ marginLeft: 0.5 }}>{sales}</Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="body2" color="text.secondary">
                Remaining Products
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LinearProgress
                  variant="determinate"
                  value={Number(remaining) / (sales + Number(remaining)) * 100}
                  sx={{ width: 50, height: 8, borderRadius: 5, marginRight: 0.5, bgcolor: 'lightgray', '& .MuiLinearProgress-bar': { bgcolor: '#FFA52F' } }}
                />
                <Typography variant="body2">{Number(remaining).toFixed(2)}</Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>

      
              <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 300,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              borderRadius: 2
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2" sx={{ marginBottom: 2 }}>
              Edit/Delete Options
            </Typography>
            <Typography id="modal-description" variant="body2" color="text.secondary" sx={{ marginBottom: 3 }}>
              Are you sure you want to perform this action? Please select an option below:
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" color="primary" sx={{ flex: 1, marginRight: 1 }} onClick={handleEdit}>
                Edit
              </Button>
              <Button variant="contained" color="error" sx={{ flex: 1, marginRight: 1 }}>
                Delete
              </Button>
              <Button variant="outlined" sx={{ flex: 1 }} onClick={handleCloseModal}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>

    </>
  );
};

export default CustomDataCard;
