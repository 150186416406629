// export const BASE_URL='http://127.0.0.1:9000/'  #localhost testing url
// export const BASE_URL='https://heroelectro.onrender.com/' 
export const BASE_URL='https://heroelectroapi-production.up.railway.app/'    //Prod

// export const BASE_URL_Test='https://api.heroelectro.com/'    //TEST
// export const BASE_URL_Test='https://www.heroelectro.com/'    //TEST

// export const BASE_URL_Test=process.env.REACT_APP_PROD_URL    //PROD
export const BASE_URL_Test='https://api.heroelectro.com'    //PROD
// export const BASE_URL_Test='http://127.0.0.1:9000'    //Test

