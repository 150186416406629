import React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { SvgIconProps } from '@mui/material/SvgIcon';

interface SocialMediaIconProps {
  IconComponent: React.ElementType<SvgIconProps> | string; 
  label: string;
  color?: string;
  
}

const SocialMediaIcon: React.FC<SocialMediaIconProps> = ({ IconComponent, label, color = '#000000' }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
      {typeof IconComponent === 'string' ? (
        <img src={IconComponent} alt={label} style={{ width: '16px', height: '16px', marginRight: '8px' }} />
      ) : (
        <IconComponent style={{ color, fontSize: '16px', marginRight: '8px' }} />
      )}
      <Typography sx={{ color, fontSize: 13 }}>
        {label}
      </Typography>
    </Box>
  );
};

export default SocialMediaIcon;
