// import { createStore,applyMiddleware } from "redux";
// import { thunk } from "redux-thunk";

// import UserReducer from "./reducers/userReducers";


// const store =()=>{
//      // Apply middleware like thunk
//   const middleware = applyMiddleware(thunk);
//   // Create and return the store
//   return createStore(UserReducer, middleware);
// }
// export type RootState = ReturnType<typeof store.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch

// export default store;

// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import UserReducer from "./reducers/userReducers";

// // Create the store with middleware
// const store = createStore(UserReducer, applyMiddleware(thunk));

// // Define types for RootState and AppDispatch
// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;

// export default store;


// Second version

// import {createStore, combineReducers, applyMiddleware } from 'redux';

// import { thunk } from 'redux-thunk';


// import UserReducer from './reducers/userReducers';

// const rootReducer = combineReducers({
//   user: UserReducer,
// });

// const configureStore = () => {
//   return createStore(rootReducer, applyMiddleware(thunk));
// };

// export type RootState = ReturnType<typeof rootReducer>;
// export default configureStore;


// store.ts
// store.ts
// import { configureStore } from '@reduxjs/toolkit';
// import UserReducer from './reducers/userReducers'; // Adjust the path as necessary

// // Configure the store
// const store = configureStore({
//   reducer: {
//     user: UserReducer,
//   },
// });

// // Define the RootState type for TypeScript
// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch; // Optional: Define dispatch type

// export default store;


// store.ts
import { combineReducers, configureStore } from '@reduxjs/toolkit'

import userReducers from './reducers/userReducers'
// import storage from 'redux-persist'
import storage from 'redux-persist/lib/storage'; // Use this for localStorage

// import {persistReducer} from 'redux-persist/es/persistReducer'
import persistReducer from 'redux-persist/es/persistReducer'


const  persistConfig={
  key:'root',
  version:1,
  storage
}

const reducer=combineReducers({
  user:userReducers
})

const persistedReducer=persistReducer(persistConfig,reducer)

export const store = configureStore({
  reducer: persistedReducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch


