import React from 'react';
import { Box, Typography, Input, IconButton, FormControl, FormLabel } from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';

const AccountDetails: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
        backgroundColor: '#f0f0f0', 
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          width: '100%',
          py: 4,
          px: 3,
          backgroundColor: '#f9f9f9',
          borderRadius: 2,
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          margin: 4,
        }}
      >
        <Typography level="h4" sx={{ mb: 2 }}>
          Account details
        </Typography>

        {/* <Typography sx={{ mb: 1, fontWeight: 'bold' }}>
          PROFILE DETAILS
        </Typography> */}

        <FormControl sx={{ mb: 2 }}>
          <FormLabel>Full name</FormLabel>
          <Input
            defaultValue="John"
            endDecorator={
              <IconButton>
                <EditIcon />
              </IconButton>
            }
            variant="outlined"
          />
        </FormControl>

        <FormControl sx={{ mb: 2 }}>
          <FormLabel>Email address</FormLabel>
          <Input
            defaultValue="Lathagn73@mail.com"
            endDecorator={
              <IconButton>
                <EditIcon />
              </IconButton>
            }
            variant="outlined"
          />
        </FormControl>

        <FormControl sx={{ mb: 2 }}>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            defaultValue="john@gmail.com"
            endDecorator={
              <IconButton>
                <EditIcon />
              </IconButton>
            }
            variant="outlined"
          />
        </FormControl>

        <FormControl sx={{ mb: 2 }}>
          <FormLabel>Gender</FormLabel>
          <Input
            type="text"
            defaultValue="Male"
            endDecorator={
              <IconButton>
                <EditIcon />
              </IconButton>
            }
            variant="outlined"
          />
        </FormControl>


        <FormControl sx={{ mb: 2 }}>
          <FormLabel>Date of birth</FormLabel>
          <Input
            type="date"
            defaultValue="28-06-1996"
            endDecorator={
              <IconButton>
                <EditIcon />
              </IconButton>
            }
            variant="outlined"
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default AccountDetails;
