import React, { useState } from 'react';
import { Typography, Box, IconButton, Avatar, AppBar, Toolbar, Tabs, Tab } from '@mui/material';
import { Notifications, CalendarToday } from '@mui/icons-material';
import PersonalInformation from './personal-information';
import SecuritySettings from './security-settings';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const SettingsPage: React.FC = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: '#EEF2FF',
          p: 3,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <AppBar
          position="static"
          elevation={0}
          sx={{
            borderBottom: '1px solid #ddd',
            mb: 2,
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            Settings & Configuration
            </Typography>
            <IconButton>
              <CalendarToday />
            </IconButton>
            <Typography variant="body1" sx={{ mx: 2 }}>
              28 July, 2024
            </Typography>
            <IconButton>
              <Notifications />
            </IconButton>
            <Avatar alt="Gn" src="/profile.webp" />
          </Toolbar>
        </AppBar>

       
        <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
          <Tab label="Personal Information" />
          <Tab label="Security Settings" />
        </Tabs>

       
        <TabPanel value={value} index={0}>
         <PersonalInformation/>
        </TabPanel>
        <TabPanel value={value} index={1}>
         
         <SecuritySettings/>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default SettingsPage;
