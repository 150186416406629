import React, { useRef, useLayoutEffect } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import { Tile as TileLayer } from 'ol/layer';
import { XYZ } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Style, Icon } from 'ol/style';

const UserLocationMap = () => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const mapInstanceRef = useRef<Map | null>(null);

  useLayoutEffect(() => {
    if (mapRef.current && !mapInstanceRef.current) {
      const map = new Map({
        target: mapRef.current,
        layers: [
          new TileLayer({
            source: new XYZ({
              url: 'https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}', 
              attributions: '© Google Maps',
            }),
          }),
        ],
        view: new View({
          center: fromLonLat([77.2090, 28.6139]), 
          zoom: 6,
        }),
      });

      mapInstanceRef.current = map;

     
      navigator.geolocation.getCurrentPosition((position) => {
        const userCoordinates = fromLonLat([position.coords.longitude, position.coords.latitude]);

        const userLocationFeature = new Feature({
          geometry: new Point(userCoordinates),
        });

        userLocationFeature.setStyle(
          new Style({
            image: new Icon({
              src: 'https://cdn-icons-png.flaticon.com/512/684/684908.png', 
              scale: 0.1,
            }),
          })
        );

        const vectorSource = new VectorSource({
          features: [userLocationFeature],
        });

        const vectorLayer = new VectorLayer({
          source: vectorSource,
        });

        map.addLayer(vectorLayer);
        map.getView().setCenter(userCoordinates);
        map.getView().setZoom(12); 
      });
    }

   
    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.setTarget(undefined); 
        mapInstanceRef.current = null;
      }
    };
  }, []);

  return <div ref={mapRef} style={{ height: '400px', width: '100%' }}></div>;
};

export default UserLocationMap;
