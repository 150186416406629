import React from 'react';
import { Box } from '@mui/joy';

const Banner: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundImage: 'url(./banner.png)',
        width: '100%',
        height: { xs: '25vh', sm: '35vh', md: '50vh' },
        maxWidth: '1600px',
        margin: '0 auto',
        borderRadius: '6px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain', // Adjusts to contain full image within box without cutting
        display: 'flex',
        alignItems: 'center',
      }}
    />
  );
};

export default Banner;
