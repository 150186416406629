export default function customRound(number) {
    const decimalPart = number - Math.floor(number); // Get the decimal part
    if (decimalPart > 0.5) {
        return Math.ceil(number); // Round up
    } else {
        return Math.floor(number); // Round down
    }
}

export function calculateCost(weight) {
    let totalCost = 0;
    const fullKgCost = 80;
    const additionalCostForOver100g = 10; 

    // Calculate full kilograms
    const fullKgs = Math.floor(weight);
    totalCost += fullKgs * fullKgCost;

    // Check remaining weight
    const remainingWeight = weight - fullKgs;
    
    if (remainingWeight > 0.1) {
        totalCost += additionalCostForOver100g; 
    }

    return totalCost;
}