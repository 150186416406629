import React from 'react';
import { Box, Typography, Chip, Button } from '@mui/joy';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; 

import { RoundNumber } from '../../../utils';
import { BASE_URL_Test } from '../../../utils/baseUrl';

interface OrderCardProps {
  imageUrl: string;
  name: string;
  price: string;
  status: 'delivered' | 'not_placed' | 'pending';
  paymentMessage?: string;
  deliveryDate?: string;
}

const OrderCard: React.FC<OrderCardProps> = ({
  imageUrl,
  name,
  price,
  status,
  paymentMessage,
  deliveryDate,
}) => {


  console.log("iamgeur",name)
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'not_placed':
        return 'danger';
      case 'delivered':
        return 'success';
      default:
        return 'neutral';
    }
  };

  
  const generatePDF = () => {
    const doc: any = new jsPDF(); 

    
    doc.setFontSize(20);
    doc.text('INVOICE', 160, 30);

   
    doc.setFontSize(12);
    doc.text('BILLED TO:', 10, 40);
    doc.text('Customer Name', 10, 45);
    doc.text('+123-456-7890', 10, 50);
    doc.text('123 Customer Address, City, Country', 10, 55);

    
    doc.text('Order No.: 12345', 160, 40);
    doc.text('Date: 2024-10-18', 160, 45);

   
    const tableColumn = ['Item', 'Price', 'Status', 'Delivery Date'];
    const tableRows = [
      [
        name, 
        price, 
        status === 'delivered' ? 'Delivered' : 'Pending', 
        deliveryDate || 'N/A'
      ]
    ];

    
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 65,
      theme: 'plain',
      styles: {
        lineColor: [44, 62, 80],
        lineWidth: 0.1,
      },
    });

   
    const finalY = doc.lastAutoTable.finalY + 10; 

   
    doc.text('Total', 140, finalY);
    doc.text(`${price}`, 180, finalY);

    
    doc.setFontSize(12);
    doc.text('Thank you for your purchase!', 10, finalY + 20);

    
    doc.save(`${name.replace(/\s+/g, '_')}_Invoice.pdf`);
  };

  return (
    <Box sx={{ display: 'flex', padding: 2, borderBottom: '1px solid #ddd' }}>
      <Box sx={{ width: '80px', marginRight: 2 }}>
        <img src={`${BASE_URL_Test}/api/v1/auth/media/`+imageUrl} alt={name} width="100%" />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography fontWeight="bold">{name}</Typography>
        {paymentMessage && (
          <Box sx={{ marginY: 1 }}>
            <Chip color="danger" variant="outlined">
              {paymentMessage}
            </Chip>
          </Box>
        )}
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        <Typography fontWeight="bold">  ₹ {RoundNumber(parseInt(price))}</Typography>
        <Chip color={getStatusColor(status)}>
          {status === 'not_placed' ? 'Order Not Placed' : `Delivered on ${deliveryDate}`}
        </Chip>
        <Button size="sm" sx={{ marginTop: 1 }} onClick={generatePDF}>
          Download Invoice
        </Button>
      </Box>
    </Box>
  );
};

export default OrderCard;
