import { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Tooltip, TextField, CircularProgress, Avatar, IconButton, Toolbar, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, AppBar, InputAdornment, Modal, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CalendarToday, Notifications, Search } from '@mui/icons-material';
import { InfoOutlined } from '@mui/icons-material';
import { Button, ModalClose, Sheet, Stack } from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { BASE_URL_Test } from '../../../../utils/baseUrl';
import { useDispatch, useSelector } from 'react-redux';
import { useFetch } from '../../../../hooks/useFetch';
import { urls } from '../../../../urls/urls';
import { convertTimestampToDate } from '../../../../utils';

import Snackbar from '@mui/joy/Snackbar';

// const initialOrderData =[
//   {
//     "id": 1,
//     "orderid": "orddrid",
//     "email": "orderidtest@gmail.com",
//     "name": "test",
//     "trackingno": "trcno01",
//     "created": "17-10-2024",
//     "total": "5020",
//     "profit": "100",
//     "status": "pending"
//   },
//   {
//     "id": 2,
//     "orderid": "orddrid02",
//     "email": "orderidtest@gmail.com",
//     "name": "test",
//     "trackingno": "trcno02",
//     "created": "17-10-2024",
//     "total": "5300",
//     "profit": "1000",
//     "status": "accepted"
//   }
// ];


interface OrderDataProps{
  id: 0,
  orderid: "",
  email: "",
  name: "",
  trackingno: "",
  created: "",
  total: "",
  profit: "",
  status: "",
  orderstatus: "",
  receiptno: ""
}

const statusColors: { [key: string]: string } = {
  Pending: '#fbf2d3',
  Confirmed: '#fbf2d3',
  Processing: '#fbf2d3',
  Picked: '#fbf2d3',
  Shipped: '#fbf2d3',
  Delivered: '#fbf2d3',
  Cancelled: '#fbf2d3',
};

const OrderManagement: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
const [status, setStatus] = useState('Pending');
const [trackingNumber, setTrackingNumber] = useState('');
// const [initialOrderData,setInitialData]=useState<OrderDataProps>()
// const [orderData, setOrderData] = useState(initialOrderData);
const [showSnackBar,setShowSnackBar]=useState({
  show:false,
  message:""
});
const [initialOrderData, setInitialData] = useState<OrderDataProps[]>([]); // Initialize as an array
const [orderData, setOrderData] = useState<OrderDataProps[]>([]);
// Ensure it's always an array

const { data:OrderData,error,isLoading,fetchData}=useFetch(BASE_URL_Test+urls('allorders'),{
  method: 'GET',
  // redirect: "follow",
  headers: { 'Content-Type': 'application/json'}

})
const { data:UpdatedData,error:UpdatedDataError,isLoading:UpdatedDataLoading,fetchData:UpdatedDataFetch}=useFetch(BASE_URL_Test+urls('allorders'),{
  method: 'PUT',
  // redirect: "follow",
  headers: { 'Content-Type': 'application/json'}

})
const dispatch=useDispatch();

const handleEditClick = (order: any) => {
  console.log("editclicked",order)
  setSelectedOrder(order);
  setTrackingNumber(order.trackingno);
  setStatus(order.status);
  setOpen(true);
};

const handleUpdateOrder = (selecteddata:any) => {
  console.log("selecteddata order",selecteddata['orderid'])
  // const updatedOrders = orderData.map((order:any) => {
  //   if (order.orderid === selecteddata.orderid) {
  //     return { ...selecteddata, status, trackingNumber }; 
  //   }
  //   return order;
  //   // console.log("selectedid",selecteddata.orderid)
  //   // console.log("selectedorderid",order.orderid)
   
  // });
  // console.log("update order",updatedOrders)
 
//   const formdata = new FormData();
// formdata.append("orderid", selecteddata['orderid']);
// formdata.append("trackingno",trackingNumber);
// formdata.append("orderstatus", status);
// const requestOptions = {
//   method: "PUT",
//   body: formdata,
//   redirect: "follow"
// };
// console.log(" ",formdata)
const formdata = new FormData();
formdata.append("orderid", selecteddata['orderid']);
formdata.append("trackingno", trackingNumber);
formdata.append("orderstatus", status);
const requestOptions: RequestInit = {
  method: 'PUT',
  body: formdata,
  redirect: 'follow' // or 'error' or 'manual'
};

// const requestOptions = {
//   method: "PUT",
//   body: formdata,
//   redirect: "follow"
// };

fetch(`${BASE_URL_Test}api/v1/auth/userorders/`, requestOptions)
  .then((response) => response.text())
  .then((result:any) => {
    console.log("result",result)
    if(result['status']==="success")
      {
        setOpen(!open); 
        setShowSnackBar({...showSnackBar,show:!showSnackBar['show'],message:result['data']})
        
      }
      fetchData();
  })
  
  .catch((error) => console.error(error));
// UpdatedDataFetch(formdata)


};

// useEffect(()=>{
// if(UpdatedDataError)
// {

// }
// else if(UpdatedData)
// {
//   if(UpdatedData['status']==="success")
//   {
//     setShowSnackBar({...showSnackBar,show:!showSnackBar,message:UpdatedData['data']})
//     fetchData();
//   setOpen(false); 
//   }
// }
// },[UpdatedData,UpdatedDataError])




const handleStatusChange = (event: SelectChangeEvent<string>) => {
  setStatus(event.target.value);
};




useEffect(()=>{
  fetchData()
},[])


useEffect(()=>{
  if(error)
  {
    console.log("admin orders error",error)
  }
  else if(OrderData)
  {
    console.log("admin orders data",OrderData)
    setInitialData(OrderData['data'])
    setOrderData(OrderData['data']); 
  
    // navigate(from); // Redirect back to the intended page
    // dispatch(setUserLoginDetails(data))
  }

},[OrderData,error])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('https://fake-store-api.mock.beeceptor.com/api/orders');
  //       const result = await response.json();
  //       setData(result);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  // const filteredOrders = orderData.filter(order =>
  //   order.orderid.toLowerCase().includes(searchValue.toLowerCase())
  // );

  const handleSearch=()=>{
    const filteredOrders = Array.isArray(orderData)
    ? orderData.filter(order =>
        order.orderid.toLowerCase().includes(searchValue.toLowerCase())
      )
    : [];
    // setInitialData(filteredOrders['data'])
  }


  
  console.log("filteredDta",initialOrderData)

  return (
    <Box component="main" sx={{ flexGrow: 1, bgcolor: '#EEF2FF', p: 3, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
     
      <AppBar position="static" elevation={0} sx={{ borderBottom: '1px solid #ddd', backgroundColor: 'white', color: 'black' }}>
        <Toolbar>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            Order Management
          </Typography>
          <IconButton>
            <CalendarToday />
          </IconButton>
          <Typography variant="body1" sx={{ mx: 2 }}>
            28 July, 2024
          </Typography>
          <IconButton>
            <Notifications />
          </IconButton>
          <Avatar alt="Gn" src="/profile.webp" />
        </Toolbar>
      </AppBar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showSnackBar['show']}
        variant={'solid'}
        color={"success"}
        sx={{color:'#fff'}}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
           console.log("onclide",reason)
          }
          // setOpen(false);
        //   setShowModal({...showModal,showSnack:false,message:'',snackColor:''})
          // dispatch(setModalProperties({showModal:false,modalMessage:''}))
        }}
      >
       {showSnackBar['message']}
      </Snackbar>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#EEF2FF', p: 3, overflowY: 'auto' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} sx={{ mb: 2 }}>
          <Tab label="Pending" sx={{ color: selectedTab === 0 ? '#4F46E5' : 'inherit' }} />
          <Tab label="Confirmed" sx={{ color: selectedTab === 1 ? '#4F46E5' : 'inherit' }} />
          <Tab label="Processing" sx={{ color: selectedTab === 2 ? '#4F46E5' : 'inherit' }} />
          <Tab label="Picked" sx={{ color: selectedTab === 3 ? '#4F46E5' : 'inherit' }} />
          <Tab label="Shipped" sx={{ color: selectedTab === 4 ? '#4F46E5' : 'inherit' }} />
          <Tab label="Delivered" sx={{ color: selectedTab === 5 ? '#4F46E5' : 'inherit' }} />
          <Tab label="Cancelled" sx={{ color: selectedTab === 6 ? '#4F46E5' : 'inherit' }} />
        </Tabs>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <TextField
            placeholder="Search by order id"
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={handleSearchChange}
            sx={{ width: '300px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ORDER ID</TableCell>
                <TableCell>TRACKING NUMBER</TableCell>
                <TableCell>CREATED</TableCell>
                <TableCell>CUSTOMER</TableCell>
                <TableCell>TOTAL</TableCell>
                <TableCell>PROFIT</TableCell>
                <TableCell>STATUS</TableCell>
                <TableCell align="right">ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
  {initialOrderData.length > 0 ? (
    initialOrderData.reverse().map((order, index) => (
      <TableRow key={index}>
        <TableCell>{order.orderid}</TableCell>
        <TableCell>{order.trackingno}</TableCell>
        <TableCell>{convertTimestampToDate(order.created)}</TableCell>
        <TableCell>{order.name}</TableCell>
        <TableCell>{(parseInt(order.total)/100)}</TableCell>
        <TableCell>{order.profit}</TableCell>
        <TableCell>{order.orderstatus}</TableCell>
        <TableCell align="right" sx={{cursor:'pointer'}}>
          <EditIcon onClick={() => handleEditClick(initialOrderData[index])} />
          <Modal
                      aria-labelledby="modal-title"
                      aria-describedby="modal-desc"
                      open={open}
                      onClose={() => setOpen(false)}
                      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      BackdropProps={{
                        style: {
                          backgroundColor: 'rgba(0, 0, 0, 0.2)', 
                        },
                      }}
                    >
                      <Sheet
                        variant="outlined"
                        sx={{ maxWidth: '700px', width: '100%', borderRadius: 'md', p: 3 }}
                      >
                        <ModalClose variant="plain" sx={{ m: 1 }} onClick={() => setOpen(false)} />

                        <Typography sx={{ fontSize: 20, textAlign: 'center', fontWeight: 'bold' }}>
                          Edit Order Details
                        </Typography>

                        {selectedOrder && (
                          <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Typography variant="body1" gutterBottom>
                             <span style={{fontWeight:'bold'}}> Order ID:</span> {selectedOrder.orderid}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                              <span style={{fontWeight:'bold'}}>Created:</span> {convertTimestampToDate(selectedOrder.created)}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                              <span style={{fontWeight:'bold'}}>Customer:</span> {selectedOrder.name}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                              <span style={{fontWeight:'bold'}}>Total:</span> {(selectedOrder.total/100)}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                              <span style={{fontWeight:'bold'}}>PROFIT:</span> {selectedOrder.profit}
                            </Typography>
                            <FormControl fullWidth>
                              <TextField
                                label="Tracking Number"
                                variant="outlined"
                                size="small"
                                sx={{ mb: 2 }}
                                value={trackingNumber}
                                onChange={(e) => setTrackingNumber(e.target.value)}
                              />
                            </FormControl>

                            <FormControl fullWidth>
                              <InputLabel>Status</InputLabel>
                              <Select
                                label="Status"
                                value={status}
                                onChange={handleStatusChange}
                                size="small"
                                sx={{ mb: 2 }}
                              >
                                <MenuItem value="Pending">Pending</MenuItem>
                                <MenuItem value="Confirmed">Confirmed</MenuItem>
                                <MenuItem value="Processing">Processing</MenuItem>
                                <MenuItem value="Picked">Picked</MenuItem>
                                <MenuItem value="Shipped">Shipped</MenuItem>
                                <MenuItem value="Delivered">Delivered</MenuItem>
                              </Select>
                            </FormControl>

                            <Button
                              sx={{ backgroundColor: '#4F46E5', alignContent: 'center' }}
                              onClick={()=>handleUpdateOrder(selectedOrder)}
                            >
                              Update
                            </Button>
                          </Stack>
                        )}
                      </Sheet>
                    </Modal>
        </TableCell>


      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={8} align="center">No orders found</TableCell>
    </TableRow>
  )}
</TableBody>

          </Table>
        </TableContainer>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <Typography variant="body2">Showing {initialOrderData.length} of {orderData.length}</Typography>
          <Box>
            <IconButton sx={{ color: '#4F46E5' }}>⟨</IconButton>
            {Array.isArray(initialOrderData) && initialOrderData.map((page, index) => (
  <IconButton key={index} sx={{ color: '#4F46E5' }}>{index}</IconButton>
))}
            <IconButton sx={{ color: '#4F46E5' }}>⟩</IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderManagement;
