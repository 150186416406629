import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, Button, Input, styled, IconButton } from '@mui/joy';
import Map from 'ol/Map';
import View from 'ol/View';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { XYZ } from 'ol/source';
import { Point, Geometry } from 'ol/geom';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Feature } from 'ol';
import { Vector as VectorSource } from 'ol/source';
import { Style, Icon } from 'ol/style';
import { Modify } from 'ol/interaction';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear'; 
import MyLocationIcon from '@mui/icons-material/MyLocation'; 

const googleMapsTileUrl = 'https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}&key';

interface AddressModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (location: string) => void;
}

const MapContainer = styled(Box)({
  width: '100%',
  height: '424px',
  borderRadius: '8px',
  overflow: 'hidden',
  backgroundColor: '#fff',
  position: 'relative',
});

const AddressModal: React.FC<AddressModalProps> = ({ open, onClose, onConfirm }) => {
  const [address, setAddress] = useState('');
  const [mapCenter, setMapCenter] = useState<[number, number]>(
    fromLonLat([-71.085059, 42.362419]) as [number, number]
  );
  const [markerPosition, setMarkerPosition] = useState<[number, number]>(
    fromLonLat([-71.085059, 42.362419]) as [number, number]
  );
  const [mapZoom, setMapZoom] = useState(15);

  const handleMapClick = (event: any) => {
    const coords = event.coordinate as [number, number];
    setMarkerPosition(coords);
  };

  const handleMarkerDragEnd = async (newPosition: [number, number]) => {
    setMarkerPosition(newPosition);

    const [lng, lat] = toLonLat(newPosition);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json&accept-language=en`
      );
      const data = await response.json();
      const location = data.display_name || `Lat: ${lat.toFixed(6)}, Lng: ${lng.toFixed(6)}`;
      setAddress(location);
    } catch (error) {
      console.error('Error fetching the address:', error);
      setAddress(`Lat: ${lat.toFixed(6)}, Lng: ${lng.toFixed(6)}`);
    }
  };

  const handleAddressChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);

    if (e.target.value.trim() === '') return;

    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?q=${e.target.value}&format=json&limit=1&accept-language=en`
    );
    const result = await response.json();

    if (result.length > 0) {
      const { lat, lon } = result[0];
      const newCenter = fromLonLat([parseFloat(lon), parseFloat(lat)]) as [
        number,
        number
      ];
      setMapCenter(newCenter);
      setMarkerPosition(newCenter);
      setMapZoom(18);
    }
  };

  const clearSearch = () => {
    setAddress('');
    setMapCenter(fromLonLat([-71.085059, 42.362419]) as [number, number]);
    setMarkerPosition(fromLonLat([-71.085059, 42.362419]) as [number, number]);
    setMapZoom(15);
  };

  const useCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const newCenter = fromLonLat([longitude, latitude]) as [
            number,
            number
          ];
          setMapCenter(newCenter);
          setMarkerPosition(newCenter);
          setMapZoom(18);
        },
        (error) => {
          console.error('Error fetching location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const confirmLocation = async () => {
    const [lng, lat] = toLonLat(markerPosition);

    if (!address) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json&accept-language=en`
        );
        const data = await response.json();
        const location = data.display_name || `Lat: ${lat.toFixed(6)}, Lng: ${lng.toFixed(6)}`;
        onConfirm(location);
      } catch (error) {
        console.error('Error fetching the address:', error);
        onConfirm(`Lat: ${lat.toFixed(6)}, Lng: ${lng.toFixed(6)}`);
      }
    } else {
      onConfirm(address);
    }
  };

  return (
    <Modal open={open} onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box
        sx={{
          p: 2,
          width: '760px',
          bgcolor: 'white',
          borderRadius: '12px',
          position: 'relative',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose} 
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <h2>Edit address</h2>
        <Box sx={{ position: 'relative', mb: 2 }}>
          <Input
            placeholder="Search your address"
            fullWidth
            value={address}
            onChange={handleAddressChange}
            sx={{ pr: 4 }} 
          />
          {address && (
            <IconButton
              aria-label="clear search"
              onClick={clearSearch}
              size="sm"
              sx={{
                position: 'absolute',
                right: 8,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Box>
        <MapContainer>
          <MapComponent
            center={mapCenter}
            zoom={mapZoom}
            markerPosition={markerPosition}
            onMapClick={handleMapClick}
            onMarkerDragEnd={handleMarkerDragEnd}
          />
          <IconButton
            onClick={useCurrentLocation}
            sx={{
              position: 'absolute',
              bottom: 16,
              left: 16,
              bgcolor: 'white',
              borderRadius: '8px',
              boxShadow: 3,
              border: '1px solid #2c3ad5', 
              padding: '8px 16px', 
            }}
          >
            <MyLocationIcon />
            <span style={{ marginLeft: 8 ,color:'#2c3ad5'}}>Your location</span>
          </IconButton>
        </MapContainer>
        <Box sx={{ textAlign: 'end', mt: 2 }}>
          <Button
            variant="solid"
            onClick={confirmLocation}
          >
            Confirm and Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

interface MapComponentProps {
  center: [number, number];
  zoom: number;
  markerPosition: [number, number];
  onMapClick: (event: any) => void;
  onMarkerDragEnd: (newPosition: [number, number]) => void;
}

const MapComponent: React.FC<MapComponentProps> = ({
  center,
  zoom,
  markerPosition,
  onMapClick,
  onMarkerDragEnd,
}) => {
  const mapRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (mapRef.current) {
      const markerFeature = new Feature({
        geometry: new Point(markerPosition) as Geometry, 
      });

      const vectorSource = new VectorSource<Feature<Geometry>>({  
        features: [markerFeature],
      });

      const map = new Map({
        target: mapRef.current,
        layers: [
          new TileLayer({
            source: new XYZ({
              url: googleMapsTileUrl,
            }),
          }),
          new VectorLayer({
            source: vectorSource,
            style: new Style({
              image: new Icon({
                anchor: [0.5, 1],
                src: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png',
              }),
            }),
          }),
        ],
        view: new View({
          center,
          zoom,
        }),
      });

      
      const modify = new Modify({ source: vectorSource });
      map.addInteraction(modify);

      modify.on('modifyend', (event) => {
        const newCoords = (event.features.item(0).getGeometry() as Point).getCoordinates();
        onMarkerDragEnd(newCoords as [number, number]);
      });

      map.on('click', onMapClick);

      return () => map.setTarget(undefined);
    }
  }, [center, zoom, markerPosition, onMapClick, onMarkerDragEnd]);

  return <Box ref={mapRef} sx={{ width: '100%', height: '100%' }} />;
};

export default AddressModal;
