import React, { useState } from 'react';
import { Box, Typography, Paper, useMediaQuery, useTheme } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface Review {
  name: string;
  review: string;
  company: string;
}

const reviews: Review[] = [
  {
    name: 'Gowri Sound & Light Service',
    review: 'They are beautifully decorated and have lovely sound systems.',
    company: 'Gowri Sound & Light Service',
  },
  {
    name: 'ASH Sounds and Lights',
    review: 'The lighting and decorations were superb, thanks to Hero Electro.',
    company: 'ASH Sounds and Lights',
  },
  {
    name: 'Anbu Audios & Decors',
    review: 'Excellent experiences with the services of Pixel LED Design and Hero Sounds.',
    company: 'Anbu Audios & Decors',
  },
];

const CustomerReview: React.FC = () => {
  const [activeSlide, setActiveSlide] = useState(1); 
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const settings = {
    centerMode: !isSmallScreen,
    centerPadding: '0',
    slidesToShow: isSmallScreen ? 1 : isMediumScreen ? 2 : 3,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    focusOnSelect: true,
    dots: true,
    arrows: true,
    initialSlide: 1,
    afterChange: (current: number) => setActiveSlide(current), 
  };

  return (
    <Box sx={{ padding: { xs: '10px', md: '40px' }, textAlign: 'center' }}>
      <Typography variant={isSmallScreen ? 'h5' : 'h4'} gutterBottom>
        Customer Review
      </Typography>
      <Typography variant={isSmallScreen ? 'body2' : 'subtitle1'} gutterBottom>
        What our Customers say about Hero Electro
      </Typography>
      <Slider {...settings}>
        {reviews.map((review, index) => {
          const isCenter = index === activeSlide;

          const scale = isCenter ? 1.05 : 0.85;
          const opacity = isCenter ? 1 : 0.5;
          const borderColor = isCenter ? 'pink' : 'transparent';

          return (
            <Box
              key={index}
              sx={{
                padding: '10px',
                transform: `scale(${scale})`,
                transition: 'transform 0.5s ease, opacity 0.5s ease',
                opacity: opacity,
              }}
            >
              <Paper
                elevation={isCenter ? 6 : 2}
                sx={{
                  padding: isSmallScreen ? '15px' : '30px',
                  borderRadius: '16px',
                  backgroundColor: 'white',
                  border: `3px solid ${borderColor}`,
                }}
              >
                <Box display="flex" justifyContent="center" mb={1}>
                  {Array.from({ length: 5 }, (_, i) => (
                    <StarIcon key={i} sx={{ fontSize: isSmallScreen ? '16px' : '20px', color: 'gold' }} />
                  ))}
                </Box>
                <Typography variant="body2" mb={1} sx={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }}>
                  {review.review}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: isSmallScreen ? '0.75rem' : '0.875rem' }}>
                  {review.company}
                </Typography>
              </Paper>
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
};

export default CustomerReview;
