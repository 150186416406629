import { useEffect, useState } from 'react';
import { Box, Avatar, IconButton, Toolbar, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, AppBar } from '@mui/material';
import { CalendarToday, Notifications } from '@mui/icons-material';
import CustomerManagementDetails from './customer-mandetails';
import { urls } from '../../../../urls/urls';
import { useFetch } from '../../../../hooks/useFetch';
import { BASE_URL_Test } from '../../../../utils/baseUrl';

const orderData = [
  { id: '1', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
  { id: '2', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
  { id: '3', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
  { id: '4', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
  { id: '5', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
  { id: '6', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
  { id: '7', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
  { id: '8', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
  { id: '9', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
  { id: '10', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
  { id: '11', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
  { id: '12', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
  { id: '13', created: 'Robert Fox', customer: '(201) 555-0124', total: '6 April 2023', profit: 'View' },
];

interface CustomersDataProps {
  
  last_login: any,
  email: string,
  password: string,
  firstname: string,
  phone: string,
  is_active: boolean,
  is_staff: boolean,
  is_superuser: boolean
}

const CustomerMnagement: React.FC = () => {
  const [searchValue] = useState('');
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null); 



  const {data:CustomersData,error:CustomersError,isLoading:CustomerLoading,fetchData:CustomersDataFetch}=useFetch(BASE_URL_Test+urls('getusersdata'),{
    method:"POST",
    headers: { 'Content-Type': 'application/json'}

  })

  const [customersAllData, setCustomersAllData] = useState<CustomersDataProps[]>([]);

  const handleViewClick = (orderId: string) => {
    setSelectedOrder(orderId); 
  };

  const filteredOrders = orderData.filter(order =>
    order.id.toLowerCase().includes(searchValue.toLowerCase())
  );


  useEffect(()=>{
    CustomersDataFetch()
  },[])

  useEffect(()=>{
    if(CustomersError)
    {

    }
    else if(CustomersData && CustomersData['status']==='success')
    {
      console.log("CustomersData",CustomersData)
      setCustomersAllData(CustomersData['data'])
    }

  },[CustomersData,CustomersError])

  return (
    <Box component="main" sx={{ flexGrow: 1, bgcolor: '#EEF2FF', p: 3, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
      {!selectedOrder ? (
        <>
          <AppBar position="static" elevation={0} sx={{ borderBottom: '1px solid #ddd', backgroundColor: 'white', color: 'black' }}>
            <Toolbar>
              <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
                Customer Management
              </Typography>
              <IconButton>
                <CalendarToday />
              </IconButton>
              <Typography variant="body1" sx={{ mx: 2 }}>
                28 July, 2024
              </Typography>
              <IconButton>
                <Notifications />
              </IconButton>
              <Avatar alt="Gn" src="/profile.webp" />
            </Toolbar>
          </AppBar>

          <Box component="main" sx={{ flexGrow: 1, bgcolor: '#EEF2FF', p: 3, overflowY: 'auto' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>NAME</TableCell>
                    <TableCell>PHONE NUMBER</TableCell>
                    <TableCell>CREATED</TableCell>
                    <TableCell>ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customersAllData && customersAllData.map((order, index) => (
                    <TableRow key={index}>
                      <TableCell>{order.firstname}</TableCell>
                      <TableCell>{order.phone}</TableCell>
                      <TableCell>{order.last_login}</TableCell>
                      <TableCell>
                      <Typography 
  onClick={() => handleViewClick(order.email)} 
  sx={{ cursor: 'pointer' }}
>
  View
</Typography>

                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
              <Typography variant="body2">Showing {filteredOrders.length} of {orderData.length}</Typography>
              <Box>
                <IconButton sx={{ color: '#4F46E5' }}>⟨</IconButton>
                {[1, 2, 3, 4, 5].map((page) => (
                  <IconButton key={page} sx={{ color: '#4F46E5' }}>{page}</IconButton>
                ))}
                <IconButton sx={{ color: '#4F46E5' }}>⟩</IconButton>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <CustomerManagementDetails orderId={selectedOrder} />
      )}
    </Box>
  );
};

export default CustomerMnagement;
